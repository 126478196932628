import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getModel10TaskStatus } from 'services/apiRequests'
import {
  resetModel10SliceState,
  selectTaskId,
  selectTaskStatus,
  setTaskCalculationProgress,
  setTaskId,
  setErrorMessage,
  setTaskStatus,
  setAuthor,
} from 'store/model10Slice'
import { setBlockingWindow, setTaskIsCanceling } from 'store/commonSlice'

const useGetTask = () => {
  const dispatch = useDispatch()
  const taskId = useSelector(selectTaskId)
  const taskStatus = useSelector(selectTaskStatus)
  const timerId = React.useRef<ReturnType<typeof setTimeout>>()

  const tryToGetTaskStatus = async () => {
    if (taskId)
      await getModel10TaskStatus(taskId)
        .then((response) => {
          dispatch(setTaskCalculationProgress(response.data.progress))
          dispatch(setTaskStatus(response.data.status))
          dispatch(setAuthor(response.data.author))
          if (['Рассчитан', 'Ошибка', 'Отменен'].includes(response.data.status)) {
            dispatch(setErrorMessage(response.data.error_message))
            dispatch(setTaskIsCanceling(false))
            if (timerId.current) {
              clearInterval(timerId.current)
              timerId.current = undefined
            }
          }
        })
        .catch(() => {
          if (timerId.current) clearInterval(timerId.current)
          dispatch(setTaskId(undefined))
          dispatch(
            setBlockingWindow({
              objectName: 'Непредвиденная ошибка',
              message: 'Непредвиденная ошибка',
              type: 'ERROR',
            }),
          )
        })
  }

  React.useEffect(() => {
    if (taskId && !taskStatus) {
      timerId.current = setInterval(async () => {
        tryToGetTaskStatus().then()
      }, 5000)
    }
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current)
        dispatch(resetModel10SliceState())
      }
    }
  }, [taskId])

  return
}

export default useGetTask
