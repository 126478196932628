import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthor, selectTaskCalculationProgress, selectTaskId } from 'store/model10Slice'
import { Link, unstable_usePrompt as usePrompt } from 'react-router-dom'
import Button from 'components/Button'
import { setBlockingWindow } from 'store/commonSlice'
import { handleStorage } from '../../../services/handleStorage'

const Progress: React.FC = () => {
  const dispatch = useDispatch()
  const taskId = useSelector(selectTaskId)
  const taskCalculationProgress = useSelector(selectTaskCalculationProgress)
  const taskAuthor = useSelector(selectAuthor)

  const stopTask = () => {
    dispatch(
      setBlockingWindow({
        objectName: 'Гидравлический расчет',
        type: 'STOP_TASK_CONFIRM',
        objectID: taskId,
      }),
    )
  }

  /*usePrompt({
    when: true,
    message:
      navigator.language === 'ru'
        ? 'Вы действительно хотите покинуть страницу? \nПрогресс расчета будет утерян.'
        : 'Are you sure you want to leave this page? \nCalculation progress will be lost.',
  })

  React.useEffect(() => {
    window.onbeforeunload = () => {
      return true
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [])*/

  console.log(taskAuthor?.id)

  return (
    <>
      <h1 className={'project-list__projects-title no-margin'}>Гидравлический расчет</h1>
      <div className='progress__loader-container model10-loader-container'>
        <div className='progress__loader' />
        <div className={'progress__percentage'}>{taskCalculationProgress || 0}%</div>
      </div>
      <div className={'progress__hint normal-text'}>{'Выполняется гидравлический расчет'}</div>
    </>
  )
}

export default Progress
