import React, { useContext, useEffect, useRef } from 'react'
import { MapContext } from './map'
interface ILayerProps {
  sourceID: string
  baseLayer: string
  actionLayer?: string
  features: any
  layerType: 'symbol' | 'circle' | 'fill' | 'line'
  layout?: any
  paint?: any
  filter?: string[]
  minZoom?: number
  visible?: 'visible' | 'none'
  handleMouseLeave?: (e: any) => void
  handleMouseMove?: (e: any) => void
  handleMouseClick?: (e: any) => void
  handleMouseDown?: (e: any) => void
  source?: boolean
  beforeId?: string
}
export const Layer: React.FC<ILayerProps> = (props) => {
  const mapContext = useContext(MapContext)
  const sourceData = useRef<any>()
  useEffect(() => {
    !mapContext?.getSource(props.sourceID) &&
      mapContext?.addSource(props.sourceID, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: props.features,
        },
      })
    sourceData.current = mapContext?.getSource(props.sourceID)
    const layerProps = {
      id: props.baseLayer,
      source: props.sourceID,
      type: props.layerType,
      layout: props.layout ? props.layout : {},
      paint: props.paint ? props.paint : {},
      minzoom: props.minZoom ? props.minZoom : 0,
    }
    mapContext?.addLayer(layerProps, props.beforeId)
    // props.beforeId
    if (props.filter) {
      mapContext.setFilter(props.baseLayer, props.filter as any)
    }
    // mapContext?.moveLayer(props.baseLayer)
    props.handleMouseClick && mapContext?.on('click', props.actionLayer || props.baseLayer, props.handleMouseClick)
    props.handleMouseDown && mapContext?.on('mousedown', props.actionLayer || props.baseLayer, props.handleMouseDown)
    return () => {
      props.handleMouseClick && mapContext?.off('click', props.actionLayer || props.baseLayer, props.handleMouseClick)
      props.handleMouseDown && mapContext?.off('mousedown', props.actionLayer || props.baseLayer, props.handleMouseDown)
      mapContext?.removeLayer(props.baseLayer)
      !mapContext.getStyle().layers.filter((layer) => layer.type !== 'background' && layer.source !== props.sourceID)
        .length && mapContext?.removeSource(props.sourceID)
    }
  }, [])
  useEffect(() => {
    if (mapContext?.getLayer(props.baseLayer) && props.paint) {
      for (const [name, value] of Object.entries(props.paint)) {
        mapContext?.setPaintProperty(props.baseLayer, name, value)
      }
    }
  }, [props.paint])
  useEffect(() => {
    mapContext?.getLayer(props.baseLayer) &&
      props.visible &&
      mapContext?.setLayoutProperty(props.baseLayer, 'visibility', props.visible)
  }, [props.visible])
  useEffect(() => {
    if (sourceData.current && props.source) {
      sourceData.current.setData({
        type: 'FeatureCollection',
        features: props.features,
      })
    }
  }, [props.features])
  return <></>
}
