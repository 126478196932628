// @ts-ignore
import { ReactComponent as Logo } from 'images/gasprom-logo.svg'
import PersonIcon from 'images/PersonIcon'
import React from 'react'
import './mainPageHeader.scss'
import './baseHeader.scss'
import PopupMenuLayout from '../Dropdown/PopupMenuLayout'
import { MenuTabKey } from '../../pages/MainPage/types'
import Tabs, { ITab } from '../Tabs/tabs'
import { useSelector } from 'react-redux'
import { selectUser } from 'store/commonSlice'
interface IMainPageHeaderProps {
  tabKey: MenuTabKey
  setTabKey(key: MenuTabKey): void
  logout?(): void
}

const menuTabList: ITab<MenuTabKey>[] = [
  { key: 'PROJECTS', tabContent: 'Проекты' },
  { key: 'DIRECTORIES ', tabContent: 'Справочники' },
  { key: 'MODEL10', tabContent: 'Модель 10' },
  { key: 'TASKS', tabContent: 'Очередь задач' },
]
export const MainPageHeader: React.FC<IMainPageHeaderProps> = ({ tabKey, setTabKey, logout }) => {
  const [showMenu, setShowMenu] = React.useState<boolean>(false)
  const user = useSelector(selectUser)

  const onLogoutClick = () => {
    setShowMenu(false)
    if (logout) logout()
  }
  return (
    <div className={'main-page-header__container header__container'}>
      {' '}
      {/*project-list__header base-header primary*/}
      <div className={'project-list__header-logo'}>
        <Logo />{' '}
        <div className={'project-list__header-label label-primary'}>
          Модуль оптимизации <br />
          трасс{' '}
        </div>
      </div>
      <div className={'main-page-content__container header-content__container'}>
        <Tabs
          tabList={menuTabList}
          activeTab={tabKey}
          setActiveTab={setTabKey}
          containerClassName={'project-list__header-btns'}
          tabClassName={'switcher_btn main-button primary button-text'}
        />
      </div>
      {/*<div className={'project-list__header-btns'}>
        <Button
          className={classnames('switcher_btn', tabKey === 'PROJECTS' && 'selected')}
          onClick={() => setTabKey('PROJECTS')}
        >
          <div className={'button-text'}>Проекты</div>
        </Button>
        <Button
          className={classnames('switcher_btn', tabKey === 'MODEL10' && 'selected')}
          onClick={() => setTabKey('MODEL10')}
        >
          <div className={'button-text'}>Модель 10</div>
        </Button>
      </div>*/}
      <div className={'project-list__header-user'}>
        <div className={'project-list__user-container'}>
          <button className={'project-list__user-btn'} onClick={() => setShowMenu(!showMenu)}>
            <PersonIcon className={'project-list__user-btn-icon'} />
            <div className={'username-label'}>{`${user?.last_name || ''} ${
              user?.first_name ? user?.first_name[0] : ''
            }`}</div>
          </button>
          {showMenu && (
            <PopupMenuLayout closeMenu={() => setShowMenu(false)}>
              <div className={'project-list__logout-btn'} onClick={onLogoutClick}>
                Выйти&nbsp;из&nbsp;системы
              </div>
            </PopupMenuLayout>
          )}
        </div>
      </div>
    </div>
  )
}
