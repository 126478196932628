import React from 'react'
import Input from '../Input'
import SearchIcon from 'images/SearchIcon'
import './searchInput.scss'
import classnames from 'classnames'

interface ISearchInputProps {
  setValue(currentValue: string | undefined): void
  placeholder?: string
}

export const SearchInput: React.FC<ISearchInputProps> = ({ setValue, placeholder }) => {
  const [focused, setFocused] = React.useState<boolean>(false)
  const onChange = (e: any) => {
    setValue(e.target.value || undefined)
  }
  return (
    <div
      className={classnames('search-input', { focused })}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
    >
      <SearchIcon />
      <div className={'input-placer'}>
        <Input placeholder={placeholder} onChange={onChange} />
      </div>
    </div>
  )
}
