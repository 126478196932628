import React, { useState } from 'react'
import BackIcon from 'images/BackIcon'
import Button from 'components/Button'
import './projectHeader.scss'
import { useNavigate } from 'react-router-dom'
import { KebabButton } from 'components/KebabButton/KebabButton'
import CopyIcon from 'images/CopyIcon'
import DeleteIcon from 'images/DeleteIcon'
import EditIcon from 'images/EditIcon'
import { setBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import useCopyProject from 'components/hooks/useCopyProject'
import { selectProjectID } from 'store/projectSlice'
interface IProjectHeaderProps {
  title: string
  id: string
}
export const ProjectHeader: React.FC<IProjectHeaderProps> = ({ title, id }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)
  const [copyProjectID, setCopyProjectID] = useState<string | undefined>()

  useCopyProject(copyProjectID)

  const onDelete = () => {
    dispatch(
      setBlockingWindow({
        objectID: id,
        objectType: 'PROJECT',
        objectName: title,
        type: 'DELETE_CONFIRM',
      }),
    )
  }
  const onChangeProjectName = () => {
    dispatch(
      setBlockingWindow({
        objectID: id,
        objectType: 'PROJECT',
        objectName: title,
        type: 'EDIT',
      }),
    )
  }
  const onCopyProject = () => {
    setCopyProjectID(projectId)
  }
  return (
    <div className={'project__header base-header'}>
      <div className={'project__header-start'}>
        <BackIcon className={'back-icon'} onClick={() => navigate('/')} />
        <h2 className={'project__header-title'}>{title}</h2>
      </div>
      <div className={'project__header-end'}>
        <KebabButton mode={'dark'} text={'Действия'}>
          <Button mode={'drop-down'} onClick={onChangeProjectName}>
            <EditIcon /> Переименовать проект
          </Button>
          <Button mode={'drop-down'} onClick={onCopyProject}>
            {' '}
            <CopyIcon /> Копировать проект{' '}
          </Button>
          <Button mode={'drop-down'} onClick={onDelete}>
            {' '}
            <DeleteIcon /> Удалить проект{' '}
          </Button>
        </KebabButton>
        {/*<Button className={'action-btn'} mode={'primary-light'}>*/}
        {/*  Действия*/}
        {/*  <DotsIcon />*/}
        {/*</Button>*/}
      </div>
    </div>
  )
}
