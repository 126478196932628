import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Button from 'components/Button'
import React, { useState } from 'react'
import TextInput from 'components/Input/textInput'
interface IEditProjectNameWindow {
  blockingWindow: any
  updateName(name: string): void
  closeWindow(): void
}
export const EditProjectNameWindow: React.FC<IEditProjectNameWindow> = ({
  blockingWindow,
  updateName,
  closeWindow,
}) => {
  const [projectName, setProjectName] = useState<string>(blockingWindow.objectName)
  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container'>
        <div className={'blocking-window__header'}>
          Переименование проекта
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'blocking-window__body'}>
          <TextInput value={projectName} setValue={setProjectName} label={'Название проекта'} />
        </div>
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Отменить
          </Button>
          <Button onClick={() => updateName(projectName)}>Переименовать</Button>
        </div>
      </div>
    </div>
  )
}
