import React from 'react'
import { unstable_usePrompt as usePrompt } from 'react-router-dom'
import './settings.scss'
import { useDispatch } from 'react-redux'
import useCreateTask from './hooks/useCreateTask'
import NumberInput from 'components/Input/numberInput'
import RadioButton from 'components/RadioButton'
import useGetHydroSettings from './hooks/useGetHydroSettings'
import { definitions } from 'generated/apiTypes'
import { resetValidation } from 'store/validationSlice'

interface ISettingsProps {
  setHydroSettingsReceived(value: boolean): void
}

const Settings: React.FC<ISettingsProps> = ({ setHydroSettingsReceived }) => {
  const dispatch = useDispatch()
  const [powerCalculationPrecision, setPowerCalculationPrecision] = React.useState<number>()
  const [year, setYear] = React.useState<number>()
  const [powerCalculationPrecisionGPU, setPowerCalculationPrecisionGPU] = React.useState<number>()
  const [pressureCalculationPrecision, setPressureCalculationPrecision] = React.useState<number>()
  const [outletPressureCalculationPrecision, setOutletPressureCalculationPrecision] = React.useState<number>()
  const [pressureRangeForReductionCalculation, setPressureRangeForReductionCalculation] = React.useState<number>()
  const [incrementStep, setIncrementStep] = React.useState<number>()
  const [calcType, setCalcType] = React.useState<string>()

  const hydroSettings: definitions['HydroCalculationProperties'] | undefined = useGetHydroSettings()
  useCreateTask({
    year,
    power_calculation_precision: powerCalculationPrecision,
    power_calculation_precision_GPU: powerCalculationPrecisionGPU,
    pressure_calculation_precision: pressureCalculationPrecision,
    outlet_pressure_calculation_precision: outletPressureCalculationPrecision,
    pressure_range_for_reduction_calculation__MPa: pressureRangeForReductionCalculation,
    increment_step_for_Qmax_calculation__Mm3_per_day: incrementStep,
    max_pipeline_capacity: calcType === 'MAX_PIPELINE_CAPACITY',
  })

  React.useEffect(() => {
    if (hydroSettings) {
      setHydroSettingsReceived(true)
      setYear(hydroSettings.year)
      setPowerCalculationPrecision(hydroSettings.power_calculation_precision)
      setPowerCalculationPrecisionGPU(hydroSettings.power_calculation_precision_GPU)
      setPressureCalculationPrecision(hydroSettings.pressure_calculation_precision)
      setOutletPressureCalculationPrecision(hydroSettings.outlet_pressure_calculation_precision)
      setPressureRangeForReductionCalculation(hydroSettings.pressure_range_for_reduction_calculation__MPa)
      setIncrementStep(hydroSettings.increment_step_for_Qmax_calculation__Mm3_per_day)
      setCalcType(hydroSettings.max_pipeline_capacity ? 'MAX_PIPELINE_CAPACITY' : 'SET_PERFORMANCE')
    }
    return () => {
      dispatch(resetValidation())
    }
  }, [hydroSettings])

  /*usePrompt({
    when: blockingWindow?.type === 'HYDRO_PROGRESS',
    message:
      navigator.language === 'ru'
        ? 'Вы действительно хотите покинуть страницу? \nПрогресс расчета будет утерян.'
        : 'Are you sure you want to leave this page? \nCalculation progress will be lost.',
  })

  React.useEffect(() => {
    if (blockingWindow?.type === 'HYDRO_PROGRESS') {
      window.onbeforeunload = () => {
        return true
      }
    }

    return () => {
      window.onbeforeunload = null
    }
  }, [blockingWindow?.type])*/

  return (
    <>
      <div className={'hydraulic-calc__top-row'}>
        <span className={'hydraulic-calc__title header2'}>Гидравлический расчет</span>
      </div>
      {hydroSettings ? (
        <>
          <div>
            <div className={'hydraulic-calc__sub-title header3'}>Режим расчета</div>
            <div className={'hydraulic-calc__calc-types'}>
              <RadioButton
                value={calcType || 'SET_PERFORMANCE'}
                setValue={setCalcType}
                id={'MAX_PIPELINE_CAPACITY'}
                label={'Расчет максимальной пропускной способности трубопровода'}
              />
              <RadioButton
                value={calcType || 'SET_PERFORMANCE'}
                setValue={setCalcType}
                id={'SET_PERFORMANCE'}
                label={'Расчет для заданной производительности'}
              />
            </div>
            <div className={'hydraulic-settings__settings-row'}>
              <div className={'hydraulic-settings__settings-item year-item'}>
                <NumberInput
                  label={'Год расчета'}
                  value={calcType === 'SET_PERFORMANCE' ? year : undefined}
                  setValue={setYear}
                  disabled={calcType !== 'SET_PERFORMANCE'}
                  fieldName={'year'}
                  id={'hydraulics'}
                />
              </div>
            </div>
            <div className={'economic-results__record-divider'} />
            <div className={'hydraulic-calc__sub-title header3'}>Параметры расчета</div>
            <div className={'hydraulic-settings__settings-row'}>
              <div className={'hydraulic-settings__settings-item'}>
                <NumberInput
                  label={'Точн. расчета мощности'}
                  value={powerCalculationPrecision}
                  setValue={setPowerCalculationPrecision}
                  unit={'MВт'}
                  fieldName={'power_calculation_precision'}
                  id={'hydraulics'}
                />
              </div>
              <div className={'hydraulic-settings__settings-item'}>
                <NumberInput
                  label={'Точн. pасчета мощности ГПА'}
                  value={powerCalculationPrecisionGPU}
                  setValue={setPowerCalculationPrecisionGPU}
                  unit={'Вт'}
                  fieldName={'power_calculation_precision_GPU'}
                  id={'hydraulics'}
                />
              </div>
              <div className={'hydraulic-settings__settings-item'}>
                <NumberInput
                  label={'Точн. pасчета давления (СТД)'}
                  value={pressureCalculationPrecision}
                  setValue={setPressureCalculationPrecision}
                  unit={'MПа'}
                  fieldName={'pressure_calculation_precision'}
                  id={'hydraulics'}
                />
              </div>
              <div className={'hydraulic-settings__settings-item'}>
                <NumberInput
                  label={'Точн. pасчета вых. давления'}
                  value={outletPressureCalculationPrecision}
                  setValue={setOutletPressureCalculationPrecision}
                  unit={'MПа'}
                  fieldName={'outlet_pressure_calculation_precision'}
                  id={'hydraulics'}
                />
              </div>
            </div>
            <div className={'hydraulic-settings__settings-row'}>
              <div className={'hydraulic-settings__settings-item'}>
                <NumberInput
                  label={'Диапазон давлений (СТД)'}
                  unit={'MПа'}
                  value={pressureRangeForReductionCalculation}
                  setValue={setPressureRangeForReductionCalculation}
                  fieldName={'pressure_range_for_reduction_calculation__MPa'}
                  id={'hydraulics'}
                />
              </div>
              <div className={'hydraulic-settings__settings-item'}>
                <NumberInput
                  label={'Шаг уточн. Q пpи поиске Qмах'}
                  unit={
                    <>
                      <span className={'superscript-wrapper'}>
                        млн м<span className={'superscript'}>3</span>
                      </span>
                    </>
                  }
                  value={incrementStep}
                  setValue={setIncrementStep}
                  fieldName={'increment_step_for_Qmax_calculation__Mm3_per_day'}
                  id={'hydraulics'}
                />
              </div>
            </div>
          </div>
        </>
      ) : (
        <span className={'hydraulic-settings__loading-message normal-text'}>Подождите...</span>
      )}
    </>
  )
}

export default Settings
