import { Text } from './text'
import { arrow, ComputedStyle, station } from '../utils'
import { TextGroup } from './textGroup'

const color = ComputedStyle.getPropertyValue('--primary-text-color')

const symbols = (ctx: CanvasRenderingContext2D, x: number, y: number) => {
  const textShift = 56
  const csHalfHeight = 8
  Text(ctx, 'Условные обозначения', x, y, {
    fontSize: 16,
    fontWeight: 800,
    color,
    textAlign: 'left',
    textBaseLine: 'middle',
  })
  Text(ctx, 'Компрессорная станция', x + textShift + csHalfHeight, y + 50.5, {
    fontSize: 12,
    fontWeight: 600,
    color,
    textAlign: 'left',
    textBaseLine: 'top',
  })
  Text(ctx, 'Километр подключения к газопроводу', x + textShift + csHalfHeight, y + 104, {
    fontSize: 12,
    fontWeight: 600,
    color,
    textAlign: 'left',
    textBaseLine: 'top',
  })
  Text(ctx, 'Направление потока', x + textShift + csHalfHeight, y + 151, {
    fontSize: 12,
    fontWeight: 600,
    color,
    textAlign: 'left',
    textBaseLine: 'top',
  })
  TextGroup(ctx, x - 3, y + 120, ['12.0'], {
    fontWeight: 600,
    fontSize: 12,
    color,
    textAlign: 'left',
    textBaseLine: 'top',
    padding: 0,
    borderColor: '#E83232',
  })
  station(ctx, x + csHalfHeight, y + 44 + 14)
  station(ctx, x + csHalfHeight, y + 88 + 14)
  arrow(ctx, x, y + 154.5, 0.5, 0.5)
}

const paramsDecryption = (ctx: CanvasRenderingContext2D, x: number, y: number, params: any[]) => {
  const gap = 24
  const gapRow = 16
  const lineHeight = 18
  let [positionX, positionY] = [x, y]
  for (const item of params) {
    const [param, description] = Object.entries(item)[0] as [string, string]
    Text(ctx, param, positionX, positionY, {
      fontSize: 14,
      fontWeight: 800,
      color,
      textAlign: 'left',
      textBaseLine: 'middle',
    })
    positionX += ctx.measureText('Q').width + gap
    Text(ctx, description, positionX, positionY + 1.5, {
      fontSize: 12,
      fontWeight: 600,
      color,
      textAlign: 'left',
      textBaseLine: 'middle',
    })
    positionX = x
    positionY += lineHeight + gapRow
  }
}
export const Legend = (ctx: CanvasRenderingContext2D, x: number, y: number) => {
  symbols(ctx, x, y)
  Text(ctx, 'Расшифровка параметров', x + 422, y, {
    fontSize: 16,
    fontWeight: 800,
    color,
    textAlign: 'left',
    textBaseLine: 'middle',
  })
  paramsDecryption(ctx, x + 422, y + 45.5, [
    { Q: 'Количество транспортного газа, млн.м3/сут' },
    { P: 'Давление газа, МПа (абс)' },
    { Tвх: 'Температура потока в начальной точке, К' },
  ])
  paramsDecryption(ctx, x + 758, y + 45.5, [
    // { q: 'Суточный расход газа, млн.м3/сут' },
    { P: 'Давление всасывания/нагнетания 1 ступень/нагнетания 2 ступень, МПа (абс)' },
    { E: 'Степень повышения давления' },
    { T: 'Температура всасывания/нагнетания 1 ступень/нагнетания 2 ступень, К' },
    { Nn: 'Потребляемая мощность ГПА, МВт' },
    { K: 'Коэффициент загрузки ГПА' },
  ])
}
