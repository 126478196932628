import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectCreateTask, setTaskId, setCreateTask, setTaskStatus } from 'store/economicSlice'
import { createEconomicTask } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'

const useCreateTask = () => {
  const dispatch = useDispatch()
  const createTask = useSelector(selectCreateTask)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)

  const getError = (detail: string): JSX.Element => {
    switch (true) {
      case ['Для экономического расчета', 'требуется рассчитанный гидравлический расчет'].every((substr) =>
        detail.includes(substr),
      ):
        return (
          <>
            {'Для запуска экономического расчета необходимо'} <br /> {'выполнить гидравлический расчет со статусом'}{' '}
            <br /> {'"Рассчитано"'}
          </>
        )
      case ['Гидравлический результат неактуален', 'Требуется заново выполнить гидравлический расчет'].every((substr) =>
        detail.includes(substr),
      ):
        return (
          <>
            {'Исходные данные трубопровода были изменены.'} <br /> {'Пожалуйста, перезапустите гидравлический расчет.'}
          </>
        )
      default:
        return <>{'Непредвиденная ошибка'}</>
    }
  }

  const tryToCreateTask = async () => {
    if (trunkPipelineId && projectId) {
      await createEconomicTask(projectId, trunkPipelineId)
        .then((response) => {
          dispatch(setTaskId(response.data.id))
          dispatch(setTaskStatus('Запущен'))
        })
        .catch((err) => {
          if (err.response.status === 400) {
            dispatch(
              setBlockingWindow({
                objectName: 'Ошибка запуска расчета',
                message: getError(err.response.data?.detail),
                type: 'ERROR',
              }),
            )
          } else {
            dispatch(
              setBlockingWindow({
                objectName: 'Непредвиденная ошибка',
                message: 'Непредвиденная ошибка',
                type: 'ERROR',
              }),
            )
          }
          dispatch(setCreateTask(false))
        })
    }
  }

  React.useEffect(() => {
    if (createTask) tryToCreateTask().then()
  }, [createTask])

  return
}

export default useCreateTask
