import React from 'react'
import { ITableCell } from './types'
import TextInput from '../Input/textInput'
import NumberInput from '../Input/numberInput'
import Checkbox from '../Checkbox'
import classNames from 'classnames'
import Dropdown from '../Dropdown/Dropdown'
import RadioButton from '../RadioButton'
export const TableCell: React.FC<ITableCell> = ({
  id,
  type,
  value,
  fieldName,
  editable = true,
  signed = false,
  noEmpty = false,
  minValue = undefined,
  options,
  displayedField,
  setValue,
  tableRef,
  className,
  cellClassNames,
}) => {
  const getCellByType = () => {
    switch (type) {
      case 'Text':
        return (
          <TextInput
            classNames={classNames('table-input', className)}
            value={value}
            disabled={!editable}
            fieldName={fieldName}
            maxlength={50}
            setValue={(value, fieldName) => setValue && setValue(id, value, fieldName as string)}
          />
        )
      case 'Number':
        return (
          <NumberInput
            id={id}
            key={`${id}-${fieldName}`}
            classNames={classNames('table-input', className)}
            value={value}
            disabled={!editable}
            fieldName={fieldName}
            signed={signed}
            noEmpty={noEmpty}
            minValue={minValue}
            setValue={(value, fieldName) => setValue && setValue(id, value, fieldName as string)}
          />
        )
      case 'Boolean':
        return value === null ? (
          <NumberInput classNames={classNames('table-input no-padding', className)} value={undefined} disabled={true} />
        ) : (
          <Checkbox
            checkedValue={value}
            disabled={!editable}
            setCheckedValue={(value) => setValue && setValue(id, value, fieldName as string)}
          />
        )
      case 'Select':
        return (
          <RadioButton
            value={value}
            disabled={!editable}
            id={id}
            label={''}
            setValue={(value) => setValue && setValue(id, value, fieldName as string)}
          />
        )
      case 'Option':
        return (
          <Dropdown
            disabled={!editable}
            options={options || []}
            selectedValue={value}
            displayedField={displayedField || ''}
            setSelectedValue={(value) => setValue && setValue(id, value, fieldName as string)}
            panelRef={tableRef}
          />
        )
      case 'OptionAsButton':
        return (
          <button
            className={'grid-table__cell-btn'}
            disabled={!editable}
            onClick={() => setValue && setValue(id, value, fieldName as string)}
          >
            <Dropdown
              disabled={true}
              options={[]}
              selectedValue={value}
              displayedField={displayedField || ''}
              panelRef={tableRef}
            />
          </button>
        )
      case 'Column':
        return <div className={'grid-table__header'}>{value}</div>
    }
  }
  return <div className={classNames('grid-table__cell', cellClassNames, type?.toLowerCase())}>{getCellByType()}</div>
}
