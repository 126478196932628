import React, { useState } from 'react'
import Settings from './components/Settings/settings'
import './hydraulicCalc.scss'
import { useDispatch, useSelector } from 'react-redux'
import { resetHydraulicSliceState, selectCreateTask, selectTaskStatus, setCreateTask } from 'store/hydraulicSlice'
import Results from './components/Results/results'
import classNames from 'classnames'
import Resizable from 'components/Resizeable/resizable'
import Handle from 'components/Handle/handle'
import Button from 'components/Button'
import DownloadIcon from 'images/DownloadIcon'
import RedoIcon from 'images/RedoIcon'
import useGetDumps from './components/Results/hooks/useGetDumps'

const HydraulicCalc: React.FC = () => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const createTask = useSelector(selectCreateTask)
  const [showSettings, setShowSettings] = React.useState<boolean>()
  const [requestDump, setRequestDump] = useState<boolean>(false)
  const [hydroSettingsReceived, setHydroSettingsReceived] = useState<boolean>(false)

  useGetDumps({ requestData: requestDump, setRequestData: setRequestDump })

  React.useEffect(() => {
    if (['Рассчитан', 'Рассчитано частично', 'Ошибка', 'Отменен'].includes(taskStatus!)) {
      setShowSettings(false)
    }
  }, [taskStatus])

  const calculate = () => {
    dispatch(resetHydraulicSliceState())
    dispatch(setCreateTask(true))
  }

  return (
    <Resizable className={'hydraulic-calc__container'} handleElement={<Handle />}>
      {(!['Рассчитан', 'Ошибка', 'Рассчитано частично'].includes(taskStatus!) || showSettings) && (
        <>
          <div className={classNames('hydraulic-calc__content')}>
            <Settings setHydroSettingsReceived={setHydroSettingsReceived} />
          </div>
          <div className={'calc__bottom-panel'}>
            {showSettings && (
              <Button
                className={'hydraulic-settings__button'}
                mode={'secondary'}
                onClick={() => setShowSettings(false)}
              >
                Отменить
              </Button>
            )}
            <Button
              className={'hydraulic-settings__button'}
              onClick={calculate}
              disabled={createTask || !hydroSettingsReceived}
            >
              Рассчитать
            </Button>
          </div>
        </>
      )}
      {['Рассчитан', 'Ошибка', 'Рассчитано частично'].includes(taskStatus!) && !showSettings && (
        <>
          <div className={classNames('hydraulic-calc__content')}>
            <Results />
          </div>
          <div className={'calc__bottom-panel'}>
            <Button mode={'secondary'} onClick={() => setRequestDump(true)}>
              <DownloadIcon />
              Лог-файл
            </Button>
            <Button onClick={() => setShowSettings(true)}>
              <RedoIcon className={'redo-icn'} />
              Пересчитать с новыми параметрами
            </Button>
          </div>
        </>
      )}
    </Resizable>
  )
}

export default HydraulicCalc
