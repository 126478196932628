import { useDispatch, useSelector } from 'react-redux'
import { selectTaskResults, selectTaskStatus, setTaskResults } from 'store/hydraulicSlice'
import {
  getHydraulicResultCompressorStations,
  getHydraulicResultPipelines,
  getHydraulicResultReductionNodes,
} from 'services/apiRequests'
import React from 'react'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { all, spread } from 'axios'
import { setBlockingWindow } from 'store/commonSlice'

const useGetResults = () => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const taskResults = useSelector(selectTaskResults)

  const tryToGetResults = async () => {
    if (trunkPipelineId && projectId)
      all([
        getHydraulicResultCompressorStations(projectId, trunkPipelineId),
        getHydraulicResultPipelines(projectId, trunkPipelineId) as any,
        getHydraulicResultReductionNodes(projectId, trunkPipelineId),
      ])
        .then(
          spread((csRes, pipelineRes, reductionNodesRes) => {
            dispatch(
              setTaskResults({
                compressorStations: csRes.data,
                pipelines: pipelineRes.data,
                reductionNodes: reductionNodesRes.data,
              }),
            )
          }),
        )
        .catch(() => {
          dispatch(
            setBlockingWindow({
              objectName: 'Непредвиденная ошибка',
              message: 'Непредвиденная ошибка',
              type: 'ERROR',
            }),
          )
        })
  }

  React.useEffect(() => {
    if (taskStatus && ['Рассчитан', 'Рассчитано частично'].includes(taskStatus) && taskResults?.pipelines.length === 0)
      tryToGetResults()
  }, [taskStatus])

  return
}

export default useGetResults
