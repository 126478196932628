import { useDispatch, useSelector } from 'react-redux'
import {
  selectProject,
  setUpdatedElement,
  updateCompressorStation,
  updateReferenceNode,
  updateSink,
  updateSource,
} from 'store/projectSlice'
import React from 'react'
import { elementType } from 'store/types'
import { definitions } from 'generated/apiTypes'

export interface IUpdateCoordsProps {
  id: string
  type: elementType
  central_point: { [key: string]: unknown }
}
const useUpdateCoords = (props?: IUpdateCoordsProps) => {
  const dispatch = useDispatch()
  const project = useSelector(selectProject)
  const inStore = (type: IUpdateCoordsProps['type']) => {
    switch (type) {
      case 'SOURCE':
        return project.source !== undefined
      case 'SINK':
        return project.sink !== undefined
      case 'COMPRESSOR_STATIONS':
        return project.compressorStations.findIndex((item) => item.id === props?.id) > -1
      case 'REFERENCE_NODES':
        return project.referenceNodes.findIndex((item) => item.id === props?.id) > -1
      case 'NODES_REDUCTIONS':
        return project.reductionNodes.findIndex((item) => item.id === props?.id) > -1
      default:
        return false
    }
  }
  const updateLocalCoords = (type: IUpdateCoordsProps['type']) => {
    if (props) {
      switch (type) {
        case 'SOURCE':
          dispatch(
            updateSource({
              ...project.source,
              central_point: {
                ...props.central_point,
                coordinates: (props.central_point.coordinates as number[]).map((coord) => Number(coord.toFixed(6))),
              },
            } as definitions['Source']),
          )
          break
        case 'SINK':
          dispatch(
            updateSink({
              ...project.sink,
              central_point: {
                ...props.central_point,
                coordinates: (props.central_point.coordinates as number[]).map((coord) => Number(coord.toFixed(6))),
              },
            } as definitions['Sink']),
          )
          break

        case 'COMPRESSOR_STATIONS':
          dispatch(
            updateCompressorStation({
              ...project.compressorStations.find((item) => item.id === props.id),
              central_point: {
                ...props.central_point,
                coordinates: (props.central_point.coordinates as number[]).map((coord) => Number(coord.toFixed(6))),
              },
            } as definitions['CompressorStation']),
          )
          break
        case 'REFERENCE_NODES':
          dispatch(
            updateReferenceNode({
              ...project.referenceNodes.find((item) => item.id === props.id),
              central_point: {
                ...props.central_point,
                coordinates: (props.central_point.coordinates as number[]).map((coord) => Number(coord.toFixed(6))),
              },
            } as definitions['ReferenceNode']),
          )
          break
      }
    }
  }
  const tryToUpdateCoords = () => {
    if (props) {
      if (inStore(props.type)) {
        updateLocalCoords(props.type)
      }
      dispatch(
        setUpdatedElement({
          elementID: props.id,
          type: props.type,
          params: {
            central_point: {
              ...props.central_point,
              coordinates: (props.central_point.coordinates as number[]).map((coord) => Number(coord.toFixed(6))),
            },
          } as { [key: string]: unknown },
        }),
      )
    }
  }

  React.useEffect(() => {
    if (props?.id) tryToUpdateCoords()
  }, [props])

  return
}

export default useUpdateCoords
