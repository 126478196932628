import React from 'react'

import { createConstructionConditionsHandbookVersion } from 'services/apiRequests'

import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItem, setDirectoryItem } from 'store/directorySlice'
import { definitions } from 'generated/apiTypes'
import { setBlockingWindow } from 'store/commonSlice'
import { VersionEconomicsConstructionConditionsSet } from 'store/types'

const useCreateConstructionConditionsVersion = (saveData: boolean | undefined): boolean => {
  const dispatch = useDispatch()
  const directoryItem = useSelector(selectDirectoryItem) as VersionEconomicsConstructionConditionsSet
  const [result, setResult] = React.useState(false)

  const tryToCreateConstructionConditionsVersion = async () => {
    const construction_conditions: definitions['VersionEconomicsConstructionConditionsSetCreate']['construction_conditions'] =
      []
    directoryItem?.construction_conditions.forEach((item) => {
      const { id, area_type, coefficient, is_modified } = item
      construction_conditions.push({
        id,
        coefficient,
        is_modified,
        area_type_id: area_type.id,
      })
    })
    const ground_construction_conditions = directoryItem.ground_construction_conditions.is_modified
      ? { permafrost: directoryItem.ground_construction_conditions.permafrost }
      : {}
    await createConstructionConditionsHandbookVersion({
      construction_conditions,
      ground_construction_conditions,
    })
      .then((res) => {
        dispatch(
          setDirectoryItem({
            ...res.data,
            construction_conditions: res.data.construction_conditions.map((item) => {
              return {
                ...item,
                is_modified: false,
              }
            }),
          }),
        )
        setResult(true)
      })
      .catch((err) => {
        if (err.response.status === 400 && err.response.data.some((item: object) => Object.keys(item).length > 0)) {
          dispatch(
            setBlockingWindow({
              objectName: 'Ошибка сохранения',
              message: (
                <div className='error-window__errors-list-container'>
                  {err.response.data.map((directoryItem: any, index: number) => {
                    if (Object.keys(directoryItem).length > 0) {
                      return (
                        <div key={index}>
                          {'При сохранении возникли следующие ошибки:'}
                          <ul className='error-window__errors-list' key={index}>
                            {Object.entries(directoryItem).map(([key, value], ix) => {
                              return <li key={ix}>{`${key}: ${value}`}</li>
                            })}
                          </ul>
                        </div>
                      )
                    }
                  })}
                </div>
              ),
              type: 'ERROR',
            }),
          )
        } else
          dispatch(
            setBlockingWindow({
              objectName: 'Непредвиденная ошибка',
              message: 'Непредвиденная ошибка',
              type: 'ERROR',
            }),
          )
      })
  }

  React.useEffect(() => {
    if (saveData !== undefined) {
      setResult(false)
      tryToCreateConstructionConditionsVersion().then()
    }
  }, [saveData])

  return result
}

export default useCreateConstructionConditionsVersion
