import React from 'react'

import { createCSUnitCostsHandbookVersion } from 'services/apiRequests'

import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItem, setDirectoryItem } from 'store/directorySlice'
import { definitions } from 'generated/apiTypes'
import { setBlockingWindow } from 'store/commonSlice'

const useCreateCSUnitCostsVersion = (saveData: boolean | undefined): boolean => {
  const dispatch = useDispatch()
  const directoryItem = useSelector(selectDirectoryItem) as definitions['PowerToCompressorStationUnitCosts']
  const [result, setResult] = React.useState(false)

  const tryToCreateCSUnitCostsVersion = async () => {
    await createCSUnitCostsHandbookVersion(directoryItem)
      .then((res) => {
        dispatch(setDirectoryItem(res.data))
        setResult(true)
      })
      .catch((err) => {
        if (err.response.status === 400 && err.response.data.some((item: object) => Object.keys(item).length > 0)) {
          dispatch(
            setBlockingWindow({
              objectName: 'Ошибка сохранения',
              message: (
                <div className='error-window__errors-list-container'>
                  {err.response.data.map((directoryItem: any, index: number) => {
                    if (Object.keys(directoryItem).length > 0) {
                      return (
                        <div key={index}>
                          {'При сохранении возникли следующие ошибки:'}
                          <ul className='error-window__errors-list' key={index}>
                            {Object.entries(directoryItem).map(([key, value], ix) => {
                              return <li key={ix}>{`${key}: ${value}`}</li>
                            })}
                          </ul>
                        </div>
                      )
                    }
                  })}
                </div>
              ),
              type: 'ERROR',
            }),
          )
        } else
          dispatch(
            setBlockingWindow({
              objectName: 'Непредвиденная ошибка',
              message: 'Непредвиденная ошибка',
              type: 'ERROR',
            }),
          )
      })
  }

  React.useEffect(() => {
    if (saveData !== undefined) {
      setResult(false)
      tryToCreateCSUnitCostsVersion().then()
    }
  }, [saveData])

  return result
}

export default useCreateCSUnitCostsVersion
