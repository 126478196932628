import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { setBlockingWindow } from 'store/commonSlice'
import { elementType, IBlockingWindow } from 'store/types'
import { RootState } from 'store/configureStore'
import { setUpdatedElement, resetShowElementInfo } from 'store/projectSlice'
import { definitions } from 'generated/apiTypes'

export interface IDeleteObjectProps {
  objectType?: elementType | null
  objectId?: string | null
  objectName?: string | null
  index?: number
  lineID?: string
}
const useDeleteObject = (props?: IDeleteObjectProps) => {
  const dispatch = useDispatch()
  const [deleteObj, setDeleteObj] = useState(false)

  const compressorStations = useSelector((store: RootState) =>
    store.project.detail ? store.project.detail.compressor_stations : [],
  )
  const referenceNodes = useSelector((store: RootState) =>
    store.project.detail ? store.project.detail.reference_nodes : [],
  )
  const reductionNodes = useSelector((store: RootState) =>
    store.project.detail ? store.project.detail.nodes_reductions : [],
  )
  const pipelines = useSelector((store: RootState) => store.map.pipelines)
  const getObjectName = () => {
    switch (props?.objectType) {
      case 'COMPRESSOR_STATIONS':
        return compressorStations.find((obj) => obj.id === props.objectId)?.name
      case 'REFERENCE_NODES':
        return referenceNodes.find((obj) => obj.id === props.objectId)?.name
      case 'NODES_REDUCTIONS':
        return reductionNodes.find((obj) => obj.id === props.objectId)?.name
      default:
        return ''
    }
  }
  const handleKeyDown = (event: KeyboardEvent) => {
    if (
      event.code === 'Delete' &&
      props?.objectType &&
      ['COMPRESSOR_STATIONS', 'REFERENCE_NODES', 'POINTS', 'NODES_REDUCTIONS'].includes(props?.objectType)
    ) {
      if (props?.objectType === 'POINTS') {
        const lineIndex = pipelines.findIndex((pipeline) => pipeline.id === props.lineID)
        if (lineIndex > -1 && props.index) {
          const pipeline = structuredClone(pipelines[lineIndex]) as definitions['Pipeline']
          pipeline.line.coordinates = [
            ...(pipeline.line.coordinates as number[]).slice(0, props.index),
            ...(pipeline.line.coordinates as number[]).slice(props.index + 1),
          ]
          dispatch(
            setUpdatedElement({
              elementID: props.lineID as string,
              type: props?.objectType,
              params: { central_point: pipeline.line } as { [key: string]: unknown },
            }),
          )
        }
        dispatch(resetShowElementInfo())
      } else
        dispatch(
          setBlockingWindow({
            objectID: props?.objectId,
            objectType: props?.objectType as IBlockingWindow['objectType'],
            type: 'DELETE_CONFIRM',
            objectName: getObjectName(),
          }),
        )
      setDeleteObj(true)
    }
  }
  useEffect(() => {
    if (props?.objectType && props.objectId) {
      document.addEventListener('keydown', handleKeyDown)
    }
    return () => {
      setDeleteObj(false)
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [props?.objectType, props?.objectId, props?.index, props?.lineID])

  return deleteObj
}

export default useDeleteObject
