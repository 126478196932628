import React from 'react'
import TaskIcon from 'images/TaskIcon'
import './Task.scss'
import CalcStatus from 'components/CalcStatus'
import { definitions } from 'generated/apiTypes'
import Button from 'components/Button'
import { handleStorage } from 'services/handleStorage'
import { useDispatch } from 'react-redux'
import { setBlockingWindow } from 'store/commonSlice'
import { Link } from 'react-router-dom'

interface ITaskProps {
  title: definitions['TaskQueue']['task_type']
  id: definitions['TaskQueue']['task_id']
  project: definitions['TaskQueue']['project']
  author: definitions['TaskQueue']['user']
  date: definitions['TaskQueue']['start_time']
  status: definitions['TaskQueue']['task_status']
}

const Task: React.FC<ITaskProps> = ({ title, id, project, author, date, status }) => {
  const dispatch = useDispatch()

  const stopTask = () => {
    dispatch(
      setBlockingWindow({
        objectName: title,
        objectID: id,
        type: 'STOP_TASK_CONFIRM',
      }),
    )
  }

  return (
    <>
      <div className='tasks__table-item task__title-column'>
        <TaskIcon className={'tasks__table-item-title-icon'} />
        <div className='task__title-wrapper'>
          <div className='normal-text task__title'>{title}</div>
          <div className='normal-text task__secondary-text task__second-row'>{id}</div>
        </div>
      </div>
      <div className='tasks__table-item task__project-wrapper'>
        <div className='normal-text task__title'>
          <Link className={'link'} to={`/project/${project.id}`} target='_blank' rel='noopener noreferrer'>
            {project.name}
          </Link>
        </div>
        <div className='normal-text task__secondary-text task__second-row'>{`${author?.last_name || ''} ${
          author?.first_name || ''
        }`}</div>
      </div>
      <div className='tasks__table-item normal-text task__title'>{date}</div>
      <div className='tasks__table-item task__status-wrapper'>
        <CalcStatus classNames={'tasks__table-status'} status={status} />
      </div>
      <div className={'tasks__table-item'}>
        {(handleStorage.getIsStaff() === 'true' || handleStorage.getUserId() === author?.id?.toString()) &&
        ['Запущен', 'Ожидает очереди'].includes(status) ? (
          <Button mode={'secondary'} onClick={stopTask}>
            Отменить
          </Button>
        ) : (
          <div className={'task__idle-stop task__secondary-text'}>—</div>
        )}
      </div>
    </>
  )
}

export default Task
