import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectTaskCalculationProgress } from '../../store/hydraulicSlice'
import { Link } from 'react-router-dom'
import Button from '../Button'
import { selectTaskIsCanceling, setBlockingWindow } from '../../store/commonSlice'
import { handleStorage } from '../../services/handleStorage'

interface IHydroProgressWindowProps {
  blockingWindow: any
}
export const HydroProgressWindow: React.FC<IHydroProgressWindowProps> = ({ blockingWindow }) => {
  const dispatch = useDispatch()
  const taskCalculationProgress = useSelector(selectTaskCalculationProgress)
  const taskIsCanceling = useSelector(selectTaskIsCanceling)

  const stopTask = () => {
    dispatch(
      setBlockingWindow({
        objectName: 'Гидравлический расчет',
        type: 'STOP_TASK_CONFIRM',
      }),
    )
  }

  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container'>
        <h1 className={'project-list__projects-title no-margin'}>Идет расчет</h1>
        <div>
          <div className='progress__loader-container'>
            <div className='progress__loader' />
            <div className={'progress__percentage'}>{taskCalculationProgress || 0}%</div>
          </div>
          <div className={'progress__hint normal-text'}>{'Выполняется гидравлический расчет'}</div>
          <Link className={'link'} to={'/'} state={{ tab: 'tasks' }}>
            Очередь задач
          </Link>
        </div>
        <Button
          className={'progress__stop-task-btn'}
          mode={'secondary'}
          onClick={stopTask}
          disabled={
            taskIsCanceling ||
            (handleStorage.getIsStaff() === 'false' && handleStorage.getUserId() !== blockingWindow?.objectName) ||
            !['true', 'false'].includes(handleStorage.getIsStaff() as string)
          }
        >
          Отменить расчет
        </Button>
      </div>
    </div>
  )
}
