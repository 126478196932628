import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { IHydraulicSlice, IModel10Slice } from './types'

export const initialState: IHydraulicSlice = {
  taskId: undefined,
  createTask: false,
  taskCalculationProgress: undefined,
  taskStatus: undefined,
  taskResults: {
    compressorStations: [],
    pipelines: [],
    reductionNodes: [],
  },
}

export const hydraulicSlice = createSlice({
  name: 'hydraulic',
  initialState,
  reducers: {
    setTaskId: (state, action: PayloadAction<IModel10Slice['taskId']>) => {
      return { ...state, taskId: action.payload }
    },
    setCreateTask: (state, action: PayloadAction<IHydraulicSlice['createTask']>) => {
      return { ...state, createTask: action.payload }
    },
    setTaskCalculationProgress: (state, action: PayloadAction<IModel10Slice['taskCalculationProgress']>) => {
      return { ...state, taskCalculationProgress: action.payload }
    },
    setTaskStatus: (state, action: PayloadAction<IHydraulicSlice['taskStatus']>) => {
      return { ...state, taskStatus: action.payload }
    },
    setTaskResults: (state, action: PayloadAction<IHydraulicSlice['taskResults']>) => {
      return { ...state, taskResults: action.payload }
    },
    setErrorMessage: (state, action: PayloadAction<IHydraulicSlice['errorMessage']>) => {
      return { ...state, errorMessage: action.payload }
    },
    resetTaskResults: (state) => {
      return { ...state, taskResults: initialState.taskResults }
    },
    resetHydraulicSliceState: () => {
      return initialState
    },
  },
})

export const {
  setTaskId,
  setCreateTask,
  setTaskStatus,
  setTaskCalculationProgress,
  setTaskResults,
  setErrorMessage,
  resetTaskResults,
  resetHydraulicSliceState,
} = hydraulicSlice.actions

export const selectTaskId = (state: RootState) => state.hydraulic.taskId

export const selectCreateTask = (state: RootState) => state.hydraulic.createTask

export const selectTaskStatus = (state: RootState) => state.hydraulic.taskStatus

export const selectTaskCalculationProgress = (state: RootState) => state.hydraulic.taskCalculationProgress

export const selectTaskResults = (state: RootState) => state.hydraulic.taskResults

export const selectErrorMessage = (state: RootState) => state.hydraulic.errorMessage

export default hydraulicSlice.reducer
