import React from 'react'
import { MultiSelectTable } from 'components/Table/MultiSelectTable/MultiSelectTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  addSelectedItem,
  deselectSelectedItemById,
  selectDirectoryItems,
  selectSelectedItems,
  updateItemFieldById,
  setModified,
} from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import useGetACU from './hooks/useGetACU'
import { columns } from './tableConfig'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import { definitions } from 'generated/apiTypes'
import useCreateACUVersion from './hooks/useCreateACUVersion'
import { generateUID } from '../../../utils'
import { setBlockingWindow } from 'store/commonSlice'
import { IHandbookACUNewUnit } from './types'
import './DirectoryACU.scss'

interface IDirectoryACAProps {
  directoryKey: keyof definitions['Handbooks']
}

const newItemProperties = {
  critical_temperature__K: 1,
  cooling_temperature_calculation_precision__degC: 1,
  critical_pressure__kgs_per_cm2: 1,
  fan_efficiency: 1,
  fan_volumetric_airflow_rate__m3_per_hour: 1,
  fan_blade_rotation_angle__deg: 1,
  section_contraction_coefficient: 1,
  external_fouling_thermal_resistance__m2_hour_degC_per_kcal: 1,
  free_cross_section_area_in_front_of_finned_tube_sections__m2: 1,
  internal_fouling_thermal_resistance__m2_hour_degC_per_kcal: 1,
  pipes_inner_diameter__m: 1,
  pipes_length_per_unit__m: 1,
  n_pipes_per_unit: 1,
  surface_enlargement_coefficient: 1,
  n_pipes_rows_in_section: 1,
  n_pipes_passes_in_section: 1,
  unit_cooling_surface__m2: 1,
  air_heat_capacity__kcal_per_kg_deg: 1,
  air_pressure__mmHg: 1,
  created: true,
  modified: false,
}
const DirectoryACU: React.FC<IDirectoryACAProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const selectedItems = useSelector(selectSelectedItems)
  const directoryItems = useSelector(selectDirectoryItems)
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)
  const [newItem, setNewItem] = React.useState<IHandbookACUNewUnit>({ id: '', name: '', ...newItemProperties })

  useGetACU(requestData)
  const saveResult = useCreateACUVersion(saveData)

  React.useEffect(() => {
    setNewItem({ id: generateUID(), name: `АВО ${directoryItems.length + 1}`, ...newItemProperties })
  }, [directoryItems.length])

  React.useEffect(() => {
    if (saveResult) setEditMode(false)
  }, [saveResult])

  const onItemSelect = (id: string) => {
    const itemToAdd = directoryItems?.find((item) => item.id === id)
    if (itemToAdd) dispatch(addSelectedItem(itemToAdd))
  }

  const onItemDeselect = (id: string) => {
    dispatch(deselectSelectedItemById(id))
  }

  const onSaveItemField = async (id: string, value: string | number, fieldName: string) => {
    const uniqueFiled = 'name'
    if (
      fieldName === uniqueFiled &&
      (directoryItems as definitions['HandbookACU'][])?.some((item) => item[uniqueFiled] === value)
    ) {
      dispatch(
        setBlockingWindow({
          type: 'ERROR',
          objectName: 'Ошибка заполнения',
          message: 'Значение поля “Название” должно быть уникальным',
        }),
      )
      const oldItem = (directoryItems as definitions['HandbookACU'][]).find((item) => item.id === id)
      if (oldItem) {
        await dispatch(updateItemFieldById({ id, value, fieldName }))
        dispatch(updateItemFieldById({ id, value: oldItem[uniqueFiled], fieldName }))
      }
    } else {
      dispatch(updateItemFieldById({ id, value, fieldName }))
      dispatch(setModified(id))
      setIsModified(true)
    }
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      title={getHandbookName(directoryKey)}
      isModified={isModified}
      setIsModified={setIsModified}
      newItemToAdd={newItem as definitions['HandbookACU']}
    >
      <div className={'directory__content'}>
        <MultiSelectTable
          selectedItems={selectedItems}
          columns={columns}
          editMode={editMode}
          dataSource={directoryItems}
          setValue={onSaveItemField}
          onItemSelect={onItemSelect}
          onItemDeselect={onItemDeselect}
          className={'directory-table__container acu-table__container'}
        />
      </div>
    </DirectoryWrapper>
  )
}

export default DirectoryACU
