import { definitions } from '../generated/apiTypes'

export const handleStorage = {
  setToken: (token: string) => {
    return localStorage.setItem('accessToken', token)
  },
  getToken: () => {
    return localStorage.getItem('accessToken')
  },
  removeToken: () => {
    localStorage.removeItem('accessToken')
  },
  setUser: (first_name: string, last_name: string) => {
    localStorage.setItem('first_name', first_name)
    localStorage.setItem('last_name', last_name)
  },
  getUser: () => {
    return {
      first_name: localStorage.getItem('first_name'),
      last_name: localStorage.getItem('last_name'),
    }
  },
  removeUser: () => {
    localStorage.removeItem('first_name')
    localStorage.removeItem('last_name')
  },
  setAuthorities: (authorities: definitions['Group'][]) => {
    authorities.forEach((role) => {
      if (role.name === 'librarian') localStorage.setItem('librarianAuthority', 'librarian')
    })
  },
  getLibrarianAuthority: () => {
    return localStorage.getItem('librarianAuthority')
  },
  removeAuthorities: () => {
    if (localStorage.getItem('librarianAuthority')) localStorage.removeItem('librarianAuthority')
  },
  setUserId: (userId: string) => {
    return localStorage.setItem('userId', userId)
  },
  getUserId: () => {
    return localStorage.getItem('userId')
  },
  removeUserId: () => {
    localStorage.removeItem('userId')
  },
  setIsStaff: (isStaff: boolean) => {
    return localStorage.setItem('isStaff', isStaff.toString())
  },
  getIsStaff: () => {
    return localStorage.getItem('isStaff')
  },
  removeIsStaff: () => {
    localStorage.removeItem('isStaff')
  },
  setShowAllProjects: () => {
    return localStorage.setItem('showAllProjects', 'true')
  },
  getShowAllProjects: () => {
    return localStorage.getItem('showAllProjects')
  },
  removeShowAllProjects: () => {
    localStorage.removeItem('showAllProjects')
  },
  setOptNotificationShown: (userId: string) => {
    return localStorage.setItem(
      'optNotificationShown',
      [...(localStorage.getItem('optNotificationShown')?.split(',') || []), userId].toString(),
    )
  },
  getOptNotificationShown: (): string[] | undefined => {
    return localStorage.getItem('optNotificationShown')?.split(',')
  },
}
