import React from 'react'

import { createUnitCostsHandbookVersion } from 'services/apiRequests'

import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItems, setDirectoryItems, setId, setVersionId } from 'store/directorySlice'
import { definitions } from 'generated/apiTypes'
import { setBlockingWindow } from 'store/commonSlice'
import { HandbookAreaUnitCostRule } from 'store/types'

const useCreateUnitCostsVersion = (saveData: boolean | undefined): boolean => {
  const dispatch = useDispatch()
  const directoryItems = useSelector(selectDirectoryItems)
  const [result, setResult] = React.useState(false)

  const tryToCreateUnitCostsVersion = async () => {
    if (directoryItems) {
      const items: definitions['HandbookAreaUnitCostRuleCreate'][] = []
      ;(directoryItems as HandbookAreaUnitCostRule[]).forEach((item) => {
        const { id, modified, created, pipe_type, area_type, unit_cost__thousand_rub_per_km } = item
        if (created) {
          items.push({
            unit_cost__thousand_rub_per_km,
            area_type_id: area_type.id,
            pipe_type_id: pipe_type.id,
            modified: false,
          })
        } else {
          items.push({
            id,
            unit_cost__thousand_rub_per_km,
            area_type_id: area_type.id,
            pipe_type_id: pipe_type.id,
            modified,
          })
        }
      })
      await createUnitCostsHandbookVersion(items)
        .then((res) => {
          dispatch(setId(res.data.id))
          dispatch(setVersionId(res.data.id))
          dispatch(
            setDirectoryItems(
              res.data.units.map((unit) => {
                return {
                  ...unit,
                  modified: false,
                }
              }),
            ),
          )
          setResult(true)
        })
        .catch((err) => {
          if (err.response.status === 400 && err.response.data.some((item: object) => Object.keys(item).length > 0)) {
            dispatch(
              setBlockingWindow({
                objectName: 'Ошибка сохранения',
                message: (
                  <div className='error-window__errors-list-container'>
                    {err.response.data.map((directoryItem: any, index: number) => {
                      if (Object.keys(directoryItem).length > 0) {
                        return (
                          <div key={index}>
                            {`При сохранении ${
                              (directoryItems[index] as definitions['HandbookAreaUnitCostRule'])?.id
                            } возникли следующие ошибки:`}
                            <ul className='error-window__errors-list' key={index}>
                              {Object.entries(directoryItem).map(([key, value], ix) => {
                                return <li key={ix}>{`${key}: ${value}`}</li>
                              })}
                            </ul>
                          </div>
                        )
                      }
                    })}
                  </div>
                ),
                type: 'ERROR',
              }),
            )
          } else
            dispatch(
              setBlockingWindow({
                objectName: 'Непредвиденная ошибка',
                message: 'Непредвиденная ошибка',
                type: 'ERROR',
              }),
            )
        })
    }
  }

  React.useEffect(() => {
    if (saveData !== undefined) {
      setResult(false)
      tryToCreateUnitCostsVersion().then()
    }
  }, [saveData])

  return result
}

export default useCreateUnitCostsVersion
