import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getTasksQueue } from 'services/apiRequests'
import { resetBlockingWindow, selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { resetTasksSliceState, setTasks } from 'store/tasksSlice'
import { IBlockingWindow } from '../../../../store/types'
import { useLocation } from 'react-router-dom'

const useGetTasks = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const blockingWindow = useSelector(selectBlockingWindow) as IBlockingWindow

  const tryToGetTasks = async () => {
    await getTasksQueue()
      .then((response) => {
        dispatch(
          setTasks(
            /*[
              {
                task_type: 'Оптимизация магистрального трубопровода',
                task_id: '123456789',
                task_status: 'Запущен',
                user: { first_name: 'Анна', last_name: 'Ширшова' },
                start_time: '02-08-2023 10:44',
                project: { id: '9876', name: 'Проект 32' },
              },
              {
                task_type: 'Расчет гидравлических показателей',
                task_id: '999886789',
                task_status: 'Ожидает очереди',
                user: { first_name: 'Anna', last_name: 'S' },
                start_time: '01-08-2023 23:44',
                project: { id: '9879096', name: 'Проект 7732' },
              },
              {
                task_type: 'Расчет гидравлических показателей',
                task_id: '999886789',
                task_status: 'Ошибка',
                user: { first_name: 'Anna', last_name: 'S' },
                start_time: '01-08-2023 23:44',
                project: { id: '9879096', name: 'Проект 7732' },
              },
              {
                task_type: 'Расчет гидравлических показателей',
                task_id: '999886789',
                task_status: 'Неактуальный',
                user: { first_name: 'Anna', last_name: 'S' },
                start_time: '01-08-2023 23:44',
                project: { id: '9879096', name: 'Проект 7732' },
              },
            ],*/ response.data,
          ),
        )
      })
      .catch(() => {
        dispatch(
          setBlockingWindow({
            objectName: 'Непредвиденная ошибка',
            message: 'Непредвиденная ошибка',
            type: 'ERROR',
          }),
        )
      })
  }

  React.useEffect(() => {
    if (['OPTIMIZATION_PROGRESS', 'HYDRO_PROGRESS', 'ECONOMIC_PROGRESS'].includes(blockingWindow.type as string))
      dispatch(resetBlockingWindow())
    tryToGetTasks().then()
    return () => {
      dispatch(resetTasksSliceState())
    }
  }, [])

  return
}

export default useGetTasks
