import React, { useState } from 'react'
import Settings from './Settings/settings'
import { useDispatch, useSelector } from 'react-redux'
import { resetOptimizationSliceState, selectTaskStatus, setCreateTask } from 'store/optimizationSlice'
import Results from './Results/results'
import useGetDumps from './Results/hooks/useGetDumps'
import Button from 'components/Button'
import DownloadIcon from 'images/DownloadIcon'
import RedoIcon from 'images/RedoIcon'
import './optimizationCalc.scss'
import { handleStorage } from 'services/handleStorage'
import { setBlockingWindow } from 'store/commonSlice'

const OptimizationCalc: React.FC = () => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const [showSettings, setShowSettings] = React.useState<boolean>()
  const [requestData, setRequestData] = useState<boolean>(false)
  useGetDumps({ requestData, setRequestData })

  React.useEffect(() => {
    if (['Рассчитан', 'Ошибка', 'Отменен'].includes(taskStatus!)) {
      setShowSettings(false)
    }
  }, [taskStatus])

  const calculate = () => {
    const userId = handleStorage.getUserId()
    if (userId && handleStorage.getOptNotificationShown()?.includes(userId)) {
      dispatch(resetOptimizationSliceState())
      dispatch(setCreateTask(true))
    } else {
      dispatch(
        setBlockingWindow({
          objectName: 'Построить трубопровод',
          objectType: 'TRUNK_PIPELINE',
          message: (
            <ul className='confirm-window__list'>
              <li>
                Результат автоматического построения трубопровода
                <br /> будет применен автоматически.
              </li>
              <li>
                Указанные параметры будут применены ко всем участкам
                <br /> трубопровода.
              </li>
            </ul>
          ),
          type: 'CONFIRM',
        }),
      )
    }
  }

  return (
    <div className={'optimization-calc__container'}>
      {(!['Рассчитан', 'Ошибка'].includes(taskStatus!) || showSettings) && (
        <>
          <Settings />
          <div className={'calc__bottom-panel'}>
            {showSettings && (
              <Button
                className={'hydraulic-settings__button'}
                mode={'secondary'}
                onClick={() => setShowSettings(false)}
              >
                Отменить
              </Button>
            )}
            <Button
              disabled={['Запущен', 'Ожидает очереди'].includes(taskStatus!)}
              className={'optimization-settings__calc-btn'}
              onClick={calculate}
            >
              Построить трубопровод
            </Button>
          </div>
        </>
      )}
      {['Рассчитан', 'Ошибка'].includes(taskStatus!) && !showSettings && (
        <>
          {' '}
          <Results />
          <div className={'calc__bottom-panel'}>
            <Button mode={'secondary'} onClick={() => setRequestData(true)}>
              <DownloadIcon />
              Лог-файл
            </Button>
            <Button onClick={() => setShowSettings(true)}>
              <RedoIcon className={'redo-icn'} />
              Пересчитать с новыми параметрами
            </Button>
          </div>{' '}
        </>
      )}
    </div>
  )
}

export default OptimizationCalc
