import React, { PropsWithChildren, ReactElement } from 'react'
import classNames from 'classnames'

interface IOptionProps<OptionType> {
  item: OptionType
  displayedField: string
  setSelectedValue?(value: OptionType): void
  closeOptionsList(): void
}

const Option = <OptionType,>({
  item,
  displayedField,
  setSelectedValue,
  closeOptionsList,
}: PropsWithChildren<IOptionProps<OptionType>>): ReactElement => {
  const optionRef = React.useRef<HTMLDivElement>(null)
  const valueRef = React.useRef<HTMLSpanElement>(null)

  const onOptionClick = () => {
    if (!(item as any)?.disabled) {
      if (setSelectedValue) setSelectedValue(item)
      closeOptionsList()
    }
  }

  return (
    <div
      className={classNames('dropdown__option', (item as OptionType & { disabled: boolean })?.disabled && 'disabled')}
      onClick={onOptionClick}
      ref={optionRef}
    >
      <span
        className={classNames(
          'dropdown__option-value normal-text',
          (item as OptionType & { disabled: boolean })?.disabled && 'disabled',
        )}
        ref={valueRef}
      >
        {(item as any)[displayedField] || (displayedField === '' && item) || 'Не задано'}
      </span>
    </div>
  )
}

export default Option
