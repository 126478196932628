import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Button from 'components/Button'
import React from 'react'
import './style.scss'
import { IBlockingWindow } from 'store/types'
interface IDeleteWindow {
  blockingWindow: IBlockingWindow
  deleteObject(): void
  closeWindow(): void
}
export const DeleteWindow: React.FC<IDeleteWindow> = ({ blockingWindow, deleteObject, closeWindow }) => {
  const titleByObjectType = () => {
    switch (blockingWindow?.objectType) {
      case 'PROJECT':
        return 'проекта'
      case 'PIPE':
        return 'нитей трубопровода'
      case 'COMPRESSOR_STATIONS':
        return 'компрессорной станции'
      case 'REFERENCE_NODES':
        return 'опорного узла'
      case 'HANDBOOK_ITEMS':
        return 'строк'
      case 'NODES_REDUCTIONS':
        return 'узла редуцирования газа'
      default:
        return ''
    }
  }
  const messageByObjectType = () => {
    switch (blockingWindow?.objectType) {
      case 'PROJECT':
        return 'проект'
      case 'PIPE':
        return 'нити'
      case 'COMPRESSOR_STATIONS':
        return 'компрессорную станцию'
      case 'REFERENCE_NODES':
        return 'опорный узел'
      case 'HANDBOOK_ITEMS':
        return 'выбранные строки'
      case 'NODES_REDUCTIONS':
        return 'узел редуцирования'
      default:
        return ''
    }
  }
  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container delete-confirm'>
        <div className={'blocking-window__header'}>
          {`Удаление ${titleByObjectType()}`}
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'blocking-window__body'}>
          {`Вы действительно хотите удалить ${messageByObjectType()}`}
          {blockingWindow.objectName !== undefined && ` “${blockingWindow.objectName}“`}?
        </div>
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Отменить
          </Button>
          <Button mode={'warn'} onClick={deleteObject}>
            Удалить
          </Button>
        </div>
      </div>
    </div>
  )
}
