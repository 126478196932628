import React, { useMemo, useState } from 'react'
import { CloseBtn } from '../CloseBtn/closeBtn'
import { useDispatch, useSelector } from 'react-redux'
import { resetDirectorySliceState, selectDirectoryItems } from 'store/directorySlice'
import { definitions } from 'generated/apiTypes'
import { readOnlyColumns } from 'pages/Directory/components/ACU/tableConfig'
import { Table } from '../Table/Table/Table'
import { selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import Button from '../Button'
import { ITableColumn } from 'components/Table/types'
import useGetACU from 'pages/Directory/components/ACU/hooks/useGetACU'
interface IGPUChoicesWindow {
  closeWindow(): void
}

export const ACUChoicesWindow: React.FC<IGPUChoicesWindow> = ({ closeWindow }) => {
  const dispatch = useDispatch()
  const directoryItems = useSelector(selectDirectoryItems) as definitions['HandbookGPU'][]
  const blockingWindow = useSelector(selectBlockingWindow)
  const [selectedID, setSelectedID] = useState<string>('')
  useGetACU(false)
  const getDataSource = (data: definitions['HandbookGPU'][]) => {
    return data.map((item) => {
      return { selected: selectedID || blockingWindow.objectID, ...item }
    })
  }
  const dataSource = useMemo(() => getDataSource(directoryItems as any), [directoryItems, selectedID])
  const currentColumns = [
    {
      title: '',
      type: 'Select',
      key: 'selected',
      editable: true,
      sticky: true,
    },
    ...readOnlyColumns,
  ]
  const selectACU = () => {
    dispatch(
      setBlockingWindow({
        objectID: selectedID,
        isConfirm: true,
      }),
    )
  }
  React.useEffect(() => {
    return () => {
      dispatch(resetDirectorySliceState())
    }
  }, [])
  if (directoryItems.length > 0)
    return (
      <div className='blocking-window-background'>
        <div className='blocking-window-container choices-table'>
          <div className={'blocking-window__header'}>
            Выбор АВО
            <CloseBtn close={closeWindow} />
          </div>
          {directoryItems.length > 0 && (
            <Table
              className={'choices-table__container'}
              columns={currentColumns as ITableColumn[]}
              dataSource={dataSource}
              setValue={setSelectedID}
            />
          )}
          <div className={'blocking-window__footer gap-16'}>
            <Button mode={'secondary'} onClick={closeWindow}>
              Отменить
            </Button>
            <Button onClick={selectACU} disabled={!selectedID}>
              Выбрать
            </Button>
          </div>
        </div>
      </div>
    )
  return <></>
}
