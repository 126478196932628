import './project.scss'
import { ProjectHeader } from 'components/Header/projectHeader'
import React, { useEffect, useState } from 'react'
import { ElementPanel } from './components/ElementPanel/elementPanel'
import { connect, useDispatch } from 'react-redux'
import {
  setProjectDetail,
  resetUpdatedElement,
  resetProject,
  setCompressorStations,
  setReferenceNodes,
  setReductionNodes,
  resetShowElementInfo,
  setShowElementInfo,
  setPipeTypes,
  setPipelines,
  updateSink,
  setLoadingHeightNodeID,
  updateSource,
  updateCompressorStation,
  updateReferenceNode,
  updateReductionNode,
} from 'store/projectSlice'
import { setMapNodes, setMapPipelines, resetMapSubmode, resetNodeCreation, resetMap } from 'store/mapSlice'
import { useLocation } from 'react-router-dom'
import {
  getProjectByIDRequest,
  updateSinkByIDRequest,
  updateSourceByIDRequest,
  updateSourceProfileByIDRequest,
  updateComponentsFlowByIDRequest,
  getNodesByProjectIDRequest,
  getPipelinesByProjectIDRequest,
  createReferenceNodeRequest,
  createCompressorStationRequest,
  updateCompressorStationByIDRequest,
  updateCompressorPlantByIDRequest,
  updateReferenceNodeByIDRequest,
  updatePipeByIDRequest,
  deleteCompressorStationByIDRequest,
  deleteReferenceNodeByIDRequest,
  updatePipelineByIDRequest,
  updateTrunkPipelineByID,
  getPipeTypesHandbook,
  getPipelineByIDRequest,
  getSinkByIDRequest,
  getSourceByIDRequest,
  getCompressorStationByIDRequest,
  getReferenceNodeByIDRequest,
  createReductionNodeRequest,
  updateReductionNodeByIDRequest,
  deleteReductionNodeByIDRequest,
  getReductionNodeByIDRequest,
} from 'services/apiRequests'
import { definitions } from 'generated/apiTypes'
import { point } from '@turf/turf'
import { ProjectMap } from 'components/Map/ProjectMap'
import { IBlockingWindow, IMap, INodeCreation, IProject } from 'store/types'
import { resetBlockingWindow } from 'store/commonSlice'
import Map from 'components/Map/map'
import { ToolTabKey } from './types'
import HydraulicCalc from './components/HydraulicCalc/hydraulicCalc'
import Tabs, { ITab } from 'components/Tabs/tabs'
import PathIcon from 'images/PathIcon'
import BarIcon from 'images/BarIcon'
import RubIcon from 'images/RubIcon'
interface IProjectProps {
  project: IProject
  mapMode: IMap['mode']
  nodeCreation: INodeCreation
  blockingWindow: IBlockingWindow
}
import './project.scss'
import { resetHydraulicSliceState } from 'store/hydraulicSlice'
import EconomicCalc from './components/EconomicCalc/economicCalc'
import { resetOptimizationSliceState, resetOptimizationTaskSettings } from 'store/optimizationSlice'
import { GasTransportScheme } from './components/GasTransportScheme/gasTransportScheme'
import { BlockingWindow } from '../../components/BlockWindow'
import useGetHydraulicTaskStatus from './hooks/useGetHydraulicTaskStatus'
import useGetOptimizationTaskStatus from './hooks/useGetOptimizationTaskStatus'
import axios, { AxiosResponse } from 'axios'
import useGetOptimizationResults from './hooks/useGetOptimizationResults'
import useStopTask from './hooks/useStopTask'
import useGetEconomicTaskStatus from './hooks/useGetEconomicTaskStatus'
import PathAutoIcon from 'images/PathAutoIcon'
import OptimizationCalc from './components/OptimizationCalc/optimizationCalc'
import NotFoundView from '../../components/NotFoundView/notFoundView'

const toolbarTabList: ITab<ToolTabKey>[] = [
  { key: 'MANUAL_MODE', tabContent: <PathIcon /> },
  { key: 'OPTIMIZATION_CALC', tabContent: <PathAutoIcon /> },
  { key: 'HYDRAULIC_CALC', tabContent: <BarIcon /> },
  { key: 'ECONOMIC_CALC', tabContent: <RubIcon /> },
]

const Project: React.FC<IProjectProps> = ({ project, nodeCreation, mapMode, blockingWindow }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [toolTabKey, setToolTabKey] = useState<ToolTabKey>('MANUAL_MODE')
  const [projectNotFoundError, setProjectNotFoundError] = useState<boolean>(false)
  const projectID: string = project.detail ? project.detail!.id : ''
  useGetHydraulicTaskStatus()
  useGetOptimizationTaskStatus()
  useGetEconomicTaskStatus()
  useGetOptimizationResults()
  useStopTask()

  const fetchProject = async (project_id: string) => {
    await getProjectByIDRequest(project_id)
      .then((res: any) => {
        dispatch(setProjectDetail(res.data))
      })
      .catch((err) => {
        if (err.response.status === 404) setProjectNotFoundError(true)
      })
    getPipelinesByProjectIDRequest(project_id).then((res: any) => {
      dispatch(setMapPipelines(res.data))
      // @ts-ignore
      const requests: Promise<AxiosResponse<definitions['DetailedPipeline']>>[] = res.data.map((pipeline) =>
        getPipelineByIDRequest(project.detail!.id, pipeline.id),
      )
      axios.all(requests).then((resArr) => {
        dispatch(setPipelines(resArr.map((res) => res.data)))
      })
    })
    getNodesByProjectIDRequest(project_id).then((res: any) => {
      dispatch(setMapNodes(res.data))
    })
  }
  useEffect(() => {
    if (location.pathname) {
      const project_id = location.pathname.split('/').pop()
      if (project_id) {
        getProjectByIDRequest(project_id)
          .then((res: any) => {
            dispatch(setProjectDetail(res.data))
          })
          .catch((err) => {
            if (err.response.status === 404) setProjectNotFoundError(true)
          })
        getPipeTypesHandbook().then((res) => dispatch(setPipeTypes(res.data.units)))
      }
    }
    return () => {
      dispatch(resetShowElementInfo())
      dispatch(resetProject())
      dispatch(resetMap())
      dispatch(resetHydraulicSliceState())
      dispatch(resetOptimizationSliceState())
      dispatch(resetOptimizationTaskSettings())
    }
  }, [location.pathname])

  const requestDetailedNodeByType = () => {
    switch (project.updatedElement.type) {
      case 'SINK':
        getSinkByIDRequest(projectID, project.updatedElement.elementID!).then((res) => {
          dispatch(updateSink(res.data))
        })
        break
      case 'SOURCE':
        getSourceByIDRequest(projectID, project.updatedElement.elementID!).then((res) => {
          dispatch(updateSource(res.data))
        })
        break
      case 'COMPRESSOR_STATIONS':
        getCompressorStationByIDRequest(projectID, project.updatedElement.elementID!).then((res) => {
          dispatch(updateCompressorStation(res.data))
        })
        break
      case 'REFERENCE_NODES':
        getReferenceNodeByIDRequest(projectID, project.updatedElement.elementID!).then((res) => {
          dispatch(updateReferenceNode(res.data))
        })
        break
      case 'NODES_REDUCTIONS':
        getReductionNodeByIDRequest(projectID, project.updatedElement.elementID!).then((res) => {
          dispatch(updateReductionNode(res.data))
        })
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (project.updatedElement.elementID) {
      let request
      switch (project.updatedElement.type) {
        case 'SINK':
          request = updateSinkByIDRequest(
            projectID,
            project.updatedElement.elementID,
            project.updatedElement.params as definitions['SinkUpdate'],
          )
          break
        case 'SOURCE':
          request = updateSourceByIDRequest(
            projectID,
            project.updatedElement.elementID,
            project.updatedElement.params as definitions['SourceUpdate'],
          )
          break
        case 'SOURCE_PROFILE':
          request = updateSourceProfileByIDRequest(
            projectID,
            project.updatedElement.elementID,
            project.updatedElement.params as definitions['ProfileUpdate'],
          )
          break
        case 'COMPONENT_FLOW':
          request = updateComponentsFlowByIDRequest(
            projectID,
            project.updatedElement.elementID,
            project.updatedElement.params as definitions['ComponentsFlowUpdate'],
          )
          break
        case 'COMPRESSOR_STATIONS':
          request = updateCompressorStationByIDRequest(
            projectID,
            project.updatedElement.elementID,
            project.updatedElement.params as definitions['CompressorStationUpdate'],
          )
          break
        case 'COMPRESSOR_PLANT':
          request = updateCompressorPlantByIDRequest(
            projectID,
            project.updatedElement.elementID,
            project.updatedElement.params as definitions['CompressorPlantUpdate'],
          )
          break
        case 'REFERENCE_NODES':
          request = updateReferenceNodeByIDRequest(
            projectID,
            project.updatedElement.elementID,
            project.updatedElement.params as definitions['ReferenceNodeUpdate'],
          )
          break
        case 'NODES_REDUCTIONS':
          request = updateReductionNodeByIDRequest(
            projectID,
            project.updatedElement.elementID,
            project.updatedElement.params as definitions['ReductionNodeUpdate'],
          )
          break
        case 'PIPE':
          updatePipeByIDRequest(
            projectID,
            project.updatedElement.elementID,
            project.updatedElement.params as definitions['PipeUpdate'],
          )
          break
        case 'PIPELINE':
          updatePipelineByIDRequest(
            projectID,
            project.updatedElement.elementID,
            project.updatedElement.params as definitions['PipelineUpdate'],
          )
          break
        case 'TRUNK_PIPELINE':
          updateTrunkPipelineByID(
            projectID,
            project.updatedElement.elementID,
            project.updatedElement.params as definitions['TrunkPipelineUpdate'],
          )
          break
        default:
          return
      }
      if (Object.keys(project.updatedElement.params!).includes('central_point')) {
        dispatch(setLoadingHeightNodeID(project.updatedElement.elementID))
        request
          ?.then(() => {
            dispatch(setLoadingHeightNodeID(null))
            axios
              .all([getPipelinesByProjectIDRequest(projectID) as any, getNodesByProjectIDRequest(projectID) as any])
              .then(
                axios.spread((pipelines, nodes) => {
                  dispatch(setMapPipelines(pipelines.data))
                  dispatch(
                    setMapNodes({
                      [project.updatedElement.type === 'NODES_REDUCTIONS'
                        ? 'nodes_reduction'
                        : (project.updatedElement.type?.toLowerCase() as string)]:
                        nodes.data[
                          project.updatedElement.type === 'NODES_REDUCTIONS'
                            ? 'nodes_reduction'
                            : (project.updatedElement.type?.toLowerCase() as string)
                        ],
                    } as any),
                  )
                }),
              )
            requestDetailedNodeByType()
            // @ts-ignore
            const req: Promise<AxiosResponse<definitions['DetailedPipeline']>>[] =
              project.detail?.trunk_pipeline.pipelines
                .filter(
                  (item) =>
                    item.start_node.id === project.updatedElement.elementID ||
                    item.end_node.id === project.updatedElement.elementID,
                )
                ?.map((pipeline) => getPipelineByIDRequest(project.detail!.id, pipeline.id))
            axios.all(req).then((resArr) => {
              dispatch(setPipelines(resArr.map((res) => res.data)))
            })
            getProjectByIDRequest(projectID)
              .then((res: any) => dispatch(setProjectDetail(res.data)))
              .catch((err) => {
                if (err.response.status === 404) setProjectNotFoundError(true)
              })
          })
          .catch(() => dispatch(setLoadingHeightNodeID(null)))
      }
      if (Object.keys(project.updatedElement.params!).includes('name')) {
        const ind = (
          project.detail![project.updatedElement.type?.toLowerCase() as keyof definitions['ProjectDetail']] as []
        ).findIndex((item: any) => item.id === project.updatedElement.elementID)
        if (ind > -1) {
          const detailsCopy = structuredClone(project.detail) as definitions['ProjectDetail']
          const element = (
            detailsCopy[project.updatedElement.type?.toLowerCase() as keyof definitions['ProjectDetail']] as []
          )[ind] as definitions['ProjectSink']
          // @ts-ignore
          element.name = project.updatedElement.params!['name']
          detailsCopy.trunk_pipeline.pipelines.forEach((obj) => {
            if (obj.start_node.id === project.updatedElement.elementID) {
              // @ts-ignore
              obj.start_node.name = project.updatedElement.params!['name']
            } else if (obj.end_node.id === project.updatedElement.elementID) {
              // @ts-ignore
              obj.end_node.name = project.updatedElement.params!['name']
            }
          })
          dispatch(setProjectDetail(detailsCopy))
        }
      }
    }
    dispatch(resetUpdatedElement())
  }, [project.updatedElement])
  useEffect(() => {
    if (nodeCreation.create) {
      switch (nodeCreation.editObject) {
        case 'REFERENCE_NODES':
          createReferenceNodeRequest(projectID, {
            central_point: point(nodeCreation.coordinates as number[]).geometry as any,
            on_pipeline: { id: nodeCreation.pipelineID } as definitions['BaseNodeRelatedPipeline'],
          }).then((res) => {
            dispatch(setReferenceNodes(res.data))
            fetchProject(projectID)
            mapMode === 'view' &&
              dispatch(
                setShowElementInfo({
                  isVisible: true,
                  objectType: nodeCreation.editObject,
                  objectId: res.data.id,
                }),
              )
          })
          dispatch(resetMapSubmode())
          dispatch(resetNodeCreation())
          break
        case 'COMPRESSOR_STATIONS':
          createCompressorStationRequest(projectID, {
            central_point: point(nodeCreation.coordinates as number[]).geometry as any,
            on_pipeline: { id: nodeCreation.pipelineID } as definitions['BaseNodeRelatedPipeline'],
          }).then((res) => {
            dispatch(setCompressorStations(res.data))
            fetchProject(projectID)
            mapMode === 'view' &&
              dispatch(
                setShowElementInfo({
                  isVisible: true,
                  objectType: nodeCreation.editObject,
                  objectId: res.data.id,
                }),
              )
          })
          dispatch(resetMapSubmode())
          dispatch(resetNodeCreation())
          break
        case 'NODES_REDUCTIONS':
          createReductionNodeRequest(projectID, {
            central_point: point(nodeCreation.coordinates as number[]).geometry as any,
            on_pipeline: { id: nodeCreation.pipelineID } as definitions['BaseNodeRelatedPipeline'],
          }).then((res) => {
            dispatch(setReductionNodes(res.data))
            fetchProject(projectID)
            dispatch(
              setShowElementInfo({
                isVisible: true,
                objectType: nodeCreation.editObject,
                objectId: res.data.id,
              }),
            )
          })
          dispatch(resetMapSubmode())
          dispatch(resetNodeCreation())
          break
      }
    }
  }, [nodeCreation.create])
  useEffect(() => {
    if (blockingWindow.type === 'DELETE_CONFIRM' && blockingWindow.isConfirm) {
      let request
      switch (blockingWindow.objectType) {
        case 'COMPRESSOR_STATIONS':
          request = deleteCompressorStationByIDRequest(projectID, blockingWindow.objectID as string)
          break
        case 'REFERENCE_NODES':
          request = deleteReferenceNodeByIDRequest(projectID, blockingWindow.objectID as string)
          break
        case 'NODES_REDUCTIONS':
          request = deleteReductionNodeByIDRequest(projectID, blockingWindow.objectID as string)
      }
      request?.then(() => {
        fetchProject(projectID)
        dispatch(resetBlockingWindow())
        dispatch(resetShowElementInfo())
      })
    }
  }, [blockingWindow])
  useEffect(() => {
    if (project.showElementInfo.objectId) setToolTabKey('MANUAL_MODE')
  }, [project.showElementInfo.objectId])
  return projectNotFoundError ? (
    <NotFoundView
      title={'Проект не найден'}
      message={'Запрашиваемый проект удален или не был создан.\nПожалуйста, проверьте корректность ссылки.'}
    />
  ) : (
    <div className={'project-page'}>
      <BlockingWindow />
      <ProjectHeader title={project.detail ? project.detail.name : ''} id={projectID} />
      <div className={'project-page__body'}>
        <Tabs
          tabList={toolbarTabList}
          setActiveTab={setToolTabKey}
          activeTab={toolTabKey}
          containerClassName={'toolbar'}
          tabClassName={'toolbar-btn'}
        />
        {toolTabKey === 'MANUAL_MODE' && <ElementPanel project={project} />}
        {toolTabKey === 'HYDRAULIC_CALC' && <HydraulicCalc />}
        {toolTabKey === 'ECONOMIC_CALC' && <EconomicCalc />}
        {toolTabKey === 'OPTIMIZATION_CALC' && <OptimizationCalc />}
        <Map>
          <ProjectMap />
        </Map>
        <GasTransportScheme />
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => ({
  project: state.project,
  mapMode: state.map.mode,
  nodeCreation: state.map.nodeCreation,
  blockingWindow: state.common.blockingWindow,
})
export default connect(mapStateToProps)(Project)
