import './elementPanel.scss'
import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import PlusIcon from 'images/PlusIcon'
import {
  selectShowElementInfo,
  setShowElementInfo,
  setCompressorStations,
  updateSink,
  updateSource,
  setReferenceNodes,
  setReductionNodes,
  setTrunkPipeline,
  setPipelines,
  addPipeline,
  setGPUChoices,
  setACUChoices,
  setCompressorStationsOrder,
} from 'store/projectSlice'
import { useDispatch, useSelector } from 'react-redux'
import { PanelButton } from './components/panelButton'
import { SinkEditPanel } from './sink/sinkEditPanel'
import { SourceEditPanel } from './source/sourceEditPanel'
import { IProject } from 'store/types'
import {
  getACUChoices,
  getCompressorStationByIDRequest,
  getGPUChoices,
  getPipelineByIDRequest,
  getReductionNodeByIDRequest,
  getReferenceNodeByIDRequest,
  getSinkByIDRequest,
  getSourceByIDRequest,
  getTrunkPipelineByIDRequest,
} from 'services/apiRequests'
import Button from 'components/Button'
import { selectMap, setMapMode, setNodeCreation, selectMapNodes, setMapSubmode } from 'store/mapSlice'
import { ReferenceNodeEditPanel } from './referenceNode/referenceNodeEditPanel'
import { CompressorStationEditPanel } from './csStation/compressorStationEditPanel'
import CompressorStationsEditPanel from './csStations/compressorStationsEditPanel'
import { definitions } from 'generated/apiTypes'
import TrunkPipelineEditPanel from './trunkPipeline/trunkPipelineEditPanel'
import axios, { AxiosResponse } from 'axios'
import PipelineEditPanel from './pipeline/pipelineEditPanel'
import { ReductionNodeEditPanel } from './reductionNode/reductionNodeEditPanel'

interface IElementPanelProps {
  project: IProject
}

export const ElementPanel: React.FC<IElementPanelProps> = ({ project }) => {
  const dispatch = useDispatch()
  const elementInfo = useSelector(selectShowElementInfo)
  const map = useSelector(selectMap)
  const [pipelineProj, setPipelineProj] = useState<definitions['ProjectPipeline'][] | []>([])
  const [referenceNodeProj, setReferenceNodeProj] = useState<definitions['ProjectReferenceNode'][] | []>([])
  const [compressorStationProj, setCompressorStationProj] = useState<definitions['ProjectCompressorStation'][] | []>([])
  const [reductionNodesProj, setReductionNodesProj] = useState<definitions['ProjectNodeReduction'][] | []>([])
  useEffect(() => {
    const objectId = project.showElementInfo.objectId as string
    switch (project.showElementInfo.objectType) {
      case 'SINK':
        !project.sink &&
          getSinkByIDRequest(project.detail!.id, objectId).then((res: any) => dispatch(updateSink(res.data)))
        break
      case 'SOURCE':
        !project.source &&
          getSourceByIDRequest(project.detail!.id, objectId).then((res: any) => dispatch(updateSource(res.data)))
        break
      case 'COMPRESSOR_STATIONS':
        !project.gpuChoices.length && getGPUChoices().then((res) => dispatch(setGPUChoices(res.data.units)))
        !project.acuChoices.length && getACUChoices().then((res) => dispatch(setACUChoices(res.data.units)))
        if (project.showElementInfo.objectId) {
          !project.compressorStations?.map((item: definitions['CompressorStation']) => item.id).includes(objectId) &&
            getCompressorStationByIDRequest(project.detail!.id, objectId).then((res: any) =>
              dispatch(setCompressorStations(res.data)),
            )
        } else {
          project.detail?.compressor_stations?.map((cs) => {
            if (!project.compressorStations?.map((item: any) => item.id).includes(cs.id))
              getCompressorStationByIDRequest(project.detail!.id, cs.id).then((res: any) =>
                dispatch(setCompressorStations(res.data)),
              )
          })
        }
        break
      case 'REFERENCE_NODES':
        !project.referenceNodes?.map((item: definitions['ReferenceNode']) => item.id).includes(objectId) &&
          getReferenceNodeByIDRequest(project.detail!.id, objectId).then((res) => dispatch(setReferenceNodes(res.data)))
        break
      case 'NODES_REDUCTIONS':
        !project.reductionNodes?.map((item: definitions['ReductionNode']) => item.id).includes(objectId) &&
          getReductionNodeByIDRequest(project.detail!.id, objectId).then((res) => dispatch(setReductionNodes(res.data)))
        break
      case 'TRUNK_PIPELINE': {
        !project.trunkPipeline &&
          getTrunkPipelineByIDRequest(project.detail!.id, objectId).then((res) => dispatch(setTrunkPipeline(res.data)))
        const pipelinesInStore = project.pipelines.map((item) => item.id)
        // @ts-ignore
        const requests: Promise<AxiosResponse<definitions['DetailedPipeline']>>[] =
          project.detail?.trunk_pipeline.pipelines
            .filter((pipeline) => !pipelinesInStore.includes(pipeline.id))
            .map((pipeline) => getPipelineByIDRequest(project.detail!.id, pipeline.id))
        axios.all(requests).then((resArr) => {
          dispatch(setPipelines([...project.pipelines, ...resArr.map((res) => res.data)]))
        })
        break
      }
      case 'PIPELINE':
        !project.pipelines.map((item) => item.id).includes(objectId) &&
          getPipelineByIDRequest(project.detail!.id, objectId).then((res) => dispatch(addPipeline(res.data)))
    }
  }, [project.showElementInfo])
  const renderElementEditPanel = () => {
    if (elementInfo.isVisible) {
      switch (elementInfo.objectType) {
        case 'SINK':
          return <SinkEditPanel sink={project.sink} />
        case 'SOURCE':
          return <SourceEditPanel source={project.source} />
        case 'REFERENCE_NODES':
          return (
            <ReferenceNodeEditPanel
              object={project.referenceNodes?.find(
                (item: definitions['ReferenceNode']) => item.id === elementInfo.objectId,
              )}
            />
          )
        case 'COMPRESSOR_STATIONS':
          if (elementInfo.objectId) {
            return (
              <CompressorStationEditPanel
                object={project.compressorStations?.find(
                  (item: definitions['CompressorStation']) => item.id === elementInfo.objectId,
                )}
              />
            )
          } else return <CompressorStationsEditPanel />
        case 'TRUNK_PIPELINE':
          return <TrunkPipelineEditPanel trunkPipeline={project.trunkPipeline} pipelines={project.pipelines} />
        case 'PIPELINE':
          return (
            <PipelineEditPanel pipeline={project.pipelines.find((pipeline) => pipeline.id === elementInfo.objectId)} />
          )
        case 'NODES_REDUCTIONS':
          return (
            <ReductionNodeEditPanel object={project.reductionNodes.find((item) => item.id === elementInfo.objectId)} />
          )
      }
    }
    return <></>
  }
  const switchToEditMode = (type: 'REFERENCE_NODES' | 'COMPRESSOR_STATIONS' | 'NODES_REDUCTIONS') => {
    dispatch(setMapSubmode('creation'))
    dispatch(
      setNodeCreation({
        editObject: type,
      }),
    )
  }
  const renderGroup = (
    title: string,
    emptyText: string,
    elements: any[],
    type: 'REFERENCE_NODES' | 'COMPRESSOR_STATIONS' | 'NODES_REDUCTIONS',
  ) => {
    return (
      <>
        {' '}
        <div className={'elements-panel__group'} onClick={() => switchToEditMode(type)}>
          {title}
          <Button className={map.nodeCreation.editObject === type ? 'selected' : ''} mode={'secondary'}>
            <PlusIcon />
          </Button>
        </div>
        {elements.length ? (
          <>
            {type === 'COMPRESSOR_STATIONS' && (
              <PanelButton
                value={<strong>Общие свойства</strong>}
                className={elementInfo.objectType === 'COMPRESSOR_STATIONS' && !elementInfo.objectId ? 'selected' : ''}
                onClick={() =>
                  dispatch(
                    setShowElementInfo({
                      isVisible: true,
                      objectId: null,
                      objectType: 'COMPRESSOR_STATIONS',
                      objectName: '',
                    }),
                  )
                }
              />
            )}
            {elements.map((elem) => (
              <PanelButton
                key={elem.id}
                value={elem.name}
                isWarning={
                  ((
                    map!.nodes?.[
                      type === 'NODES_REDUCTIONS'
                        ? 'nodes_reduction'
                        : (type.toLowerCase() as keyof definitions['ProjectNodes'])
                    ] as definitions['Node'][]
                  )?.find((item) => item.node_id === elem.id)?.gis_height_error.length || 0) > 0
                }
                className={elem.id === elementInfo.objectId && elementInfo.isVisible ? 'selected' : ''}
                onClick={() =>
                  dispatch(
                    setShowElementInfo({
                      isVisible: true,
                      objectId: elem.id as string,
                      objectType: type as any,
                      objectName: elem.name,
                    }),
                  )
                }
              />
            ))}
          </>
        ) : (
          <div className={'elements-panel__text'}>{emptyText}</div>
        )}
      </>
    )
  }

  useEffect(() => {
    if (project.detail && project.detail.trunk_pipeline.pipelines.length > 0) {
      const cs = []
      const rn = []
      const pipe = []
      const redNodes = []
      const visited: string[] = [project.detail.source.id]
      let currentNode = project.detail.source.id
      while (currentNode) {
        const csIndex = project.detail.compressor_stations.findIndex((obj) => obj.id === currentNode)
        if (csIndex > -1) {
          cs.push(project.detail.compressor_stations[csIndex])
        } else {
          const refNodeIndex = project.detail.reference_nodes.findIndex((obj) => obj.id === currentNode)
          if (refNodeIndex > -1) {
            rn.push(project.detail.reference_nodes[refNodeIndex])
          } else {
            const redNodeIndex = project.detail.nodes_reductions.findIndex((obj) => obj.id === currentNode)
            redNodeIndex > -1 && redNodes.push(project.detail.nodes_reductions[redNodeIndex])
          }
        }
        const pipeline = project.detail.trunk_pipeline.pipelines.find(
          (obj) =>
            (obj.start_node.id === currentNode && !visited.includes(obj.end_node.id)) ||
            (obj.end_node.id === currentNode && !visited.includes(obj.start_node.id)),
        )
        if (pipeline) {
          pipe.push(pipeline)
          visited.push(currentNode)
          currentNode = pipeline.start_node.id === currentNode ? pipeline.end_node.id : pipeline.start_node.id
        } else break
      }
      setCompressorStationProj(cs)
      dispatch(setCompressorStationsOrder(cs))
      setReferenceNodeProj(rn)
      setPipelineProj(pipe)
      setReductionNodesProj(redNodes)
    } else {
      setCompressorStationProj([])
      setReferenceNodeProj([])
      setPipelineProj([])
      setReductionNodesProj([])
    }
  }, [project.detail])

  const renderTrunkPipelineGroup = (trunk_pipeline?: definitions['ProjectTrunkPipeline']) => {
    return (
      <>
        <div className={'elements-panel__group'}>
          <div className={'elements-panel__group__title'}>
            <div>магистральный трубопровод</div>
          </div>
        </div>
        {trunk_pipeline && (
          <>
            <PanelButton
              value={<strong>Общие свойства</strong>}
              className={elementInfo.objectType === 'TRUNK_PIPELINE' ? 'selected' : ''}
              onClick={() =>
                dispatch(
                  setShowElementInfo({
                    isVisible: true,
                    objectId: trunk_pipeline?.id as string,
                    objectType: 'TRUNK_PIPELINE',
                    objectName: '',
                  }),
                )
              }
            />
            {pipelineProj.map((pipeline) => (
              <PanelButton
                key={pipeline.id}
                value={`${pipeline.start_node.name} - ${pipeline.end_node.name}`}
                className={elementInfo.objectId === pipeline.id && elementInfo.isVisible ? 'selected' : ''}
                onClick={() =>
                  dispatch(
                    setShowElementInfo({
                      isVisible: true,
                      objectId: pipeline.id,
                      objectType: 'PIPELINE',
                      objectName: `${pipeline.start_node.name} - ${pipeline.end_node.name}`,
                    }),
                  )
                }
              />
            ))}
          </>
        )}
      </>
    )
  }
  return project ? (
    <>
      <div className={'elements-panel'}>
        {project.detail && map.nodes?.source && (
          <PanelButton
            key={map.nodes.source.node_id}
            className={map.nodes?.source.node_id === elementInfo.objectId && elementInfo.isVisible ? 'selected' : ''}
            value={'Начальная точка трубопровода'}
            isWarning={map!.nodes?.source.gis_height_error.length > 0}
            onClick={() =>
              dispatch(
                setShowElementInfo({
                  isVisible: true,
                  objectId: map.nodes?.source.node_id as string,
                  objectType: 'SOURCE',
                  objectName: project.detail!.source.name,
                }),
              )
            }
          />
        )}
        {project.detail && map.nodes?.sink && (
          <PanelButton
            key={map!.nodes?.sink.node_id}
            className={classnames(
              map!.nodes?.sink.node_id === elementInfo?.objectId && elementInfo.isVisible && 'selected',
            )}
            value={'Конечная точка трубопровода'}
            isWarning={map!.nodes?.sink.gis_height_error.length > 0}
            onClick={() =>
              dispatch(
                setShowElementInfo({
                  isVisible: true,
                  objectId: map!.nodes?.sink.node_id as string,
                  objectType: 'SINK',
                  objectName: project.detail!.sink?.name,
                }),
              )
            }
          />
        )}
        {renderGroup(
          'компр. станции',
          'Не добавлена ни одна компр. станция',
          compressorStationProj,
          'COMPRESSOR_STATIONS',
        )}

        {renderGroup('опорные узлы', 'Не добавлен ни один опорный узел', referenceNodeProj, 'REFERENCE_NODES')}
        {renderGroup(
          'узлы редуцирования газа',
          'Не добавлен ни один узел редуцирования газа',
          reductionNodesProj,
          'NODES_REDUCTIONS',
        )}
        {renderTrunkPipelineGroup(project?.detail?.trunk_pipeline)}
      </div>
      {renderElementEditPanel()}
    </>
  ) : (
    <></>
  )
}
