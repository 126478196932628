import { IMap, INodeCreation } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configureStore'
import { definitions } from '../generated/apiTypes'

export const initialState: IMap = {
  mode: 'view',
  submode: 'base',
  layers: undefined,
  zoom: {
    in: false,
    out: false,
  },
  toCenter: false,
  nodeCreation: {
    editObject: null,
    create: false,
  },
  nodes: undefined,
  pipelines: [],
  selectedGroup: [],
}
export const mapSlice = createSlice({
  name: 'mapSlice',
  initialState,
  reducers: {
    setMapNodes: (state, action: PayloadAction<definitions['ProjectNodes']>) => {
      state.nodes = { ...state.nodes, ...action.payload }
    },
    setMapPipelines: (state, action: PayloadAction<IMap['pipelines']>) => {
      state.pipelines = action.payload
    },
    setMapMode: (state, action: PayloadAction<IMap['mode']>) => {
      state.mode = action.payload
    },
    setMapSubmode: (state, action: PayloadAction<IMap['submode']>) => {
      state.submode = action.payload
    },
    resetMapMode: (state) => {
      state.mode = initialState.mode
    },
    resetMapSubmode: (state) => {
      state.submode = initialState.submode
    },
    setNodeCreation: (state, action: PayloadAction<INodeCreation>) => {
      state.nodeCreation = {
        ...state.nodeCreation,
        ...action.payload,
      }
    },
    resetNodeCreation: (state) => {
      state.nodeCreation = initialState.nodeCreation
    },
    resetMap: () => {
      return initialState
    },
    setMapZoom: (state, action: PayloadAction<IMap['zoom']>) => {
      state.zoom = { ...state.zoom, ...action.payload }
    },
    setToMapCenter: (state, action: PayloadAction<IMap['toCenter']>) => {
      state.toCenter = action.payload
    },
    resetMapZoom: (state) => {
      state.zoom = initialState.zoom
    },
    setMapLayers: (state, action: PayloadAction<IMap['layers']>) => {
      state.layers = action.payload
    },
    setSelectedGroup: (state, action: PayloadAction<IMap['selectedGroup']>) => {
      state.selectedGroup = action.payload
    },
  },
})

export const {
  setMapNodes,
  setMapPipelines,
  setMapMode,
  resetMapMode,
  setMapSubmode,
  resetMapSubmode,
  setNodeCreation,
  resetNodeCreation,
  resetMap,
  setToMapCenter,
  setMapZoom,
  resetMapZoom,
  setMapLayers,
  setSelectedGroup,
} = mapSlice.actions

export const selectMap = (state: RootState) => state.map
export const selectMapMode = (state: RootState) => state.map.mode
export const selectMapSubmode = (state: RootState) => state.map.submode
export const selectNodeCreation = (state: RootState) => state.map.nodeCreation
export const selectMapZoom = (state: RootState) => state.map.zoom
export const selectMapLayers = (state: RootState) => state.map.layers
export const selectSelectedGroup = (state: RootState) => state.map.selectedGroup
export const selectMapNodes = (state: RootState) => state.map.nodes
export default mapSlice.reducer
