import React, { useEffect } from 'react'
import Input from '../Input'
import './style.scss'
import useGetValidationMessage from '../hooks/useGetValidationMessage'

interface INumberInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string
  classNames?: string
  setValue?(value: number, label?: string): void
  label?: any
  fieldName?: string
  unit?: string | JSX.Element
  signed?: boolean
  placeholder?: string
  noEmpty?: boolean
  infoText?: string | JSX.Element
  warningText?: string | JSX.Element
  minValue?: number
  maxValue?: number
  accuracy?: number
  loading?: boolean
}

const NumberInput: React.FC<INumberInputProps> = ({
  id,
  value,
  classNames,
  label,
  disabled,
  setValue,
  fieldName,
  unit,
  signed,
  placeholder = '—',
  noEmpty = false,
  minValue = -Infinity,
  maxValue = Infinity,
  infoText,
  warningText,
  accuracy = 6,
  loading,
  ...props
}) => {
  const [valueState, setValueState] = React.useState<string>('')
  const valueRef = React.useRef<any>()
  const timerId = React.useRef<ReturnType<typeof setTimeout>>()

  const errorText = useGetValidationMessage(id, fieldName)

  React.useEffect(() => {
    value === undefined || value === null || isNaN(value as number)
      ? setValueState('')
      : setValueState(value.toString())
  }, [value])

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const pattern = new RegExp(`-?\\d*\\.?\\d{0,${accuracy}}`)
    if (signed) {
      setValueState(
        e.target.value
          .replace(/,/, '.')
          .replace(/[^-?0-9.]/g, '')
          .replace(/(\..*)\./g, '$1')
          .match(pattern)![0] || '',
      )
    } else
      setValueState(
        e.target.value
          .replace(/,/, '.')
          .replace(/[^0-9.]/g, '')
          .replace(/(\..*)\./g, '$1')
          .match(pattern)![0] || '',
      )
    timerId.current && clearInterval(timerId.current)
    timerId.current = setInterval(async () => {
      updateValue()
    }, 2000)
  }

  const updateValue = () => {
    onBlurHandler()
    timerId.current && clearInterval(timerId.current)
  }
  useEffect(() => {
    valueRef.current = valueState
  }, [valueState])

  const onBlurHandler = () => {
    if (!valueRef.current?.length) {
      if (noEmpty) {
        if (value) setValueState(value.toString())
        else setValueState('0')
      } else if (!isNaN(value as number) && setValue) {
        setValue(NaN, fieldName)
      }
    } else {
      const newValue = Math.max(minValue, Math.min(maxValue, parseFloat(valueRef.current!)))
      if (setValue && newValue !== value) {
        setValue(newValue, fieldName)
      } else if (value !== undefined && value !== null) {
        setValueState(value.toString())
      }
    }
  }
  return (
    <Input
      {...props}
      label={label}
      unit={unit}
      size={valueState.toString().length | 1}
      value={valueState}
      onChange={onChangeHandler}
      classNames={classNames}
      disabled={disabled}
      onEnter={updateValue}
      placeholder={placeholder}
      infoText={infoText}
      warningText={warningText}
      errorText={errorText}
      loading={loading}
    />
  )
}

export default NumberInput
