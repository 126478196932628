import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getEconomicTaskStatus } from 'services/apiRequests'
import {
  resetEconomicSliceState,
  selectTaskId,
  selectCreateTask,
  selectTaskStatus,
  setTaskCalculationProgress,
  setTaskStatus,
  setCreateTask,
  setErrorMessage,
  setTaskId,
} from 'store/economicSlice'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import {
  resetBlockingWindow,
  selectBlockingWindow,
  selectTaskIsCanceling,
  setBlockingWindow,
  setTaskIsCanceling,
} from 'store/commonSlice'
import { definitions } from 'generated/apiTypes'

const useGetEconomicTaskStatus = () => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const taskId = useSelector(selectTaskId)
  const createTask = useSelector(selectCreateTask)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const blockingWindow = useSelector(selectBlockingWindow)
  const taskIsCanceling = useSelector(selectTaskIsCanceling)
  const [taskAuthor, setTaskAuthor] = React.useState<definitions['TaskStatus']['author']>()
  const timerId = React.useRef<ReturnType<typeof setTimeout>>()

  const tryToGetTaskStatus = async () => {
    if (trunkPipelineId && projectId)
      await getEconomicTaskStatus(projectId, trunkPipelineId)
        .then((response) => {
          if (!taskAuthor) setTaskAuthor(response.data.author)
          if (!taskId) dispatch(setTaskId(response.data.id))
          dispatch(setTaskCalculationProgress(response.data.progress))
          dispatch(setTaskStatus(response.data.status))
          dispatch(setErrorMessage(response.data.error_message))
        })
        .catch(() => {
          if (timerId.current) clearInterval(timerId.current)
          dispatch(
            setBlockingWindow({
              objectName: 'Непредвиденная ошибка',
              message: 'Непредвиденная ошибка',
              type: 'ERROR',
            }),
          )
        })
  }

  React.useEffect(() => {
    if (taskStatus) {
      if (['Рассчитан', 'Ошибка', 'Рассчитано частично', 'Отменен'].includes(taskStatus)) {
        dispatch(resetBlockingWindow())
        dispatch(setCreateTask(false))
        dispatch(setTaskIsCanceling(false))
        if (timerId.current) {
          clearInterval(timerId.current)
          timerId.current = undefined
        }
      } else if (!timerId.current) {
        if (['Запущен', 'Ожидает очереди'].includes(taskStatus)) {
          tryToGetTaskStatus().then()
          dispatch(
            setBlockingWindow({ type: 'ECONOMIC_PROGRESS', objectID: taskId, objectName: taskAuthor?.id?.toString() }),
          )
        }
        timerId.current = setInterval(async () => {
          tryToGetTaskStatus().then()
        }, 5000)
      }
    }
  }, [taskStatus])

  React.useEffect(() => {
    if (taskIsCanceling && trunkPipelineId && createTask) {
      tryToGetTaskStatus().then()
      if (blockingWindow?.type) dispatch(resetBlockingWindow())
    }
  }, [taskIsCanceling])

  React.useEffect(() => {
    if (taskAuthor?.id) {
      dispatch(
        setBlockingWindow({
          objectName: taskAuthor?.id?.toString(),
        }),
      )
    }
  }, [taskAuthor])

  React.useEffect(() => {
    if (trunkPipelineId) {
      tryToGetTaskStatus().then()
    }
    return () => {
      if (timerId.current) {
        clearInterval(timerId.current)
        dispatch(resetEconomicSliceState())
      }
    }
  }, [trunkPipelineId])

  React.useEffect(() => {
    if (!blockingWindow.type && taskStatus && !taskIsCanceling) {
      if (['Запущен', 'Ожидает очереди'].includes(taskStatus)) {
        dispatch(
          setBlockingWindow({ type: 'ECONOMIC_PROGRESS', objectID: taskId, objectName: taskAuthor?.id?.toString() }),
        )
      }
    }
  }, [blockingWindow.type])

  return
}

export default useGetEconomicTaskStatus
