import { useSelector } from 'react-redux'
import { selectTaskResults } from 'store/hydraulicSlice'
import { definitions } from 'generated/apiTypes'
import { twoDigitsFormatter } from 'pages/utils'
import { Table } from 'components/Table/Table/Table'
import React from 'react'
import { ITableColumn } from 'components/Table/types'

import './pipeline.scss'
import { CSTable } from './csTable'
import { ReductionNodeTable } from './reductionNodeTable'

const columns: ITableColumn[] = [
  {
    title: (
      <>
        Начало <br /> участка, км
      </>
    ),
    type: 'Text',
    key: 'start_point',
    editable: false,
  },
  {
    title: (
      <>
        Конец участка,
        <br /> км
      </>
    ),
    type: 'Text',
    key: 'end_point',
    editable: false,
  },
  {
    title: (
      <>
        Длина участка,
        <br /> км
      </>
    ),
    type: 'Text',
    key: 'length',
    editable: false,
  },
  {
    title: (
      <>
        Кол-во <br /> ниток
      </>
    ),
    type: 'Number',
    key: 'n_pipes',
    editable: false,
  },
  {
    title: (
      <>
        Объем транспортного <br />
        газа, млн.м3/сут
      </>
    ),
    type: 'Text',
    key: 'gas_transportation_amount',
    editable: false,
  },
  {
    title: (
      <>
        Приток/отбор газа,
        <br /> млн.м3/сут
      </>
    ),
    type: 'Text',
    key: 'gas_inflow',
    editable: false,
  },
  {
    title: (
      <>
        Температура газа в<br /> начале участка, К
      </>
    ),
    type: 'Text',
    key: 'start_point_temperature',
    editable: false,
  },
  {
    title: (
      <>
        Температура газа в<br /> конце участка, К
      </>
    ),
    type: 'Text',
    key: 'finish_point_temperature',
    editable: false,
  },
  {
    title: (
      <>
        Давление в начале
        <br /> участка, МПа
      </>
    ),
    type: 'Text',
    key: 'start_point_pressure',
    editable: false,
  },
  {
    title: (
      <>
        Давление газа в<br /> конце участка, МПа
      </>
    ),
    type: 'Text',
    key: 'finish_point_pressure',
    editable: false,
  },
  {
    title: (
      <>
        Скорость движения
        <br /> газа в трубе, м/сек
      </>
    ),
    type: 'Text',
    key: 'gas_velocity',
    editable: false,
  },
]
export const Pipeline: React.FC = () => {
  const taskResults = useSelector(selectTaskResults)
  const getDataSource = (pipelineList: definitions['HydraulicResultRetrievePipeline'][]) => {
    const data: any = []
    pipelineList.forEach((pipeline) => {
      data.push({
        id: pipeline.id,
        start_point: twoDigitsFormatter.format(pipeline.start_point__km),
        end_point: twoDigitsFormatter.format(pipeline.end_point__km),
        length: twoDigitsFormatter.format(pipeline.length__km),
        n_pipes: pipeline.n_pipes,
        gas_transportation_amount: twoDigitsFormatter.format(pipeline.gas_transportation_amount__Mm3_per_day),
        gas_inflow: twoDigitsFormatter.format(pipeline.gas_inflow__Mm3_per_day),
        start_point_temperature: twoDigitsFormatter.format(pipeline.start_point_temperature__K),
        finish_point_temperature: twoDigitsFormatter.format(pipeline.finish_point_temperature__k),
        start_point_pressure: twoDigitsFormatter.format(pipeline.start_point_pressure__MPa),
        finish_point_pressure: twoDigitsFormatter.format(pipeline.finish_point_pressure__MPa),
        gas_velocity: twoDigitsFormatter.format(pipeline.gas_velocity__m_per_s),
      })
      if (pipeline.end_node && pipeline.end_node.node_type === 'КС') {
        data.push({
          id: pipeline.end_node.id,
          expandable: true,
        })
      }
      if (pipeline.end_node && pipeline.end_node.node_type === 'Узел редуцирования') {
        data.push({
          id: pipeline.end_node.id,
          expandable: true,
        })
      }
    })
    return data
  }
  const renderExpandRowData = (id: string) => {
    const csResult = taskResults.compressorStations.find(
      (cs) => cs.id === id,
    ) as definitions['HydroResultRetrieveCompressorStation']
    if (csResult) return <CSTable cs={csResult} />
    const reductionsNodeResult = taskResults.reductionNodes.find(
      (node) => node.id === id,
    ) as definitions['HydroResultRetrieveReductionNode']
    if (reductionsNodeResult) return <ReductionNodeTable node={reductionsNodeResult} />
    return <></>
  }
  const renderExpandCellContent = (id: string) => {
    const station = taskResults.compressorStations.find((obj) => obj.id === id)
    if (station) return <div className={'expand-cell__title header4'}>{`компрессорная станция (${station?.name})`}</div>
    const reductionNode = taskResults.reductionNodes.find((obj) => obj.id === id)
    if (reductionNode)
      return <div className={'expand-cell__title header4'}>{`узел редуцирования газа (${reductionNode?.name})`}</div>
    return <div />
  }
  return (
    <Table
      className={'pipeline-result result-table'}
      columns={columns}
      dataSource={getDataSource(taskResults.pipelines)}
      expandRow={renderExpandRowData}
      expandCellContent={renderExpandCellContent}
    />
  )
}
