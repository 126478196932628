import React from 'react'

import {
  createBrowserRouter,
  createHashRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom'
import MainPage from './pages/MainPage/mainPage'
import Project from './pages/Project/project'
import Directory from './pages/Directory/directory'
import Login from './pages/Login/login'
import './fonts.scss'
import './index.css'
import { setBaseURL } from './api'
import OAuth from './pages/OAuth/oauth'
import NotFoundView from './components/NotFoundView/notFoundView'

const App: React.FC = () => {
  setBaseURL()
  let href = window.location.href
  href = href.replace(/\/login/, '')
  href = href.replace(/\?.*/, '')
  document.cookie = `original_url=${encodeURIComponent(href)};domain=.numdes.com;path=/`

  const baseRouterConfig = createRoutesFromElements(
    <React.Fragment>
      <Route path='/' element={<MainPage />} />
      <Route path='/project/:id' element={<Project />} />
      <Route path='/directory/:id' element={<Directory />} />
      <Route path='/login' element={<Login />} />
      <Route path='/login/complete' element={<OAuth />} />
      <Route
        path='*'
        element={
          <NotFoundView
            title={'Страница не найдена'}
            message={'Запрашиваемая страница отсутствует в системе.\nПожалуйста, проверьте корректность ссылки.'}
          />
        }
      />
    </React.Fragment>,
  )

  const router = window?.env?.BASENAME
    ? createBrowserRouter(baseRouterConfig, { basename: window?.env?.BASENAME })
    : createHashRouter(baseRouterConfig)

  return <RouterProvider router={router} />
}

export default App
