import React, { useEffect } from 'react'
import { handleStorage } from 'services/handleStorage'
import { useNavigate } from 'react-router-dom'

const OAuth: React.FC = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const params = new URLSearchParams(location.search.replace('?', ''))
    const token = params.get('token')
    if (token) {
      handleStorage.setToken(token)
      navigate('/')
    }
  }, [])
  return <></>
}

export default OAuth
