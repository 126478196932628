import { useDispatch, useSelector } from 'react-redux'
import { selectDownloadResults, setDownloadResults } from 'store/economicSlice'
import { getEconomicResultExcel } from 'services/apiRequests'
import React from 'react'
import { selectProjectID, selectTrunkPipelineID } from '../../../../../store/projectSlice'

const useGetResults = () => {
  const dispatch = useDispatch()
  const downloadResults = useSelector(selectDownloadResults)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)

  const tryToGetResults = async () => {
    if (trunkPipelineId && projectId)
      await getEconomicResultExcel(projectId, trunkPipelineId).then((response) => {
        const myURL = (window as any)?.URL || (window as any)?.webkitURL
        const url = myURL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'economics-results.xlsx')
        document.body.appendChild(link)
        link.click()
      })
  }

  React.useEffect(() => {
    if (downloadResults)
      tryToGetResults().then(() => {
        dispatch(setDownloadResults(false))
      })
  }, [downloadResults])

  return
}

export default useGetResults
