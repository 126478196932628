import React from 'react'

import { createRegionCoefficientsHandbookVersion } from 'services/apiRequests'

import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItems, setDirectoryItems, setId, setVersionId } from 'store/directorySlice'
import { definitions } from 'generated/apiTypes'
import { setBlockingWindow } from 'store/commonSlice'
import { HandbookRegionCoefficientsType } from '../../../../../store/types'

const useCreateRegionCoefficientsVersion = (saveData: boolean | undefined): boolean => {
  const dispatch = useDispatch()
  const directoryItems = useSelector(selectDirectoryItems) as HandbookRegionCoefficientsType[]
  const [result, setResult] = React.useState(false)

  const tryToCreateRegionCoefficientsVersion = async () => {
    if (directoryItems) {
      const items: definitions['RegionCoefficientsCreate'][] = []
      ;(directoryItems as HandbookRegionCoefficientsType[]).forEach((item) => {
        const { id, created, modified, ...newItem } = item
        if (created) {
          items.push({ is_modified: false, ...newItem })
        } else {
          items.push({ id, ...newItem, is_modified: modified })
        }
      })
      await createRegionCoefficientsHandbookVersion(items)
        .then((res) => {
          dispatch(setId(res.data.id))
          dispatch(setVersionId(res.data.id))
          dispatch(
            setDirectoryItems(
              res.data.regions_coefficients.map((unit) => {
                return {
                  ...unit,
                  modified: false,
                }
              }),
            ),
          )
          setResult(true)
        })
        .catch((err) => {
          if (err.response.status === 400 && err.response.data.some((item: object) => Object.keys(item).length > 0)) {
            dispatch(
              setBlockingWindow({
                objectName: 'Ошибка сохранения',
                message: (
                  <div className='error-window__errors-list-container'>
                    {err.response.data.map((directoryItem: any, index: number) => {
                      if (Object.keys(directoryItem).length > 0) {
                        return (
                          <div key={index}>
                            {`При сохранении ${directoryItems[index]?.name} возникли следующие ошибки:`}
                            <ul className='error-window__errors-list' key={index}>
                              {Object.entries(directoryItem).map(([key, value], ix) => {
                                return <li key={ix}>{`${key}: ${value}`}</li>
                              })}
                            </ul>
                          </div>
                        )
                      }
                    })}
                  </div>
                ),
                type: 'ERROR',
              }),
            )
          } else
            dispatch(
              setBlockingWindow({
                objectName: 'Непредвиденная ошибка',
                message: 'Непредвиденная ошибка',
                type: 'ERROR',
              }),
            )
        })
    }
  }

  React.useEffect(() => {
    if (saveData !== undefined) {
      setResult(false)
      tryToCreateRegionCoefficientsVersion().then()
    }
  }, [saveData])

  return result
}

export default useCreateRegionCoefficientsVersion
