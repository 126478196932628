import React, { PropsWithChildren } from 'react'
import { DirectoryHeader } from 'components/Header/directoryHeader'
import Button from 'components/Button'
import PlusIcon from 'images/PlusIcon'
import DeleteIcon from 'images/DeleteIcon'
import {
  addDirectoryItem,
  resetDirectorySliceState,
  selectDirectoryItems,
  selectSelectedItems,
} from 'store/directorySlice'
import { useDispatch, useSelector } from 'react-redux'
import { setBlockingWindow } from 'store/commonSlice'
import useDeleteItems from '../hooks/useDeleteItems'
import useDiscardChanges from '../hooks/useDiscardChanges'
import { definitions } from 'generated/apiTypes'

interface IDirectoryWrapperProps {
  editMode: boolean
  setEditMode(editMode: boolean): void
  onDiscardChanges(): void
  onSaveChanges(): void
  title: string
  newItemToAdd?:
    | definitions['HandbookGPU']
    | definitions['HandbookACU']
    | definitions['HandbookPipeType']
    | definitions['HandbookAreaType']
    | definitions['HandbookNextgisAreaType']
  disableAdding?: boolean
  isModified?: boolean
  setIsModified?(value: boolean): void
}

const DirectoryWrapper: React.FC<PropsWithChildren<IDirectoryWrapperProps>> = ({
  editMode,
  setEditMode,
  onDiscardChanges,
  onSaveChanges,
  title,
  newItemToAdd,
  disableAdding,
  isModified,
  setIsModified,
  children,
}) => {
  const dispatch = useDispatch()
  const selectedItems = useSelector(selectSelectedItems)
  const directoryItems = useSelector(selectDirectoryItems)

  const onDeleteItems = () => {
    if (setIsModified) setIsModified(true)
  }

  useDeleteItems({ onDeleteItems })
  useDiscardChanges({ onDiscardChanges })

  React.useEffect(() => {
    return () => {
      dispatch(resetDirectorySliceState())
    }
  }, [])

  const onRemoveItems = () => {
    dispatch(
      setBlockingWindow({
        objectType: 'HANDBOOK_ITEMS',
        type: 'DELETE_CONFIRM',
      }),
    )
  }

  const discardChanges = () => {
    if (isModified) {
      dispatch(
        setBlockingWindow({
          type: 'DISCARD_CHANGES',
          objectName: 'EDIT_EXIT',
        }),
      )
    } else setEditMode(false)
  }

  const pageLeave = (): boolean => {
    if (isModified) {
      dispatch(
        setBlockingWindow({
          type: 'DISCARD_CHANGES',
          objectName: 'PAGE_LEAVE',
        }),
      )
    } else return true
    return false
  }

  const addItem = () => {
    if (newItemToAdd) {
      dispatch(addDirectoryItem(newItemToAdd))
      if (setIsModified) setIsModified(true)
    }
  }

  const saveChanges = () => {
    if (
      Array.isArray(directoryItems) &&
      directoryItems?.some((item) =>
        Object.entries(item).some(([_key, value]) => {
          return (typeof value === 'number' && isNaN(value)) || (typeof value === 'string' && value === '')
        }),
      )
    ) {
      dispatch(
        setBlockingWindow({
          type: 'ERROR',
          objectName: 'Ошибка сохранения',
          message: 'Все поля справочника должны быть заполнены',
        }),
      )
    } else onSaveChanges()
  }

  return (
    <>
      <DirectoryHeader
        title={title}
        editMode={editMode}
        setEditMode={setEditMode}
        discardChanges={discardChanges}
        saveChanges={saveChanges}
        pageLeave={pageLeave}
      />
      {children}
      {editMode && newItemToAdd && (
        <div className={'directory__footer'}>
          <Button className={'with-icon directory__footer-add-btn'} onClick={addItem} disabled={disableAdding}>
            <PlusIcon />
            Добавить строку
          </Button>
          <div className={'directory__footer-delete-container'}>
            <div>
              <span className={'normal-text directory__footer-delete-text'}>Выбрано:</span>
              <span className={'normal-text directory__footer-delete-number'}>{` ${selectedItems?.length || 0}`}</span>
            </div>
            <Button
              className={'with-icon directory__footer-delete-btn'}
              mode={'secondary'}
              onClick={onRemoveItems}
              disabled={selectedItems?.length < 1}
            >
              <DeleteIcon />
              Удалить выбранное
            </Button>
          </div>
        </div>
      )}
    </>
  )
}

export default DirectoryWrapper
