import { getCoords, Position, rhumbBearing } from '@turf/turf'
import { GeoJSONSource } from 'maplibre-gl'
import { definitions } from 'generated/apiTypes'
import { AnyLayer } from './types'
import { FeatureCollection } from 'geojson'
import { v4 as uuid4 } from 'uuid'
import { MapElementType } from '../../store/types'

export const PointsMinZoom = 10
export const layerProps = {
  // 'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.1, 18, 1],
  'icon-ignore-placement': true,
  'icon-allow-overlap': true,
  'icon-rotate': ['get', 'rotation'],
}
export const lineLayerProps = {
  'line-color': '#0074BC',
  'line-width': 5,
}
export const lineBaseLayerProps = {
  'line-color': 'transparent',
  'line-width': 30,
}
export const lineLayoutProps = {
  'line-join': 'round',
}
export const lineHoverLayerProps = {
  'line-color': 'rgba(0, 116, 188, 0.3)',
  'line-width': 17,
}
export const lineTransparentLayerProps = {
  'line-color': 'transparent',
  'line-width': 17,
}
export const lineSelectedLayerProps = {
  'line-color': 'rgba(0, 116, 188, 0.5)',
  'line-width': 17,
}
export const circleLayerProps = {
  'circle-radius': 5,
  'circle-color': '#0074BC',
}
export const circleTransparentLayerProps = {
  'circle-radius': 5,
  'circle-color': 'transparent',
}
export const circleHoverLayerProps = {
  'circle-radius': 5,
  'circle-color': '#0074BC',
  'circle-stroke-width': 5,
  'circle-stroke-color': '#0074BC',
  'circle-stroke-opacity': 0.5,
}
export const circleWarnLayerProps = {
  'circle-radius': 5,
  'circle-color': '#0074BC',
  'circle-stroke-width': 5,
  'circle-stroke-color': '#FF3030',
  'circle-stroke-opacity': 0.3,
}
export const selectAreaLayerProps = {
  'fill-color': 'rgba(255, 51, 198, 0.12)',
  'fill-outline-color': '#FF33C6',
}
const normalizeVector = (vector: Position[]) => {
  const deltaX = vector[1][0] - vector[0][0]
  const deltaY = vector[1][1] - vector[0][1]
  const invLength = 1 / Math.sqrt(deltaX ** 2 + deltaY ** 2)
  return [deltaX * invLength, deltaY * invLength]
}
const calcCrossProduct = (vectorA: [number, number], vectorB: [number, number]) => {
  return vectorA[0] * vectorB[1] - vectorA[1] * vectorB[0]
}
const calcDotProduct = (vectorA: [number, number], vectorB: [number, number]) => {
  return vectorA[0] * vectorB[0] + vectorA[1] * vectorB[1]
}

const isPointOnLine = (line: Position[], point: Position, epsilon: number) => {
  const vectorA = [line[0], point]
  const vectorB = [point, line[1]]
  const normalA = normalizeVector(vectorA as [number, number][])
  const normalB = normalizeVector(vectorB as [number, number][])
  return 1 - calcDotProduct(normalA as [number, number], normalB as [number, number]) <= epsilon
}
export const calcAngleBetweenVectors = (vectorA: Position[], vectorB: Position[]) => {
  const ab = normalizeVector(vectorA) as [number, number]
  const cb = normalizeVector(vectorB) as [number, number]
  return Math.atan(calcCrossProduct(cb, ab) / calcDotProduct(cb, ab))
}
export const calcLength = (line: Position[]) => {
  return Math.sqrt((line[0][0] - line[1][0]) ** 2 + (line[0][1] - line[1][1]) ** 2)
}
export const calcLineAngle = (line: Position[]) => {
  const dx = line[1][0] - line[0][0]
  const dy = line[1][1] - line[0][1]
  return Math.PI / 2 - Math.atan2(dx, dy)
}
export const pointOnLine = (line: Position[], pt: Position): Position => {
  let point = pt
  let distance = Infinity
  line.reduce((prev, cur) => {
    const segment = [prev, cur]
    const lineAngle = Math.PI - calcLineAngle(segment)
    const lineLength = calcLength([segment[0], pt])
    const angle = calcAngleBetweenVectors(segment, [segment[0], pt])
    const dx = lineLength * Math.sin(angle) * Math.sin(lineAngle)
    const dy = lineLength * Math.sin(angle) * Math.cos(lineAngle)
    const _pt = [pt[0] - dx, pt[1] - dy]
    if (isPointOnLine(segment, _pt, 10 ** -5) && calcLength([pt, _pt]) < distance) {
      distance = calcLength([pt, _pt])
      point = _pt
    }
    return cur
  })
  return point
}
export const calcRotation = (isStart = true, coordinates: any) => {
  return isStart ? rhumbBearing(coordinates[0], coordinates[1]) : rhumbBearing(coordinates[1], coordinates[0])
}
export const getRotation = (node: definitions['Node'], edgesList: definitions['Pipeline'][]) => {
  const edge = edgesList.find((edge) => edge.start_node_id === node.node_id || edge.end_node_id === node.node_id)
  if (edge) {
    const isStart = edge.start_node_id === node.node_id
    return calcRotation(
      isStart,
      isStart ? getCoords(edge.line as any).slice(0, 2) : getCoords(edge.line as any).slice(-2),
    )
  }
  return 0
}

export const addPointToLineString = (line: Position[], point: Position) => {
  let index = -1
  let distance = Infinity
  line.reduce((prev, cur, curIndex) => {
    const segment = [prev, cur]
    const lineAngle = Math.PI - calcLineAngle(segment)
    const lineLength = calcLength([segment[0], point])
    const angle = calcAngleBetweenVectors(segment, [segment[0], point])
    const dx = lineLength * Math.sin(angle) * Math.sin(lineAngle)
    const dy = lineLength * Math.sin(angle) * Math.cos(lineAngle)
    const _pt = [point[0] - dx, point[1] - dy]
    if (isPointOnLine(segment, _pt, 10 ** -5) && calcLength([point, _pt]) < distance) {
      distance = calcLength([point, _pt])
      index = curIndex
    }
    return cur
  })
  if (index > -1) {
    return [...line.slice(0, index), point, ...line.slice(index)]
  }
  return line
}
export const getSourceData = (source: GeoJSONSource) => {
  return source._data as FeatureCollection
}
export const getSourceType = (layer: AnyLayer) => {
  return layer?.source.replace('__source', '') as MapElementType
}
export const getIconName = (type: string) => {
  switch (type) {
    case 'source':
      return 'source'
    case 'sink':
      return 'sink'
    case 'reference_nodes':
      return 'junction'
    case 'compressor_stations':
      return 'cs'
    case 'nodes_reductions':
      return 'reduction'
  }
}
export const getIconSize = (type: string) => {
  switch (type.toLowerCase()) {
    case 'source':
    case 'sink':
      return 48
    case 'reference_nodes':
      return 32
    case 'compressor_stations':
      return 39
    case 'nodes_reductions':
      return 39
    case 'points':
      return 20
    default:
      return 0
  }
  return 0
}

export const generateUID = () => {
  return uuid4()
}
