import { useDispatch, useSelector } from 'react-redux'
import { selectTaskResults, selectTaskStatus, setTaskResults } from 'store/economicSlice'
import { getEconomicResult } from 'services/apiRequests'
import React from 'react'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'

const useGetResults = () => {
  const dispatch = useDispatch()
  const taskStatus = useSelector(selectTaskStatus)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const taskResults = useSelector(selectTaskResults)

  const tryToGetResults = async () => {
    if (trunkPipelineId && projectId)
      await getEconomicResult(projectId, trunkPipelineId).then((response) => {
        dispatch(setTaskResults(response.data))
      })
  }

  React.useEffect(() => {
    if (taskStatus === 'Рассчитан' && !taskResults) tryToGetResults().then()
  }, [taskStatus])

  return
}

export default useGetResults
