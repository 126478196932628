import React, { useState } from 'react'
import { SearchInput } from 'components/SearchInput/searchInput'
import { DirectoryCard } from './components/DirectoryCard/directoryCard'
import { useNavigate } from 'react-router-dom'
import useGetHandbooks from './hooks/useGetHandbooks'
import { definitions } from 'generated/apiTypes'
import DirectoryGroup from './components/DirectoryGroup/directoryGroup'
import './directoties.scss'

export const getHandbookName = (key: keyof definitions['Handbooks']) => {
  switch (key) {
    case 'gpu':
      return 'Справочник ГПА'
    case 'acu':
      return 'Справочник АВО'
    case 'nextgis_layers':
      return 'Справочник соответствия слоев NextGIS'
    case 'pipe_types':
      return 'Справочник типов трубопроводов'
    case 'layers':
      return 'Справочник слоев системы'
    case 'unit_costs':
      return 'Справочник удельных стоимостей'
    case 'offsets':
      return 'Справочник минимально допустимых расстояний'
    case 'economics_compressor_station_calculation_indices':
      return 'Справочник индексов для расчета компрессорных станций'
    case 'economics_pipes_calculation_indices':
      return 'Справочник индексов для расчета труб'
    case 'economics_region_coefficients':
      return 'Справочник районных коэффициентов'
    case 'economics_construction_conditions':
      return 'Справочник коэффициентов, учитывающих условия строительства'
    case 'economics_compressor_station_unit_costs':
      return 'Справочник базовых удельных затрат на компрессорные станции'
    case 'economics_linear_unit_costs':
      return 'Справочник базовых стоимостей для расчета линейной части'
    case 'economics_black_pipe_prices':
      return 'Справочник цен черной трубы'
    default:
      return key
  }
}

const Directories: React.FC = () => {
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState<string | undefined>()

  const handbooks = useGetHandbooks()

  const openDirectory = (id: string) => {
    navigate(`/directory/${id}`)
  }

  const directoriesKeys = React.useMemo(() => {
    return searchValue
      ? Object.keys(handbooks)?.filter((key) =>
          getHandbookName(key as keyof typeof handbooks)
            .toLowerCase()
            .includes(searchValue.toLowerCase()),
        )
      : Object.keys(handbooks)
  }, [handbooks, searchValue])

  return (
    <div className={'main-page__container'}>
      <div className={'main-page-content__container'}>
        <div className={'project-list__projects-header'}>
          <div className={'project-list__projects-title header1'}>Все справочники</div>
          <div className={'project-list__projects-btn'}>
            <SearchInput setValue={setSearchValue} placeholder={'Название справочника...'} />
          </div>
        </div>
        {searchValue ? (
          <div className={'directories__content'}>
            {directoriesKeys
              ?.sort((a, b) =>
                getHandbookName(a as keyof typeof handbooks).localeCompare(
                  getHandbookName(b as keyof typeof handbooks),
                ),
              )
              .map((key) => {
                const pattern = /(\d{2})-(\d{2})-(\d{4})/
                const date = new Date((handbooks as any)[key]?.updated_at.replace(pattern, '$3-$2-$1'))
                return (
                  <DirectoryCard
                    key={key}
                    id={key}
                    title={getHandbookName(key as keyof typeof handbooks)}
                    date={
                      (handbooks as any)[key]?.updated_at
                        ? date
                            .toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' })
                            .replace(' г.', '')
                        : ''
                    }
                    onClick={() => openDirectory(key)}
                  />
                )
              })}
          </div>
        ) : (
          directoriesKeys?.length > 0 && (
            <>
              <DirectoryGroup title={'Гидравлические расчёты'}>
                {directoriesKeys
                  ?.filter((key) => ['pipe_types', 'gpu', 'acu'].includes(key))
                  ?.sort((a, b) =>
                    getHandbookName(a as keyof typeof handbooks).localeCompare(
                      getHandbookName(b as keyof typeof handbooks),
                    ),
                  )
                  .map((key) => {
                    const pattern = /(\d{2})-(\d{2})-(\d{4})/
                    const date = new Date((handbooks as any)[key]?.updated_at.replace(pattern, '$3-$2-$1'))
                    return (
                      <DirectoryCard
                        key={key}
                        id={key}
                        title={getHandbookName(key as keyof typeof handbooks)}
                        date={
                          (handbooks as any)[key]?.updated_at
                            ? date
                                .toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' })
                                .replace(' г.', '')
                            : ''
                        }
                        onClick={() => openDirectory(key)}
                      />
                    )
                  })}
              </DirectoryGroup>
              <DirectoryGroup title={'Автоматическое построение трубопровода'}>
                {directoriesKeys
                  ?.filter((key) => ['layers', 'nextgis_layers', 'offsets', 'unit_costs'].includes(key))
                  ?.sort((a, b) =>
                    getHandbookName(a as keyof typeof handbooks).localeCompare(
                      getHandbookName(b as keyof typeof handbooks),
                    ),
                  )
                  .map((key) => {
                    const pattern = /(\d{2})-(\d{2})-(\d{4})/
                    const date = new Date((handbooks as any)[key]?.updated_at.replace(pattern, '$3-$2-$1'))
                    return (
                      <DirectoryCard
                        key={key}
                        id={key}
                        title={getHandbookName(key as keyof typeof handbooks)}
                        date={
                          (handbooks as any)[key]?.updated_at
                            ? date
                                .toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' })
                                .replace(' г.', '')
                            : ''
                        }
                        onClick={() => openDirectory(key)}
                      />
                    )
                  })}
              </DirectoryGroup>
              <DirectoryGroup title={'Экономические расчеты'}>
                {directoriesKeys
                  ?.filter((key) =>
                    [
                      'economics_compressor_station_calculation_indices',
                      'economics_pipes_calculation_indices',
                      'economics_compressor_station_unit_costs',
                      'economics_region_coefficients',
                      'economics_construction_conditions',
                      'economics_linear_unit_costs',
                      'economics_black_pipe_prices',
                    ].includes(key),
                  )
                  ?.sort((a, b) =>
                    getHandbookName(a as keyof typeof handbooks).localeCompare(
                      getHandbookName(b as keyof typeof handbooks),
                    ),
                  )
                  .map((key) => {
                    const pattern = /(\d{2})-(\d{2})-(\d{4})/
                    const date = new Date((handbooks as any)[key]?.updated_at.replace(pattern, '$3-$2-$1'))
                    return (
                      <DirectoryCard
                        key={key}
                        id={key}
                        title={getHandbookName(key as keyof typeof handbooks)}
                        date={
                          (handbooks as any)[key]?.updated_at
                            ? date
                                .toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' })
                                .replace(' г.', '')
                            : ''
                        }
                        onClick={() => openDirectory(key)}
                      />
                    )
                  })}
              </DirectoryGroup>
            </>
          )
        )}
      </div>
    </div>
  )
}

export default Directories
