import { EditElementPanel } from '../../ElementPanel/editElementPanel'
import Dropdown from 'components/Dropdown/Dropdown'
import React, { useEffect, useState } from 'react'
import NumberInput from 'components/Input/numberInput'
import { resetBlockingWindow, selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectPipeTypes, selectProject } from 'store/projectSlice'
import { definitions } from 'generated/apiTypes'
import { FilterButton } from '../../filterButton'
import { classOptions } from 'pages/utils'
import TextInput from 'components/Input/textInput'
import {
  resetOptimizationSliceState,
  selectTaskSettings,
  setCreateTask,
  setTaskSettings,
} from 'store/optimizationSlice'
import useCreateTask from './hooks/useCreateTask'
import IntegerInput from 'components/Input/integerInput'
import './settings.scss'

const Settings: React.FC = () => {
  const dispatch = useDispatch()
  const pipeTypes = useSelector(selectPipeTypes)
  const taskSettings = useSelector(selectTaskSettings)
  const project = useSelector(selectProject)
  const blockingWindow = useSelector(selectBlockingWindow)
  const [currentPipeType, setCurrentPipeType] = useState<definitions['PipeType']>()

  useCreateTask(taskSettings)
  useEffect(() => {
    !taskSettings &&
      pipeTypes.length &&
      updateStore({
        class_: 1,
        pipe_type_id: pipeTypes[0].id,
        n_pipes: 1,
      })
  }, [pipeTypes.length])
  const updateStore = (params: any) => {
    dispatch(
      setTaskSettings({
        ...taskSettings,
        ...params,
      }),
    )
  }
  useEffect(() => {
    if (taskSettings?.pipe_type_id) {
      setCurrentPipeType(pipeTypes.find((pipeType: any) => pipeType.id === taskSettings?.pipe_type_id))
    }
  }, [taskSettings?.pipe_type_id])

  useEffect(() => {
    if (blockingWindow.objectType === 'TRUNK_PIPELINE' && blockingWindow.isConfirm) {
      dispatch(resetOptimizationSliceState())
      dispatch(setCreateTask(true))
      dispatch(resetBlockingWindow())
    }
  }, [blockingWindow.isConfirm])

  return (
    <EditElementPanel
      className={'optimization-settings__edit-element-panel'}
      title={'Автоматическое построение трубопровода'}
    >
      <div className={'edit-element-panel__subtitle'}>Параметры трубопровода</div>
      <div className={'edit-element-panel__table'}>
        <Dropdown
          options={classOptions}
          displayedField={'name'}
          selectedValue={classOptions.find((option) => option.id === taskSettings?.class_.toString())}
          label={'Класс'}
          setSelectedValue={(value) => updateStore({ class_: value.id })}
        />
        <IntegerInput
          value={taskSettings?.n_pipes}
          label={'Кол-во нитей'}
          fieldName={'n_pipes'}
          setValue={(value, field: string) => updateStore({ [field]: value })}
          noEmpty
        />
        <div className={'common-row'}>
          <hr />
        </div>
      </div>
      <div className={'edit-element-panel__subtitle optimization-settings__complex-subtitle'}>
        Параметры трубы
        <FilterButton
          onClick={() => {
            dispatch(
              setBlockingWindow({
                objectID: project.detail?.trunk_pipeline.id,
                objectType: 'TRUNK_PIPELINE',
                objectName: currentPipeType?.id,
                type: 'EDIT_PIPE_TYPE',
                subID: currentPipeType?.id,
              }),
            )
          }}
        />
      </div>
      <div className={'edit-element-panel__table'}>
        <NumberInput value={currentPipeType?.diameter__mm} unit={'мм'} label={'Внешний диаметр'} disabled />
        <NumberInput value={currentPipeType?.thickness__mm} unit={'мм'} label={'Толщина стенки'} disabled />
        <NumberInput value={currentPipeType?.roughness__mm} unit={'мм'} label={'Шероховатость'} disabled />
        <NumberInput value={currentPipeType?.pressure__MPa} unit={'МПа'} label={'Рабочее давление'} disabled />
        <TextInput value={currentPipeType?.steel_name as string} label={'Марка стали'} disabled />
      </div>
    </EditElementPanel>
  )
}

export default Settings
