import React from 'react'
import './editElementPanel.scss'
import { useDispatch } from 'react-redux'
import { resetShowElementInfo } from 'store/projectSlice'
import { CloseBtn } from 'components/CloseBtn/closeBtn'
import classnames from 'classnames'
import { DeleteButton } from 'components/DeleteButton/DeleteButton'
interface IEditElementPanelProps {
  title: string
  children?: any
  deleteAllow?: boolean
  deleteObj?(): void
  className?: string
}
export const EditElementPanel: React.FC<IEditElementPanelProps> = ({
  title,
  children,
  deleteAllow = false,
  deleteObj,
  className,
}) => {
  const dispatch = useDispatch()
  return (
    <div className={classnames('edit-element-panel', className)}>
      <div className={'edit-element-panel__header'}>
        {title}
        <div className={'edit-element-panel__header-right'}>
          {deleteAllow && <DeleteButton onClick={deleteObj} />}
          <CloseBtn close={() => dispatch(resetShowElementInfo())} />
        </div>
      </div>
      {children}
    </div>
  )
}
