import React from 'react'
import { useParams } from 'react-router-dom'
import './directory.scss'
import DirectoryGPU from './components/GPU/DirectoryGPU'
import DirectoryACU from './components/ACU/DirectoryACU'
import { definitions } from 'generated/apiTypes'
import { BlockingWindow } from 'components/BlockWindow'
import DirectoryPipeTypes from './components/PipeTypes/DirectoryPipeTypes'
import DirectoryLayers from './components/Layers/DirectoryLayers'
import DirectoryNextGISLayers from './components/NextGISLayers/DirectoryNextGISLayers'
import DirectoryUnitCosts from './components/UnitCosts/DirectoryUnitCosts'
import DirectoryOffsets from './components/Offsets/DirectoryOffsets'
import DirectoryCSCalculationIndices from './components/CSCalculationIndices/DirectoryCSCalculationIndices'
import DirectoryIndicesPipesCalculation from './components/IndicesPipesCalculation/DirectoryIndicesPipesCalculation'
import DirectoryRegionCoefficients from './components/RegionCoefficients/directoryRegionCoefficients'
import DirectoryCSUnitCosts from './components/CSUnitCosts/DirectoryCSUnitCosts'
import DirectoryConstructionConditions from './components/ConstructionConditions/DirectoryConstructionConditions'
import DirectoryBlackPipePrices from './components/BlackPipePrices/DirectoryBlackPipePrices'
import DirectoryLinearUnitCosts from './components/LinearUnitCosts/DirectoryLinearUnitCosts'
import NotFoundView from '../../components/NotFoundView/notFoundView'

const Directory: React.FC = () => {
  const { id } = useParams<{ id: keyof definitions['Handbooks'] }>()

  const renderDirectory = () => {
    switch (id) {
      case 'gpu':
        return <DirectoryGPU directoryKey={id} />
      case 'acu':
        return <DirectoryACU directoryKey={id} />
      case 'pipe_types':
        return <DirectoryPipeTypes directoryKey={id} />
      case 'layers':
        return <DirectoryLayers directoryKey={id} />
      case 'nextgis_layers':
        return <DirectoryNextGISLayers directoryKey={id} />
      case 'unit_costs':
        return <DirectoryUnitCosts directoryKey={id} />
      case 'offsets':
        return <DirectoryOffsets directoryKey={id} />
      case 'economics_compressor_station_calculation_indices':
        return <DirectoryCSCalculationIndices directoryKey={id} />
      case 'economics_pipes_calculation_indices':
        return <DirectoryIndicesPipesCalculation directoryKey={id} />
      case 'economics_region_coefficients':
        return <DirectoryRegionCoefficients directoryKey={id} />
      case 'economics_compressor_station_unit_costs':
        return <DirectoryCSUnitCosts directoryKey={id} />
      case 'economics_linear_unit_costs':
        return <DirectoryLinearUnitCosts directoryKey={id} />
      case 'economics_construction_conditions':
        return <DirectoryConstructionConditions directoryKey={id} />
      case 'economics_black_pipe_prices':
        return <DirectoryBlackPipePrices directoryKey={id} />
      default:
        return (
          <NotFoundView
            title={'Справочник не найден'}
            message={'Запрашиваемый справочник отсутствует в системе.\nПожалуйста, проверьте корректность ссылки.'}
          />
        )
    }
  }

  return (
    <>
      <BlockingWindow />
      {renderDirectory()}
    </>
  )
}

export default Directory
