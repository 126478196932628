import React from 'react'
import { useSelector } from 'react-redux'
import { selectTaskStatus, selectErrorMessage } from 'store/optimizationSlice'
import './results.scss'
import CalcStatus from 'components/CalcStatus'

const Results: React.FC = () => {
  const taskStatus = useSelector(selectTaskStatus)
  const errorMessage = useSelector(selectErrorMessage)

  return (
    <div className={'edit-element-panel optimization-results__edit-element-panel'}>
      <div className={'hydraulic-calc__status-wrapper'}>
        <span className={'hydraulic-calc__title header2'}>Автоматическое построение труб...</span>
        <CalcStatus status={taskStatus} classNames={'optimization-results__status'} />
      </div>
      {taskStatus === 'Ошибка' && errorMessage && (
        <div className={'optimization-info__error-message'}>{errorMessage}</div>
      )}
    </div>
  )
}

export default Results
