import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getHydroCalculationProperties } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { definitions } from 'generated/apiTypes'

const useGetHydroSettings = (): definitions['HydroCalculationProperties'] | undefined => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)
  const [properties, setProperties] = React.useState<definitions['HydroCalculationProperties']>()

  const tryToGetHydroCalculationProperties = async () => {
    if (trunkPipelineId && projectId) {
      await getHydroCalculationProperties(projectId, trunkPipelineId)
        .then((response) => {
          setProperties(response.data)
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              objectName: 'Непредвиденная ошибка',
              message: 'Непредвиденная ошибка',
              type: 'ERROR',
            }),
          )
        })
    }
  }

  React.useEffect(() => {
    tryToGetHydroCalculationProperties().then()
  }, [projectId])

  return properties
}

export default useGetHydroSettings
