import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItems, updateItemFieldById, setModified, addDirectoryItem } from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import { columns } from './tableConfig'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'

import useGetAreaTypes from 'pages/Directory/hooks/useGetAreaTypes'
import { Table } from 'components/Table/Table/Table'
import './directoryUnitCosts.scss'
import useCreateUnitCostsVersion from './hooks/useCreateUnitCostsVersion'
import { HandbookAreaUnitCostRule } from 'store/types'
import useGetUnitCosts from './hooks/useGetUnitCosts'
import useGetPipeTypes from './hooks/useGetPipeTypes'
import { generateUID } from '../../../utils'

interface IDirectoryNextGISLayersProps {
  directoryKey: keyof definitions['Handbooks']
}
interface IRowProps {
  id: string
  name: string
  [key: string]: number | string
}
const newItem = (
  pipeType: definitions['HandbookPipeType'],
  areaType: definitions['HandbookAreaType'],
  unit_cost__thousand_rub_per_km: number,
) => {
  return {
    id: generateUID(),
    pipe_type: {
      id: pipeType.id,
      name: pipeType.name,
      diameter__mm: pipeType.diameter__mm,
    },
    area_type: {
      id: areaType.id,
      name: areaType.name,
    },
    unit_cost__thousand_rub_per_km,
    created: true,
  }
}
const DirectoryUnitCosts: React.FC<IDirectoryNextGISLayersProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const directoryItems = useSelector(selectDirectoryItems) as HandbookAreaUnitCostRule[]
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)
  const areaTypes = useGetAreaTypes()
  useGetUnitCosts(requestData)

  const saveResult = useCreateUnitCostsVersion(saveData)

  React.useEffect(() => {
    if (saveResult) setEditMode(false)
  }, [saveResult])

  const onSaveItemField = async (pipe_type_id: string, value: number, area_type_id: string) => {
    const unitCostRule: definitions['HandbookAreaUnitCostRule'] | undefined = directoryItems.find(
      (item: definitions['HandbookAreaUnitCostRule']) =>
        item.pipe_type.id === pipe_type_id && item.area_type.id === area_type_id,
    )
    if (unitCostRule) {
      dispatch(
        updateItemFieldById({
          id: unitCostRule.id,
          value,
          fieldName: 'unit_cost__thousand_rub_per_km',
        }),
      )
      dispatch(setModified(unitCostRule.id))
      setIsModified(true)
    }
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  const dataSource = React.useMemo(() => {
    const data: IRowProps[] = []
    const pipes: string[] = []
    directoryItems.forEach((item) => !pipes.includes(item.pipe_type.id) && pipes.push(item.pipe_type.id))
    if (areaTypes) {
      pipes.forEach((pipe) => {
        const pipeName = directoryItems.find((item) => item.pipe_type.id === pipe)?.pipe_type.name
        data.push({
          id: pipe as string,
          name: pipeName as string,
          ...Object.fromEntries(areaTypes.map((item) => [item.id, 0])),
        })
      })
      directoryItems.forEach((item: definitions['HandbookAreaUnitCostRule']) => {
        const inListIndex = data.findIndex((d) => d.id === item.pipe_type.id)
        if (inListIndex > -1) {
          data[inListIndex][item.area_type.id] = item.unit_cost__thousand_rub_per_km
        }
      })
    }
    return data
  }, [directoryItems, areaTypes])

  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      title={getHandbookName(directoryKey)}
      isModified={isModified}
    >
      <div className={'directory__content'}>
        <Table
          className={'directory-table__container unit-costs-table__container'}
          columns={columns(
            editMode,
            areaTypes.filter((item) => !item?.is_restricted),
          )}
          dataSource={dataSource}
          setValue={onSaveItemField}
        />
      </div>
    </DirectoryWrapper>
  )
}

export default DirectoryUnitCosts
