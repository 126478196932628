import React, { useEffect, useState } from 'react'
import './gasTransportScheme.scss'
import Button from 'components/Button'
import { Scheme } from 'components/Scheme/scheme'
import { useDispatch, useSelector } from 'react-redux'
import { selectDisplayScheme, setDisplayScheme } from 'store/canvasSchemeSlice'
import classnames from 'classnames'
import { CloseBtn } from 'components/CloseBtn/closeBtn'
import DownloadIcon from 'images/DownloadIcon'
export const GasTransportScheme: React.FC = () => {
  const dispatch = useDispatch()
  const display = useSelector(selectDisplayScheme)
  const [download, setDownload] = useState(false)
  useEffect(() => {
    return () => {
      dispatch(setDisplayScheme(false))
    }
  }, [])
  if (display)
    return (
      <div className={classnames('gas-transport-scheme__frame')}>
        <div className={'gas-transport-scheme__header'}>
          <div>
            <span className={'header2'}>Схема транспорта газа</span>
            <Button mode={'secondary'} onClick={() => setDownload(true)}>
              <DownloadIcon /> Скачать
            </Button>
          </div>
          <CloseBtn close={() => dispatch(setDisplayScheme(false))} />
        </div>
        <Scheme download={download} setDownload={setDownload} />
      </div>
    )
  return <></>
}
