import React from 'react'
import { definitions } from 'generated/apiTypes'

interface IUnitCostsColumn {
  title: string | JSX.Element
  type: 'Text' | 'Number'
  key: keyof definitions['HandbookAreaUnitCostRule'] | string
  editable: boolean
  options?: any[]
  displayedField?: string
  className?: string
  sticky?: boolean
}

export const columns = (
  editMode: boolean,
  areaTypes: definitions['HandbookNextgisAreaTypeAreaType'][] | [],
): IUnitCostsColumn[] => [
  {
    title: (
      <>
        Удельная стоимость строительства,
        <br />
        тыс.руб/км
      </>
    ),
    type: 'Text',
    key: 'name',
    editable: false,
    className: 'unit-cost-table__header',
    sticky: true,
  },
  ...areaTypes.map((item) => {
    return {
      title: item.name,
      type: 'Number',
      key: item.id,
      noEmpty: true,
      minValue: 1,
      editable: editMode,
    } as IUnitCostsColumn
  }),
]
