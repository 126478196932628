import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItems, updateItemFieldById, setModified } from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import { columns } from './tableConfig'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import './directoryOffsets.scss'
import { Table } from 'components/Table/Table/Table'
import useGetOffsets from './hooks/useGetOffsets'
import useCreateOffsetsVersion from './hooks/useCreateOffsetsVersion'

interface IDirectoryNextGISLayersProps {
  directoryKey: keyof definitions['Handbooks']
}

const DirectoryOffsets: React.FC<IDirectoryNextGISLayersProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const directoryItems = useSelector(selectDirectoryItems) as definitions['HandbookOffsetRule'][]
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)
  useGetOffsets(requestData)

  const saveResult = useCreateOffsetsVersion(saveData)
  React.useEffect(() => {
    if (saveResult) setEditMode(false)
  }, [saveResult])

  const onSaveItemField = async (id: string, value: number, fieldName: string) => {
    const offset: definitions['HandbookOffsetRule'] | undefined = directoryItems.find(
      (item: definitions['HandbookOffsetRule']) => item.id === id,
    )
    if (offset) {
      const [classField, offsetField] = fieldName.split('/')
      dispatch(
        updateItemFieldById({
          id: offset.id,
          value: {
            ...(offset[
              classField as keyof definitions['HandbookOffsetRule']
            ] as unknown as definitions['HandbookOffsetRule']),
            [offsetField]: isNaN(value) ? 0 : value,
          },
          fieldName: classField,
        }),
      )
      dispatch(setModified(offset.id))
      setIsModified(true)
    }
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  const dataSource = React.useMemo(() => {
    const data: any[] = []
    directoryItems.forEach((item: definitions['HandbookOffsetRule']) => {
      data.push({
        id: item.id,
        area_type: item.area_type.name,
        'class_1/offset_0_300__m': item.class_1.offset_0_300__m,
        'class_1/offset_300_600__m': item.class_1.offset_300_600__m,
        'class_1/offset_600_800__m': item.class_1.offset_600_800__m,
        'class_1/offset_800_1000__m': item.class_1.offset_800_1000__m,
        'class_1/offset_1000_1200__m': item.class_1.offset_1000_1200__m,
        'class_1/offset_1200_1400__m': item.class_1.offset_1200_1400__m,
        'class_2/offset_0_300__m': item.class_2.offset_0_300__m,
        'class_2/offset_300_2000__m': item.class_2.offset_300_2000__m,
      })
    })
    return data
  }, [directoryItems])
  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      isModified={isModified}
      title={getHandbookName(directoryKey)}
    >
      <div className={'directory__content'}>
        <Table
          className={'directory-table__container offsets-table__container'}
          columns={columns(editMode)}
          dataSource={dataSource}
          setValue={onSaveItemField}
        />
      </div>
    </DirectoryWrapper>
  )
}

export default DirectoryOffsets
