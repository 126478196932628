import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'

import store from './store/configureStore'
import { addApiErrors } from './store/commonSlice'
import { addToValidation, removeFromValidation } from './store/validationSlice'
import { handleStorage } from './services/handleStorage'
import updateAuthToken from './services/updateAuthToken'
import { apiError } from './store/types'

let config: AxiosRequestConfig = {
  headers: {
    'Content-Type': 'application/json',
  },
}

const getApiURL = () => {
  if (window?.env?.API_URL) {
    return window.env.API_URL
  } else {
    return process.env.REACT_APP_API_URL
  }
}

const ApiURL = getApiURL()
export const BaseURL = `${ApiURL}/api`
export let api = axios.create(config)

export const setBaseURL = () => {
  config = {
    baseURL: BaseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  }
  api = axios.create(config)
  api.defaults.timeout = 1000 * 60
  api.interceptors.request.use(handleRequest)
  api.interceptors.response.use((res: AxiosResponse) => handleResponse(res), handleError)
}

const handleRequest = async (request: any) => {
  if (!request.headers?.common?.Authorization) {
    const token = handleStorage.getToken()
    if (!token) {
      return request
    }
    updateAuthToken(token)
    request.headers = {
      ...request.headers,
      Authorization: `Token ${handleStorage.getToken()}`,
    }
  }
  return request
}

const handleResponse = async (res: AxiosResponse) => {
  if (res.config.data && res.config.method === 'patch') {
    const id = res?.config.url?.split('/').slice(-2, -1)[0] as string
    for (const [field, errorMessage] of Object.entries(JSON.parse(res.config.data))) {
      store.dispatch(removeFromValidation({ id, field }))
    }
  }
  return res
}
const handleError = async (error: any) => {
  switch (error?.response?.status) {
    case 400: {
      const errorsObj: apiError[] = []
      if (error.response.data?.length > 0) {
        ;(error.response.data as string[]).forEach((item) => {
          errorsObj.push({
            status: error.response.status,
            url: `${error.config.baseURL}${error.config.url}`,
            timestamp: new Date().toISOString(),
            message: item,
          })
        })
      } else if (error.response.data?.non_field_errors) {
        ;(error.response.data.non_field_errors as string[]).forEach((item) =>
          errorsObj.push({
            status: error.response.status,
            url: `${error.config.baseURL}${error.config.url}`,
            timestamp: new Date().toISOString(),
            message: item,
          }),
        )
      } else {
        const id = error?.response?.config.url.split('/').slice(-2, -1)[0]
        for (const [field, errorMessage] of Object.entries(error?.response?.data)) {
          store.dispatch(
            addToValidation({
              id,
              field,
              errorMessage: errorMessage as string[],
            }),
          )
        }
      }
      store.dispatch(addApiErrors(errorsObj))
      break
    }
    case 500: {
      store.dispatch(
        addApiErrors([
          {
            status: error.response.status,
            url: `${error.config.baseURL}${error.config.url}`,
            timestamp: new Date().toISOString(),
            message: 'Server Error',
          },
        ]),
      )
      break
    }
    case 401: {
      handleStorage.removeToken()
      handleStorage.removeUser()
      updateAuthToken(false)
      if (!window.location.href.includes('login')) {
        window.location.replace(`${BaseURL}/logout/`)
      }
      break
    }
    // case 422: {
    //   const errorsObj: {
    //     status: number
    //     url: string
    //     timestamp: string
    //     message: string
    //   }[] = []
    //   error.response.data.errors.forEach((item: components['schemas']['ValidationError']) =>
    //     errorsObj.push({
    //       status: error.response.status,
    //       url: `${error.config.baseURL}${error.config.url}`,
    //       timestamp: new Date().toISOString(),
    //       message: item.msg,
    //     }),
    //   )
    //   store.dispatch(addApiErrors(errorsObj))
    //   break
    // }
    default: {
      store.dispatch(
        addApiErrors([
          {
            status: error.response?.status,
            url: `${error.config.baseURL}${error.config.url}`,
            timestamp: new Date().toISOString(),
            message: error.response?.data?.detail,
          },
        ]),
      )
    }
  }
  return Promise.reject(error)
}
