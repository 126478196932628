import { IProject, IShowElementInfo, IUpdatedElement } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configureStore'
import { definitions } from '../generated/apiTypes'
import update from 'immutability-helper'

export const initialState: IProject = {
  detail: null,
  sink: undefined,
  source: undefined,
  pipeTypes: [],
  gpuChoices: [],
  acuChoices: [],
  compressorStations: [],
  referenceNodes: [],
  reductionNodes: [],
  trunkPipeline: undefined,
  pipelines: [],
  showElementInfo: {
    isVisible: false,
    objectType: null,
    objectId: null,
    objectName: null,
  },
  updatedElement: {
    elementID: null,
    type: null,
    params: null,
  },
  compressorStationsOrder: [],
  loadingHeightNodeID: null,
}
export const projectSlice = createSlice({
  name: 'projectSlice',
  initialState,
  reducers: {
    setProjectDetail: (state, action: PayloadAction<definitions['ProjectDetail']>) => {
      state.detail = action.payload
    },
    setCompressorStationsOrder: (state, action: PayloadAction<IProject['compressorStationsOrder']>) => {
      state.compressorStationsOrder = action.payload
    },
    updateProjectDetail: (state, action: PayloadAction<definitions['ProjectDetail']>) => {
      state.detail = {
        ...state.detail,
        ...action.payload,
      }
    },
    resetProjectDetail: (state) => {
      state.detail = initialState.detail
    },
    updateSink: (state, action: PayloadAction<definitions['Sink']>) => {
      state.sink = {
        ...state.sink,
        ...action.payload,
      }
    },
    updateSource: (state, action: PayloadAction<definitions['Source']>) => {
      state.source = {
        ...state.source,
        ...action.payload,
      }
    },
    setShowElementInfo: (state, action: PayloadAction<IShowElementInfo>) => {
      state.showElementInfo = {
        ...state.showElementInfo,
        ...action.payload,
      }
    },
    resetShowElementInfo: (state) => {
      state.showElementInfo = initialState.showElementInfo
    },
    setUpdatedElement: (state, action: PayloadAction<IUpdatedElement>) => {
      state.updatedElement = action.payload
    },
    resetUpdatedElement: (state) => {
      state.updatedElement = initialState.updatedElement
    },
    setPipeTypes: (state, action: PayloadAction<IProject['pipeTypes']>) => {
      state.pipeTypes = action.payload
    },
    setCompressorStations: (state, action: PayloadAction<definitions['CompressorStation']>) => {
      state.compressorStations = [...state.compressorStations, action.payload]
    },
    setReferenceNodes: (state, action: PayloadAction<definitions['ReferenceNode']>) => {
      state.referenceNodes = [...state.referenceNodes, action.payload]
    },
    setReductionNodes: (state, action: PayloadAction<definitions['ReductionNode']>) => {
      state.reductionNodes = [...state.reductionNodes, action.payload]
    },
    resetProject: () => {
      return initialState
    },
    updateCompressorStation: (state, action: PayloadAction<definitions['CompressorStation']>) => {
      const updateIndex = state.compressorStations.findIndex((station) => station.id === action.payload.id)
      if (updateIndex > -1) {
        state.compressorStations = update(state.compressorStations, {
          [updateIndex]: {
            $set: action.payload,
          },
        })
      } else {
        state.compressorStations = update(state.compressorStations, {
          $push: [action.payload],
        })
      }
    },
    updateReferenceNode: (state, action: PayloadAction<definitions['ReferenceNode']>) => {
      const updateIndex = state.referenceNodes.findIndex((node) => node.id === action.payload.id)
      state.referenceNodes = update(state.referenceNodes, {
        [updateIndex]: {
          $set: action.payload,
        },
      })
    },
    updateReductionNode: (state, action: PayloadAction<definitions['ReductionNode']>) => {
      const updateIndex = state.reductionNodes.findIndex((node) => node.id === action.payload.id)
      state.reductionNodes = update(state.reductionNodes, {
        [updateIndex]: {
          $set: action.payload,
        },
      })
    },
    setTrunkPipeline: (state, action: PayloadAction<definitions['TrunkPipeline']>) => {
      state.trunkPipeline = action.payload
    },
    setPipelines: (state, action: PayloadAction<definitions['DetailedPipeline'][]>) => {
      state.pipelines = action.payload
    },
    addPipeline: (state, action: PayloadAction<definitions['DetailedPipeline']>) => {
      state.pipelines = [...state.pipelines, action.payload]
    },
    updatePipeline: (state, action: PayloadAction<definitions['DetailedPipeline']>) => {
      const updateIndex = state.pipelines.findIndex((pipeline) => pipeline.id === action.payload.id)
      state.pipelines = update(state.pipelines, {
        [updateIndex]: {
          $set: action.payload,
        },
      })
    },
    setGPUChoices: (state, action: PayloadAction<definitions['HandbookGPUCompressed'][]>) => {
      state.gpuChoices = action.payload
    },
    setACUChoices: (state, action: PayloadAction<definitions['HandbookACUCompressed'][]>) => {
      state.acuChoices = action.payload
    },
    setLoadingHeightNodeID: (state, action: PayloadAction<IProject['loadingHeightNodeID']>) => {
      state.loadingHeightNodeID = action.payload
    },
  },
})

export const {
  setProjectDetail,
  setCompressorStationsOrder,
  updateProjectDetail,
  resetProjectDetail,
  setShowElementInfo,
  resetShowElementInfo,
  updateSink,
  updateSource,
  setUpdatedElement,
  resetUpdatedElement,
  setPipeTypes,
  resetProject,
  setCompressorStations,
  setReferenceNodes,
  setReductionNodes,
  updateCompressorStation,
  updateReferenceNode,
  updateReductionNode,
  setTrunkPipeline,
  setPipelines,
  addPipeline,
  updatePipeline,
  setGPUChoices,
  setACUChoices,
  setLoadingHeightNodeID,
} = projectSlice.actions

export const selectProject = (state: RootState) => state.project
export const selectProjectID = (state: RootState) => state.project.detail?.id
export const selectTrunkPipelineID = (state: RootState) => state.project.detail?.trunk_pipeline.id
export const selectShowElementInfo = (state: RootState) => state.project.showElementInfo
export const selectSink = (state: RootState) => state.project.sink
export const selectUpdatedElement = (state: RootState) => state.project.updatedElement
export const selectPipeTypes = (state: RootState) => state.project.pipeTypes
export const selectGPUChoices = (state: RootState) => state.project.gpuChoices
export const selectACUChoices = (state: RootState) => state.project.acuChoices
export const selectCompressorStations = (state: RootState) => state.project.compressorStations
export const selectCompressorStationsOrder = (state: RootState) => state.project.compressorStationsOrder
export const selectLoadingHeightNodeID = (state: RootState) => state.project.loadingHeightNodeID

export default projectSlice.reducer
