import { IProjectsList } from './types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configureStore'
import { definitions } from '../generated/apiTypes'

export const initialState: IProjectsList = {
  items: [],
  getProjects: false,
}
export const projectsListSlice = createSlice({
  name: 'projectsListSlice',
  initialState: {
    items: initialState.items,
    getProjects: initialState.getProjects,
  },
  reducers: {
    setProjectsList: (state, action: PayloadAction<definitions['Project'][]>) => {
      state.items = action.payload
    },
    setGetProjects: (state, action: PayloadAction<IProjectsList['getProjects']>) => {
      state.getProjects = action.payload
    },
  },
})

export const { setProjectsList, setGetProjects } = projectsListSlice.actions

export const selectProjectsList = (state: RootState) => state.projectsList.items

export const selectGetProjects = (state: RootState) => state.projectsList.getProjects

export default projectsListSlice.reducer
