import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { PipeParamsWindow } from './PipeParamsWindow'
import { resetBlockingWindow } from 'store/commonSlice'
import { DeleteWindow } from './DeleteWindow'
import { HydroProgressWindow } from './HydroProgressWindow'
import { EconomicProgressWindow } from './EconomicProgressWindow'
import { deleteProjectByIDRequest, updateProjectByIDRequest } from 'services/apiRequests'
import { setProjectsList, selectProjectsList, setGetProjects } from 'store/projectsListSlice'
import { setUpdatedElement, updateProjectDetail } from 'store/projectSlice'
import { useNavigate } from 'react-router-dom'
import { EditProjectNameWindow } from './EditProjectNameWindow'
import { selectTaskSettings, setTaskSettings } from 'store/optimizationSlice'
import { definitions } from 'generated/apiTypes'
import { OptimizationProgressWindow } from './OptimizationProgressWindow'
import { ErrorWindow } from './ErrorWindow'
import { DiscardChangesWindow } from './DiscardChangesWindow'
import { GPUChoicesWindow } from './GPUChoicesWindow'
import { ACUChoicesWindow } from './ACUChoicesWindow'
import { StopTaskWindow } from './StopTaskWindow'
import { ConfirmWindow } from './ConfirmWindow'

export const BlockingWindow: React.FC = () => {
  const blockingWindow = useSelector(selectBlockingWindow)
  const projectsList = useSelector(selectProjectsList)
  const optimizationTask = useSelector(selectTaskSettings)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const closeWindow = () => {
    dispatch(resetBlockingWindow())
  }
  const deleteObject = () => {
    switch (blockingWindow.objectType) {
      case 'PROJECT':
        deleteProjectByIDRequest(blockingWindow.objectID as string).then(() => {
          dispatch(setProjectsList(projectsList.filter((project: any) => project.id !== blockingWindow.objectID)))
          dispatch(resetBlockingWindow())
          navigate('/')
        })
        break
      case 'REFERENCE_NODES':
      case 'NODES_REDUCTIONS':
      case 'COMPRESSOR_STATIONS':
      case 'PIPE':
      case 'HANDBOOK_ITEMS':
        dispatch(
          setBlockingWindow({
            isConfirm: true,
          }),
        )
    }
  }
  const updateProjectName = (name: string) => {
    updateProjectByIDRequest(blockingWindow.objectID as string, { name }).then(() => {
      dispatch(updateProjectDetail({ name } as any))
      dispatch(setGetProjects(true))
      dispatch(resetBlockingWindow())
    })
  }
  const updatePipeType = (pipeTypeID: string) => {
    switch (blockingWindow.objectType) {
      case 'TRUNK_PIPELINE':
        dispatch(
          setTaskSettings({
            ...optimizationTask,
            pipe_type_id: pipeTypeID,
          } as definitions['OptimizationCreate']),
        )
        break
      case 'PIPE':
        dispatch(
          setUpdatedElement({
            elementID: blockingWindow.objectID as string,
            type: 'PIPE',
            params: {
              pipe_type_id: pipeTypeID,
            },
          }),
        )
    }
    closeWindow()
  }

  const confirm = () => {
    dispatch(
      setBlockingWindow({
        isConfirm: true,
      }),
    )
  }

  if (blockingWindow.objectType === 'GPU' && blockingWindow.type === 'EDIT')
    return <GPUChoicesWindow closeWindow={closeWindow} />
  if (blockingWindow.objectType === 'ACU' && blockingWindow.type === 'EDIT')
    return <ACUChoicesWindow closeWindow={closeWindow} />
  if (blockingWindow.type === 'EDIT_PIPE_TYPE')
    return (
      <PipeParamsWindow
        updatePipeType={updatePipeType}
        closeWindow={closeWindow}
        pipeID={blockingWindow.objectName || ''}
        currentPipeTypeID={blockingWindow.subID}
      />
    )
  if (blockingWindow.type === 'DELETE_CONFIRM')
    return <DeleteWindow blockingWindow={blockingWindow} closeWindow={closeWindow} deleteObject={deleteObject} />
  if (blockingWindow.type == 'EDIT')
    return (
      <EditProjectNameWindow blockingWindow={blockingWindow} updateName={updateProjectName} closeWindow={closeWindow} />
    )
  if (blockingWindow.type === 'HYDRO_PROGRESS') return <HydroProgressWindow blockingWindow={blockingWindow} />
  if (blockingWindow.type === 'ECONOMIC_PROGRESS') return <EconomicProgressWindow blockingWindow={blockingWindow} />
  if (blockingWindow.type === 'OPTIMIZATION_PROGRESS')
    return <OptimizationProgressWindow blockingWindow={blockingWindow} />
  if (blockingWindow.type === 'ERROR') return <ErrorWindow blockingWindow={blockingWindow} closeWindow={closeWindow} />
  if (blockingWindow.type === 'DISCARD_CHANGES') return <DiscardChangesWindow closeWindow={closeWindow} />
  if (blockingWindow.type === 'STOP_TASK_CONFIRM')
    return <StopTaskWindow blockingWindow={blockingWindow} stopTask={confirm} closeWindow={closeWindow} />
  if (blockingWindow.type === 'CONFIRM')
    return <ConfirmWindow blockingWindow={blockingWindow} confirm={confirm} closeWindow={closeWindow} />
  return <></>
}
