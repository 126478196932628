import { EditElementPanel } from '../editElementPanel'
import NumberInput from 'components/Input/numberInput'
import React from 'react'
import TextInput from 'components/Input/textInput'
import { definitions } from 'generated/apiTypes'
import { getCoords } from '@turf/turf'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectLoadingHeightNodeID,
  selectProjectID,
  setLoadingHeightNodeID,
  setUpdatedElement,
  updateReductionNode,
} from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import {
  getNodesByProjectIDRequest,
  getReductionNodeByIDRequest,
  updateGISHeight,
  updateReductionNodeByIDRequest,
} from 'services/apiRequests'
import { setMapNodes } from 'store/mapSlice'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import RedoIcon from 'images/RedoIcon'

interface IReductionNodeEditPanel {
  object?: definitions['ReductionNode']
}
export const ReductionNodeEditPanel: React.FC<IReductionNodeEditPanel> = ({ object }) => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)
  const loadingHeightNodeID = useSelector(selectLoadingHeightNodeID)
  const updateStore = (updatedField: { [key: string]: any }) => {
    dispatch(
      updateReductionNode({
        ...object,
        ...updatedField,
      } as definitions['ReductionNode']),
    )
  }
  const setValue = (value: any, label: string) => {
    let updatedValue
    if (label === 'central_point') {
      updatedValue = {
        ...object?.central_point,
        coordinates: value,
      }
    } else updatedValue = value
    updateStore({ [label]: updatedValue })
    dispatch(
      setUpdatedElement({
        elementID: object?.id as string,
        type: 'NODES_REDUCTIONS',
        params: { [label]: updatedValue },
      }),
    )
  }
  const deleteObj = () => {
    dispatch(
      setBlockingWindow({
        objectID: object?.id,
        objectType: 'NODES_REDUCTIONS',
        type: 'DELETE_CONFIRM',
        objectName: object?.name,
      }),
    )
  }
  const setHeight = (value: number) => {
    updateStore({ height__m: value })
    if (projectId && object?.id) {
      updateReductionNodeByIDRequest(projectId, object.id, {
        height__m: value,
      } as definitions['ReductionNodeUpdate']).then(() => {
        getReductionNodeByIDRequest(projectId, object.id).then((res) => {
          dispatch(updateReductionNode(res.data))
        })
        getNodesByProjectIDRequest(projectId).then((res: any) => {
          dispatch(setMapNodes(res.data))
        })
      })
    }
  }
  const setAllowToRequestHeight = async (value: boolean) => {
    updateStore({ allow_to_request_gis_height: value })
    if (projectId && object?.id) {
      if (value) dispatch(setLoadingHeightNodeID(object.id))
      updateReductionNodeByIDRequest(projectId, object.id, {
        allow_to_request_gis_height: value,
      } as definitions['ReductionNodeUpdate'])
        .then(() => {
          getReductionNodeByIDRequest(projectId, object.id)
            .then((res) => {
              dispatch(updateReductionNode(res.data))
              if (value) dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectId).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  const updateHeight = async () => {
    if (projectId && object?.id) {
      dispatch(setLoadingHeightNodeID(object.id))
      updateGISHeight(projectId, object.id)
        .then(() => {
          getReductionNodeByIDRequest(projectId, object.id)
            .then((res) => {
              dispatch(updateReductionNode(res.data))
              dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectId).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  return (
    <EditElementPanel title={'Узел редуцирования газа'} deleteAllow deleteObj={deleteObj}>
      <div className={'edit-element-panel__table'}>
        <div className={'common-row'}>
          <TextInput value={object?.name || ''} fieldName={'name'} label={'Название'} setValue={setValue} />
        </div>
        <NumberInput
          id={object?.id}
          key={`${object?.id}_central_point1`}
          fieldName={'central_point'}
          value={object?.central_point ? getCoords(object?.central_point as any)[1] : ''}
          setValue={(value, label: string) => setValue([getCoords(object?.central_point as any)[0], value], label)}
          label={'lat'}
          signed
          noEmpty
        />
        <NumberInput
          id={object?.id}
          key={`${object?.id}_central_point2`}
          fieldName={'central_point'}
          value={object?.central_point ? getCoords(object?.central_point as any)[0] : ''}
          setValue={(value, label: string) => setValue([value, getCoords(object?.central_point as any)[1]], label)}
          label={'lon'}
          signed
          noEmpty
        />
        <NumberInput
          unit={'м'}
          value={object?.height__m || 0}
          label={'Высотная отметка'}
          fieldName={'height__m'}
          setValue={setHeight}
          loading={loadingHeightNodeID === object?.id}
          signed
          infoText={'Поле с выбранной опцией "Загружать из ГИС" обновляется автоматически при перемещении объекта'}
          warningText={object?.gis_height_error}
          disabled={object?.allow_to_request_gis_height}
        />
        <div className={'edit-element-panel__height-row'}>
          <Checkbox
            setCheckedValue={setAllowToRequestHeight}
            checkedValue={object?.allow_to_request_gis_height || false}
          >
            Загружать из ГИС
          </Checkbox>
          <div className={'edit-element-panel__redo-btn-container'}>
            {object?.allow_to_request_gis_height && (
              <Button className={'edit-element-panel__redo-btn'} mode={'secondary'} onClick={updateHeight}>
                <RedoIcon />
              </Button>
            )}
          </div>
        </div>
        <NumberInput
          id={object?.id}
          key={`${object?.id}_pressure__MPa`}
          unit={'МПа'}
          fieldName={'pressure__MPa'}
          value={object?.pressure__MPa}
          label={'Давление на выходе'}
          setValue={setValue}
          signed
        />
        <NumberInput
          id={object?.id}
          key={`${object?.id}_joule_thomson_ratio`}
          fieldName={'joule_thomson_ratio'}
          unit={'°C/МПа'}
          value={object?.joule_thomson_ratio}
          label={'Коэффициент Джоуля-Томпсона'}
          setValue={setValue}
          signed
        />
      </div>
    </EditElementPanel>
  )
}
