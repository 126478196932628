import React, { CSSProperties, PropsWithChildren } from 'react'
import classNames from 'classnames'
import './resizable.scss'

interface IResizableProps {
  className?: string
  handleElement?: JSX.Element
  setWidth?(width: number | undefined): void
}

const Resizable: React.FC<PropsWithChildren<IResizableProps>> = ({ className, children, setWidth, handleElement }) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [containerStyles, setContainerStyles] = React.useState<CSSProperties>()
  const [dragMode, setDragMode] = React.useState<boolean>(false)

  const resize = (e: MouseEvent) => {
    if (containerRef?.current?.clientWidth) {
      const rect = containerRef?.current?.getBoundingClientRect()
      setContainerStyles({
        width: `${containerRef?.current?.clientWidth + (e.pageX - (rect?.left + rect.width))}px`,
      })
      if (setWidth) setWidth(containerRef?.current?.clientWidth)
    }
  }

  React.useEffect(() => {
    if (dragMode) {
      document.addEventListener('mousemove', resize)
      document.addEventListener('mouseup', onMouseUp)
    }
    return () => {
      document.removeEventListener('mousemove', resize)
      document.removeEventListener('mouseup', onMouseUp)
    }
  }, [dragMode])

  const onMouseDown = () => {
    setDragMode(true)
    document.body.style.cursor = 'ew-resize'
  }

  const onMouseUp = () => {
    setDragMode(false)
    document.body.style.cursor = 'default'
  }

  return (
    <div
      ref={containerRef}
      style={containerStyles}
      className={classNames('resizable-container', className, dragMode && 'dragging')}
    >
      {children}
      <button onMouseDown={onMouseDown} onMouseUp={onMouseUp} className={'resizable-container__handle'}>
        {handleElement}
      </button>
    </div>
  )
}

export default Resizable
