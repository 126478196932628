import React, { ChangeEvent, KeyboardEventHandler, useCallback } from 'react'
import './login.scss'
// @ts-ignore
import { ReactComponent as Logo } from 'images/LogoBlue.svg'
import Input from 'components/Input'
import Button from 'components/Button'
import useLogin from './hooks/useLogin'
import { useDispatch, useSelector } from 'react-redux'
import { setLoginRequest } from 'store/loginSlice'
import { deleteApiError, selectApiErrors } from 'store/commonSlice'
import { BaseURL } from 'api'
import useRedirect from './hooks/useRedirect'
const Login: React.FC = () => {
  const [username, setUsername] = React.useState<string>('')
  const [password, setPassword] = React.useState<string>('')
  const [emptyUsernameError, setEmptyUsernameError] = React.useState<boolean>(false)
  const [emptyPasswordError, setEmptyPasswordError] = React.useState<boolean>(false)
  const apiErrors = useSelector(selectApiErrors)
  const dispatch = useDispatch()

  useLogin({ username, password })
  const noRedirect = useRedirect()

  const onChangeUsername = (event: ChangeEvent<HTMLInputElement>) => {
    if (emptyUsernameError) setEmptyUsernameError(false)
    const error = apiErrors.find((item) => item.message === 'Access denied: wrong username or password')
    if (error) dispatch(deleteApiError(error))
    setUsername(event.target.value)
  }

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    if (emptyPasswordError) setEmptyPasswordError(false)
    const error = apiErrors.find((item) => item.message === 'Access denied: wrong username or password')
    if (error) dispatch(deleteApiError(error))
    setPassword(event.target.value)
  }

  const onLoginClick = () => {
    if (!username) setEmptyUsernameError(true)
    else if (!password) setEmptyPasswordError(true)
    else {
      const error = apiErrors.find((item) => item.message === 'Access denied: wrong username or password')
      if (error) dispatch(deleteApiError(error))
      dispatch(setLoginRequest(true))
    }
  }

  const loginKeyCloak = () => {
    window.location.replace(`${BaseURL}/login/keycloak/`)
  }

  const getValidationMessage = () => {
    if (apiErrors.find((item) => item.message === 'Access denied: wrong username or password'))
      return 'Неверная комбинация логина и пароля'
    else if (emptyUsernameError) return 'Введите логин'
    else if (emptyPasswordError) return 'Введите пароль'
    else return ''
  }

  return noRedirect ? (
    <div className={'login-page__container'}>
      <div className={'login-page__form'}>
        <Logo />
        <div>
          <div className={'login-page__form-label'}>Логин</div>
          <Input value={username} onChange={onChangeUsername} onEnter={onLoginClick} />
        </div>
        <div>
          <div className={'login-page__form-label'}>Пароль</div>
          <Input value={password} onChange={onChangePassword} type={'password'} onEnter={onLoginClick} />
        </div>
        <div>
          <Button style={{ width: '100%' }} onClick={onLoginClick}>
            Войти
          </Button>
          <div className={'login-page__error-message'}>{getValidationMessage()}</div>
        </div>
        <hr />
        <Button className={'login-page__account-btn'} onClick={loginKeyCloak}>
          Войти через корпоративный аккаунт
        </Button>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default Login
