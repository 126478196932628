import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectMapMode, selectMapSubmode, setMapMode, setMapSubmode } from 'store/mapSlice'
import Button from '../../Button'
import AddPointIcon from 'images/AddPointIcon'
import EditIcon from 'images/EditIcon'
import classnames from 'classnames'
import './modeControl.scss'
export const ModeControl: React.FC = () => {
  const mode = useSelector(selectMapMode)
  const submode = useSelector(selectMapSubmode)
  const dispatch = useDispatch()
  const switchMode = () => {
    mode === 'edit' ? dispatch(setMapMode('view')) : dispatch(setMapMode('edit'))
    dispatch(setMapSubmode('base'))
  }
  return (
    <div className={classnames('mode-control', mode)}>
      <Button id={'mode-control__btn'} className={classnames(mode === 'edit' && 'selected')} onClick={switchMode}>
        <EditIcon id={'edit_icn'} /> Редактирование геометрии
      </Button>
      {mode === 'edit' && (
        <Button
          id={'mode-control__btn'}
          className={classnames(submode === 'add_point' && 'selected')}
          onClick={() => dispatch(setMapSubmode(submode === 'add_point' ? 'base' : 'add_point'))}
        >
          <AddPointIcon />
        </Button>
      )}
    </div>
  )
}
