import React from 'react'
import { LngLat } from 'maplibre-gl'
import './coordsView.scss'
import CursorIcon from 'images/CursorIcon'
import { fourDigitsFormatter } from 'pages/utils'
import { useSelector } from 'react-redux'
import { selectMapMode } from 'store/mapSlice'
import classNames from 'classnames'
interface ICoordsViewProps {
  lngLat?: LngLat
}
export const CoordsView: React.FC<ICoordsViewProps> = ({ lngLat }) => {
  const mode = useSelector(selectMapMode)
  return (
    <div className={classNames('coords-view', mode)}>
      <CursorIcon />
      {lngLat && (
        <div className={'normal-text'}>{`${fourDigitsFormatter.format(lngLat.lat)}; ${fourDigitsFormatter.format(
          lngLat.lng,
        )}`}</div>
      )}
    </div>
  )
}
