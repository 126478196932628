import { definitions } from '../../../../generated/apiTypes'

interface IGPUColumn {
  title: string | JSX.Element
  type: 'Text' | 'Number' | 'Option'
  key: keyof definitions['HandbookGPU']
  editable: boolean
  options?: any[]
  displayedField?: string
  sticky?: boolean
}

export const columns: IGPUColumn[] = [
  {
    title: 'Название',
    type: 'Text',
    key: 'name',
    editable: true,
    sticky: true,
  },
  {
    title: 'Тип ГПА',
    type: 'Option',
    key: 'type',
    editable: true,
    options: [
      'Полнонапорный' as definitions['HandbookGPU']['type'],
      'Одноступенчатый' as definitions['HandbookGPU']['type'],
      'Электроприводный' as definitions['HandbookGPU']['type'],
    ],
    displayedField: '',
  },
  {
    title: 'Номин. мощность, кВт',
    type: 'Number',
    key: 'power__kW',
    editable: true,
  },
  {
    title: 'Мин. привед. объемный расход, м3/мин',
    type: 'Number',
    key: 'min_volume_flow_rate__m3_per_min',
    editable: true,
  },
  {
    title: 'Макс. привед. объемный расход, м3/мин',
    type: 'Number',
    key: 'max_volume_flow_rate__m3_per_min',
    editable: true,
  },
  {
    title: 'Приведенная температура, К',
    type: 'Number',
    key: 'temperature__K',
    editable: true,
  },
  {
    title: 'Газовая постоянная, кгс.м/кг*К',
    type: 'Number',
    key: 'R__kgs_m_per_kg_K',
    editable: true,
  },
  {
    title: 'Коэф. сжимаемости',
    type: 'Number',
    key: 'compressibility_factor',
    editable: true,
  },
  {
    title: 'Макс. степень сжатия при одноступ. сжатии',
    type: 'Number',
    key: 'max_single_stage_compression_ratio',
    editable: true,
  },
  {
    title: 'Макс. степень сжатия при многоступ. сжатии',
    type: 'Number',
    key: 'max_multi_stage_compression_ratio',
    editable: true,
  },
  {
    title: 'Коэф. техн. состояния ГТУ по топливу',
    type: 'Number',
    key: 'fuel_technical_condition_factor',
    editable: true,
  },
  {
    title: 'Температура окружающей среды, К',
    type: 'Number',
    key: 'temperature_environment__K',
    editable: true,
  },
  {
    title: 'КПД электродвигателя',
    type: 'Number',
    key: 'electric_engine_efficiency',
    editable: true,
  },
  {
    title: 'КПД мультипликатора',
    type: 'Number',
    key: 'multiplier_efficiency',
    editable: true,
  },
  {
    title: 'Степ. сжатия от объемной производ. (К1)',
    type: 'Number',
    key: 'compressibility_per_volume_flow_rate_1',
    editable: true,
  },
  {
    title: 'Степ. сжатия от объемной производ. (К2)',
    type: 'Number',
    key: 'compressibility_per_volume_flow_rate_2',
    editable: true,
  },
  {
    title: 'Степ. сжатия от объемной производ. (К3)',
    type: 'Number',
    key: 'compressibility_per_volume_flow_rate_3',
    editable: true,
  },
  {
    title: 'Политроп. КПД (К1)',
    type: 'Number',
    key: 'polytropic_efficiency_1',
    editable: true,
  },
  {
    title: 'Политроп. КПД (К2)',
    type: 'Number',
    key: 'polytropic_efficiency_2',
    editable: true,
  },
  {
    title: 'Политроп. КПД (К3)',
    type: 'Number',
    key: 'polytropic_efficiency_3',
    editable: true,
  },
  {
    title: 'Политроп. КПД (К4)',
    type: 'Number',
    key: 'polytropic_efficiency_4',
    editable: true,
  },
  {
    title: 'Политроп. КПД (К5)',
    type: 'Number',
    key: 'polytropic_efficiency_5',
    editable: true,
  },
  {
    title: 'Внутрен. привед. мощность (К1)',
    type: 'Number',
    key: 'inner_power_1',
    editable: true,
  },
  {
    title: 'Внутрен. привед. мощность (К2)',
    type: 'Number',
    key: 'inner_power_2',
    editable: true,
  },
  {
    title: 'Внутрен. привед. мощность (К3)',
    type: 'Number',
    key: 'inner_power_3',
    editable: true,
  },
  {
    title: 'Внутрен. привед. мощность (К4)',
    type: 'Number',
    key: 'inner_power_4',
    editable: true,
  },
  {
    title: 'Номинальный КПД ГТУ',
    type: 'Number',
    key: 'nominal_efficiency',
    editable: true,
  },
  {
    title: 'Коэф. техн. состояния ГТУ',
    type: 'Number',
    key: 'technical_condition_coefficient',
    editable: true,
  },
  {
    title: 'Коэф. расч. влияния температуры атм. воздуха',
    type: 'Number',
    key: 'air_temperature_influence_coefficient',
    editable: true,
  },
  {
    title: 'Коэф., утилизатора тепла',
    type: 'Number',
    key: 'heat_utilization_coefficient',
    editable: true,
  },
  {
    title: 'Коэф. влияния скор. вращ. ротора силовой турбины',
    type: 'Number',
    key: 'turbine_speed_influence_coefficient',
    editable: true,
  },
  {
    title: 'Механический КПД нагнетателя',
    type: 'Number',
    key: 'blower_mechanical_efficiency',
    editable: true,
  },
]

export const readOnlyColumns = structuredClone(columns).map((column) => {
  column.editable = false
  if (column.type === 'Option') column.type = 'Text'
  return column
})
