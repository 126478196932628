import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectCreateTask, setCreateTask, setTaskId, setTaskStatus } from 'store/hydraulicSlice'
import { createHydraulicTask } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { definitions } from 'generated/apiTypes'
import { resetValidation } from '../../../../../../../store/validationSlice'

const useCreateTask = (settings: definitions['HydroCalculationProperties']) => {
  const dispatch = useDispatch()
  const createTask = useSelector(selectCreateTask)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)

  const tryToCreateTask = async () => {
    if (trunkPipelineId && projectId) {
      dispatch(resetValidation())
      await createHydraulicTask(projectId, trunkPipelineId, {
        power_calculation_precision: settings.power_calculation_precision,
        power_calculation_precision_GPU: settings.power_calculation_precision_GPU,
        pressure_calculation_precision: settings.pressure_calculation_precision,
        outlet_pressure_calculation_precision: settings.outlet_pressure_calculation_precision,
        pressure_range_for_reduction_calculation__MPa: settings.pressure_range_for_reduction_calculation__MPa,
        increment_step_for_Qmax_calculation__Mm3_per_day: settings.increment_step_for_Qmax_calculation__Mm3_per_day,
        year: !settings.max_pipeline_capacity ? settings.year : undefined,
        max_pipeline_capacity: settings.max_pipeline_capacity,
      })
        .then((response) => {
          dispatch(setTaskId(response.data.id))
          dispatch(setTaskStatus('Запущен'))
        })
        .catch((err) => {
          if (err.response.status === 400) {
            if (err.response.data?.length > 0) {
              dispatch(
                setBlockingWindow({
                  objectName: 'Ошибка запуска расчета',
                  message: (
                    <div className='error-window__errors-list-container'>
                      <div>При запуске расчета возникли следующие ошибки:</div>
                      <ul className='error-window__errors-list'>
                        {(err.response.data as string[])?.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))}
                      </ul>
                    </div>
                  ),
                  type: 'ERROR',
                }),
              )
            }
          } else
            dispatch(
              setBlockingWindow({
                objectName: 'Непредвиденная ошибка',
                message: 'Непредвиденная ошибка',
                type: 'ERROR',
              }),
            )
          dispatch(setCreateTask(false))
        })
    }
  }

  React.useEffect(() => {
    if (createTask) tryToCreateTask().then()
  }, [createTask])

  return
}

export default useCreateTask
