import React from 'react'
import { MultiSelectTable } from 'components/Table/MultiSelectTable/MultiSelectTable'
import { useDispatch, useSelector } from 'react-redux'
import {
  addSelectedItem,
  deselectSelectedItemById,
  selectDirectoryItems,
  selectSelectedItems,
  updateItemFieldById,
  setModified,
} from 'store/directorySlice'
import DirectoryWrapper from '../DirectoryWrapper'
import { columns } from './tableConfig'
import { definitions } from 'generated/apiTypes'
import { getHandbookName } from 'pages/MainPage/Directories/directories'
import { generateUID } from 'pages/utils'
import { setBlockingWindow } from 'store/commonSlice'
import './directoryNextGISLayers.scss'
import useGetNextGISLayers from './hooks/useGetNextGISLayers'
import useCreateNextGISLayersVersion from './hooks/useCreateNextGISLayersVersion'
import useGetAreaTypes from 'pages/Directory/hooks/useGetAreaTypes'
import { HandbookNextgisAreaType } from 'store/types'

interface IDirectoryNextGISLayersProps {
  directoryKey: keyof definitions['Handbooks']
}

const DirectoryNextGISLayers: React.FC<IDirectoryNextGISLayersProps> = ({ directoryKey }) => {
  const dispatch = useDispatch()
  const selectedItems = useSelector(selectSelectedItems)
  const directoryItems = useSelector(selectDirectoryItems) as HandbookNextgisAreaType[]
  const [editMode, setEditMode] = React.useState<boolean>(false)
  const [requestData, setRequestData] = React.useState<boolean>(false)
  const [saveData, setSaveData] = React.useState<boolean>()
  const [isModified, setIsModified] = React.useState<boolean>(false)
  const areaTypes = useGetAreaTypes()
  const newItemProperties = {
    area_type: areaTypes[0],
    modified: false,
    created: true,
  }
  const [newItem, setNewItem] = React.useState<HandbookNextgisAreaType>({
    id: '',
    class_id: '',
    ...newItemProperties,
  })

  useGetNextGISLayers(requestData)

  const saveResult = useCreateNextGISLayersVersion(saveData)

  React.useEffect(() => {
    setNewItem({
      id: generateUID(),
      class_id: '',
      ...newItemProperties,
    })
  }, [directoryItems.length])

  React.useEffect(() => {
    if (saveResult) setEditMode(false)
  }, [saveResult])

  const onItemSelect = (id: string) => {
    const itemToAdd = directoryItems?.find((item) => item.id === id)
    if (itemToAdd) dispatch(addSelectedItem(itemToAdd))
  }

  const onItemDeselect = (id: string) => {
    dispatch(deselectSelectedItemById(id))
  }

  const onSaveItemField = async (id: string, value: string | number, fieldName: string) => {
    const uniqueFiled = 'class_id'
    if (
      fieldName === uniqueFiled &&
      (directoryItems as HandbookNextgisAreaType[])?.some((item) => item[uniqueFiled] === value)
    ) {
      dispatch(
        setBlockingWindow({
          type: 'ERROR',
          objectName: 'Ошибка заполнения',
          message: 'Значение поля “Идентификатор в NextGIS” должно быть уникальным',
        }),
      )
      const oldItem = (directoryItems as definitions['HandbookNextgisAreaType'][]).find((item) => item.id === id)
      if (oldItem) {
        await dispatch(updateItemFieldById({ id, value, fieldName }))
        dispatch(updateItemFieldById({ id, value: oldItem[uniqueFiled], fieldName }))
      }
    } else {
      dispatch(updateItemFieldById({ id, value, fieldName }))
      dispatch(setModified(id))
      setIsModified(true)
    }
  }

  const onDiscardChanges = () => {
    setRequestData(!requestData)
    setEditMode(false)
    setIsModified(false)
  }

  const onSaveChanges = () => {
    setSaveData(!saveData)
  }

  return (
    <DirectoryWrapper
      editMode={editMode}
      setEditMode={setEditMode}
      onDiscardChanges={onDiscardChanges}
      onSaveChanges={onSaveChanges}
      title={getHandbookName(directoryKey)}
      isModified={isModified}
      setIsModified={setIsModified}
      newItemToAdd={newItem as definitions['HandbookNextgisAreaType']}
    >
      <div className={'directory__content'}>
        <MultiSelectTable
          selectedItems={selectedItems}
          columns={columns(areaTypes)}
          editMode={editMode}
          dataSource={directoryItems}
          onItemSelect={onItemSelect}
          onItemDeselect={onItemDeselect}
          setValue={onSaveItemField}
          className={'directory-table__container nextgis-layers-table__container'}
        />
      </div>
    </DirectoryWrapper>
  )
}

export default DirectoryNextGISLayers
