import { EditElementPanel } from '../editElementPanel'
import React, { useEffect, useMemo, useState } from 'react'
import { definitions } from 'generated/apiTypes'
import NumberInput from 'components/Input/numberInput'
import { useDispatch, useSelector } from 'react-redux'
import {
  updateSource,
  setUpdatedElement,
  selectProjectID,
  selectLoadingHeightNodeID,
  setLoadingHeightNodeID,
} from 'store/projectSlice'
import {
  createSourceProfileRequest,
  deleteSourceProfileByIDRequest,
  getNodesByProjectIDRequest,
  getSourceByIDRequest,
  updateGISHeight,
  updateSourceByIDRequest,
} from 'services/apiRequests'
import axios from 'axios'
import { generateUID } from 'pages/utils'
import IntegerInput from 'components/Input/integerInput'
import ErrorIcon from 'images/ErrorIcon'
import classNames from 'classnames'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import RedoIcon from 'images/RedoIcon'
import { setMapNodes } from 'store/mapSlice'
import CoordinateInput from 'components/Input/coordinateInput'

interface ISourceEditPanel {
  source: definitions['Source'] | undefined
}
const substanceTranscript = {
  N2: 'азот',
  CH4: 'метан',
  C2H6: 'этан',
  C3H8: 'пропан',
  C4H10_I: 'изо-бутан',
  C4H10_N: 'н-бутан',
  C5H12_I: 'изо-пентан',
  C5H12_N: 'н-пентан',
  C6H14: 'н-гексан',
  C7H16: 'н-гептан',
  C8H18: 'н-октан',
  C9H20: 'н-нонан',
  C10H22: 'н-декан',
  CO2: 'углекислый газ',
  H2S: 'сероводород',
  He: 'гелий',
  O2: 'кислород',
  H2O: 'вода',
}
export const SourceEditPanel: React.FC<ISourceEditPanel> = ({ source }) => {
  const dispatch = useDispatch()
  const [minMaxYear, setMinMaxYear] = useState<any>()
  const projectID = useSelector(selectProjectID)
  const loadingHeightNodeID = useSelector(selectLoadingHeightNodeID)
  const startYearID = generateUID()
  const endYearID = generateUID()
  useEffect(() => {
    if (source?.profiles.length) {
      const min = Math.min(...(source?.profiles.map((profile) => profile.year) || []))
      const max = Math.max(...(source?.profiles.map((profile) => profile.year) || []))
      setMinMaxYear({ min, max })
    }
  }, [source?.profiles.length])
  useEffect(() => {
    if (minMaxYear?.min && minMaxYear?.max) {
      const requests = []
      const profileYears = source?.profiles.map((profile: any) => profile.year)
      const profilesToDelete =
        source?.profiles.filter((profile) => profile.year < minMaxYear.min || profile.year > minMaxYear.max) || []
      if (projectID) {
        requests.push(...profilesToDelete.map((profile) => deleteSourceProfileByIDRequest(projectID, profile.id)))
      }
      const profiles =
        source?.profiles
          .filter(
            (profile: definitions['Profile']) => profile.year <= minMaxYear?.max && profile.year >= minMaxYear?.min,
          )
          .sort((a, b) => a.year - b.year) || []
      if (projectID) {
        for (let year = minMaxYear.min; year <= minMaxYear.max; year++) {
          !profileYears?.includes(year) &&
            requests.push(
              createSourceProfileRequest(projectID, source?.id as string, {
                year,
                gas_flow__mln_m3_per_day: 0,
              }),
            )
        }
        axios.all(requests).then((resArr) => {
          resArr.forEach((res) => res?.data && profiles.push(res.data))
          profiles.length && updateStore({ profiles })
        })
      }
    }
  }, [minMaxYear])
  const updateStore = (updatedField: { [key: string]: any }) => {
    dispatch(
      updateSource({
        ...source,
        ...updatedField,
      } as definitions['Source']),
    )
  }
  const updateCoordinates = (coordinates: [number, number]) => {
    const central_point = {
      ...source?.central_point,
      coordinates,
    }
    updateStore({ central_point })
    dispatch(
      setUpdatedElement({
        elementID: source?.id as string,
        type: 'SOURCE',
        params: { central_point },
      }),
    )
  }
  const setValue = (value: number, label: string) => {
    updateStore({ [label]: value })
    dispatch(
      setUpdatedElement({
        elementID: source?.id as string,
        type: 'SOURCE',
        params: { [label]: value },
      }),
    )
  }

  const setProfileParam = (value: number, profileID: string) => {
    const profileIndex = source?.profiles.findIndex((profile) => profile.id === profileID)
    if (profileIndex === undefined || profileIndex < 0) return
    const profiles = JSON.parse(JSON.stringify(source?.profiles))
    profiles[profileIndex].gas_flow__mln_m3_per_day = value
    updateStore({ profiles })
    dispatch(
      setUpdatedElement({
        elementID: source?.profiles[profileIndex].id as string,
        type: 'SOURCE_PROFILE',
        params: { gas_flow__mln_m3_per_day: value },
      }),
    )
  }
  const setComponentFlowParam = (value: number, label: string) => {
    updateStore({
      components_flow: {
        ...source?.components_flow,
        [label]: value,
      },
    })
    dispatch(
      setUpdatedElement({
        elementID: source?.components_flow.id as string,
        type: 'COMPONENT_FLOW',
        params: { [label]: value },
      }),
    )
  }
  const setHeight = (value: number) => {
    updateStore({ height__m: value })
    if (projectID && source?.id) {
      updateSourceByIDRequest(projectID, source.id, {
        height__m: value,
      }).then(() => {
        getSourceByIDRequest(projectID, source.id).then((res) => {
          dispatch(updateSource(res.data))
        })
        getNodesByProjectIDRequest(projectID).then((res: any) => {
          dispatch(setMapNodes(res.data))
        })
      })
    }
  }
  const setAllowToRequestHeight = async (value: boolean) => {
    updateStore({ allow_to_request_gis_height: value })
    if (projectID && source?.id) {
      if (value) dispatch(setLoadingHeightNodeID(source.id))
      updateSourceByIDRequest(projectID, source.id, {
        allow_to_request_gis_height: value,
      })
        .then(() => {
          getSourceByIDRequest(projectID, source.id)
            .then((res) => {
              dispatch(updateSource(res.data))
              if (value) dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectID).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  const updateHeight = async () => {
    if (projectID && source?.id) {
      dispatch(setLoadingHeightNodeID(source.id))
      updateGISHeight(projectID, source.id)
        .then(() => {
          getSourceByIDRequest(projectID, source.id)
            .then((res) => {
              dispatch(updateSource(res.data))
              dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectID).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  const renderComponentFlowTable = useMemo(() => {
    const components = []
    let sum = 0
    for (const component in source?.components_flow) {
      if (component !== 'id') {
        components.push(
          <div key={`${component}-label`} className={'edit-element-panel__row-label'}>
            {substanceTranscript[component as keyof typeof substanceTranscript]
              ? `${component.split('_')[0]} (${substanceTranscript[component as keyof typeof substanceTranscript]})`
              : component}
          </div>,
          <div key={`${component}-value`} className={'edit-element-panel__cell'}>
            <NumberInput
              value={source?.components_flow[component as keyof definitions['ComponentsFlow']]}
              fieldName={component}
              setValue={setComponentFlowParam}
              noEmpty
            />
          </div>,
        )
        sum += source?.components_flow[component as keyof definitions['ComponentsFlow']] as number
      }
    }
    return { component: <>{components}</>, sum }
  }, [source?.components_flow])

  return (
    <EditElementPanel title={'Начальная точка трубопровода'}>
      <div className={'edit-element-panel__table'}>
        <CoordinateInput
          id={source?.id}
          field={'latitude'}
          label={'lat'}
          value={source?.central_point && (source?.central_point?.coordinates as [number, number])[1]}
          setValue={(value: number) =>
            updateCoordinates([(source?.central_point.coordinates as [number, number])[0], value])
          }
        />
        <CoordinateInput
          id={source?.id}
          field={'longitude'}
          label={'lon'}
          value={source?.central_point && (source?.central_point?.coordinates as [number, number])[0]}
          setValue={(value: number) =>
            updateCoordinates([value, (source?.central_point.coordinates as [number, number])[1]])
          }
        />
        <NumberInput
          value={source?.height__m || 0}
          unit={'м'}
          label={'Высотная отметка'}
          infoText={'Поле с выбранной опцией "Загружать из ГИС" обновляется автоматически при перемещении объекта'}
          warningText={source?.gis_height_error}
          setValue={setHeight}
          signed
          loading={loadingHeightNodeID === source?.id}
          fieldName={'height__m'}
          disabled={source?.allow_to_request_gis_height}
        />
        <div className={'edit-element-panel__height-row'}>
          <Checkbox
            setCheckedValue={setAllowToRequestHeight}
            checkedValue={source?.allow_to_request_gis_height || false}
          >
            Загружать из ГИС
          </Checkbox>
          <div className={'edit-element-panel__redo-btn-container'}>
            {source?.allow_to_request_gis_height && (
              <Button className={'edit-element-panel__redo-btn'} mode={'secondary'} onClick={updateHeight}>
                <RedoIcon />
              </Button>
            )}
          </div>
        </div>
        <div className={'common-row'}>
          <hr />
        </div>
        <NumberInput
          id={source?.id}
          value={source?.calorific_value__kcal_per_m3}
          unit={(<>ккал/м&#179;</>) as any}
          fieldName={'calorific_value__kcal_per_m3'}
          label={'Теплотвоpная способность газа'}
          accuracy={2}
          setValue={setValue}
          signed
        />
        <NumberInput
          id={source?.id}
          value={source?.temperature__C}
          unit={'°C'}
          fieldName={'temperature__C'}
          accuracy={2}
          setValue={setValue}
          label={'Температура газа'}
          signed
        />
        <NumberInput
          id={source?.id}
          value={source?.heat_capacity_ratio}
          fieldName={'heat_capacity_ratio'}
          setValue={setValue}
          label={'Kоэффициент адиабаты'}
          accuracy={2}
          signed
        />
        <NumberInput
          id={source?.id}
          value={source?.pressure__MPa}
          unit={'МПа'}
          fieldName={'pressure__MPa'}
          setValue={setValue}
          label={'Давление'}
          accuracy={2}
          signed
        />
        <div className={'common-row'}>
          <hr />{' '}
        </div>
      </div>
      <div className={'edit-element-panel__subtitle'}>Профиль перекачки</div>
      <div className={'edit-element-panel__table'}>
        <IntegerInput
          id={startYearID}
          fieldName={'start_year'}
          value={minMaxYear && minMaxYear.min}
          setValue={(value) => setMinMaxYear({ ...minMaxYear, min: value <= minMaxYear.max ? value : minMaxYear.max })}
          label={'Год начала'}
          minValue={1900}
          maxValue={minMaxYear && minMaxYear.max}
          noEmpty
        />
        <IntegerInput
          id={endYearID}
          fieldName={'end_year'}
          value={minMaxYear && minMaxYear.max}
          setValue={(value) => setMinMaxYear({ ...minMaxYear, max: value >= minMaxYear.min ? value : minMaxYear.min })}
          label={'Год окончания'}
          minValue={minMaxYear && minMaxYear.min}
          maxValue={2200}
          noEmpty
        />
      </div>
      <div className={'edit-element-panel__table-v2'}>
        <div className={'edit-element-panel__col-label'}>Год</div>
        <div className={'edit-element-panel__col-label'}>Пpоизводительность, млн.куб.м/сутки</div>
        {source?.profiles.map((profile) => {
          return [
            <div key={`${profile.id}-year`} className={'edit-element-panel__row-label'}>
              {profile.year}
            </div>,
            <div key={`${profile.id}-flow`} className={'edit-element-panel__cell'}>
              <NumberInput
                value={profile.gas_flow__mln_m3_per_day}
                fieldName={profile.id}
                setValue={setProfileParam}
                accuracy={3}
              />
            </div>,
          ]
        })}
      </div>
      <hr />
      <div className={'edit-element-panel__subtitle'}>Мольные доли по компонентам</div>
      <div className={'edit-element-panel__table-v3'}>
        <div className={'edit-element-panel__col-label'}>Вещество</div>
        <div className={'edit-element-panel__col-label edit-element-panel__complex-col'}>
          Мольная доля{' '}
          <span
            className={classNames(
              'edit-element-panel__mole-sum',
              parseFloat(renderComponentFlowTable.sum.toFixed(6)) !== 1 && 'error',
            )}
          >
            <span className={classNames('edit-element-panel__mole-sum-symbol')}>Σ</span>=
            {parseFloat(renderComponentFlowTable.sum.toFixed(6))}
            {parseFloat(renderComponentFlowTable.sum.toFixed(6)) !== 1 && <ErrorIcon />}
          </span>
        </div>
        {renderComponentFlowTable.component}
      </div>
    </EditElementPanel>
  )
}
