import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { IOptimizationSlice } from './types'

export const initialState: IOptimizationSlice = {
  taskId: undefined,
  taskSettings: undefined,
  createTask: false,
  getTaskResults: false,
  taskCalculationProgress: undefined,
  taskStatus: undefined,
  errorMessage: undefined,
}

export const optimizationSlice = createSlice({
  name: 'optimization',
  initialState,
  reducers: {
    setTaskId: (state, action: PayloadAction<IOptimizationSlice['taskId']>) => {
      return { ...state, taskId: action.payload }
    },
    setCreateTask: (state, action: PayloadAction<IOptimizationSlice['createTask']>) => {
      return { ...state, createTask: action.payload }
    },
    setTaskSettings: (state, action: PayloadAction<IOptimizationSlice['taskSettings']>) => {
      return { ...state, taskSettings: action.payload }
    },
    setTaskCalculationProgress: (state, action: PayloadAction<IOptimizationSlice['taskCalculationProgress']>) => {
      return { ...state, taskCalculationProgress: action.payload }
    },
    setTaskStatus: (state, action: PayloadAction<IOptimizationSlice['taskStatus']>) => {
      return { ...state, taskStatus: action.payload }
    },
    setGetTaskResults: (state, action: PayloadAction<IOptimizationSlice['getTaskResults']>) => {
      return { ...state, getTaskResults: action.payload }
    },
    setErrorMessage: (state, action: PayloadAction<IOptimizationSlice['errorMessage']>) => {
      return { ...state, errorMessage: action.payload }
    },
    resetOptimizationTaskSettings: (state) => {
      return { ...state, taskSettings: initialState.taskSettings }
    },
    resetOptimizationSliceState: (state) => {
      return {
        ...state,
        taskId: initialState.taskId,
        createTask: initialState.createTask,
        taskCalculationProgress: initialState.taskCalculationProgress,
        taskStatus: initialState.taskStatus,
        getTaskResults: initialState.getTaskResults,
        errorMessage: initialState.errorMessage,
      }
    },
  },
})

export const {
  setCreateTask,
  setTaskSettings,
  setTaskId,
  setTaskCalculationProgress,
  setTaskStatus,
  setGetTaskResults,
  setErrorMessage,
  resetOptimizationTaskSettings,
  resetOptimizationSliceState,
} = optimizationSlice.actions

export const selectCreateTask = (state: RootState) => state.optimization.createTask

export const selectTaskId = (state: RootState) => state.optimization.taskId

export const selectTaskCalculationProgress = (state: RootState) => state.optimization.taskCalculationProgress
export const selectTaskStatus = (state: RootState) => state.optimization.taskStatus
export const selectGetTaskResults = (state: RootState) => state.optimization.getTaskResults
export const selectTaskSettings = (state: RootState) => state.optimization.taskSettings
export const selectErrorMessage = (state: RootState) => state.optimization.errorMessage

export default optimizationSlice.reducer
