import { AnyLayer } from './types'

export const Layers: AnyLayer[] = [
  {
    id: 'base-map-layer',
    metadata: {
      name: 'OSM',
    },
    type: 'raster',
    source: 'base-map-tiles',
    minzoom: 0,
    maxzoom: 20,
    layout: {
      visibility: 'visible',
    },
  },
  {
    id: 'stripped-down-layer',
    metadata: {
      name: 'ИУС ГИС (Урез.)',
    },
    type: 'raster',
    source: 'stripped-down-tiles',
    minzoom: 0,
    maxzoom: 20,
    layout: {
      visibility: 'visible',
    },
  },
  {
    id: 'full-layer',
    metadata: {
      name: 'ИУС ГИС (Полн.)',
    },
    type: 'raster',
    source: 'full-tiles',
    minzoom: 0,
    maxzoom: 20,
    layout: {
      visibility: 'visible',
    },
  },
]
