import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Button from 'components/Button'
import React from 'react'
import './style.scss'
import { IBlockingWindow } from 'store/types'
import Checkbox from '../Checkbox'
import { handleStorage } from 'services/handleStorage'
interface IConfirmWindow {
  blockingWindow: IBlockingWindow
  confirm(): void
  closeWindow(): void
}
export const ConfirmWindow: React.FC<IConfirmWindow> = ({ blockingWindow, confirm, closeWindow }) => {
  const [singleClick, setSingleClick] = React.useState<boolean>(false)
  const [checkboxValue, setCheckboxValue] = React.useState<boolean>(false)
  const titleByObjectType = () => {
    switch (blockingWindow?.objectType) {
      case 'TRUNK_PIPELINE':
        return 'Автоматическое построение трубопровода'
      default:
        return ''
    }
  }

  const onConfirm = () => {
    setSingleClick(true)
    if (checkboxValue) {
      const userId = handleStorage.getUserId()
      if (userId) handleStorage.setOptNotificationShown(userId)
    }
    confirm()
  }

  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container delete-confirm'>
        <div className={'blocking-window__header'}>
          {titleByObjectType()}
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'blocking-window__body'}>{blockingWindow.message}</div>
        {blockingWindow?.objectType === 'TRUNK_PIPELINE' && (
          <Checkbox checkedValue={checkboxValue} setCheckedValue={setCheckboxValue}>
            Больше не показывать
          </Checkbox>
        )}
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Отменить
          </Button>
          <Button onClick={onConfirm} disabled={singleClick}>
            {blockingWindow.objectName}
          </Button>
        </div>
      </div>
    </div>
  )
}
