import { useEffect } from 'react'
import { resetBlockingWindow, selectBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { removeSelectedItems } from 'store/directorySlice'

interface IDeleteItemsProps {
  onDeleteItems(): void
}

const useDeleteItems = ({ onDeleteItems }: IDeleteItemsProps) => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow)

  useEffect(() => {
    if (
      blockingWindow.objectType === 'HANDBOOK_ITEMS' &&
      blockingWindow.type === 'DELETE_CONFIRM' &&
      blockingWindow.isConfirm
    ) {
      dispatch(removeSelectedItems())
      dispatch(resetBlockingWindow())
      onDeleteItems()
    }
  }, [blockingWindow.isConfirm])

  return
}

export default useDeleteItems
