import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectCreateTask, setTaskId, setCreateTask, setTaskStatus } from 'store/optimizationSlice'
import { createOptimizationTask } from 'services/apiRequests'
import { selectProjectID, selectTrunkPipelineID } from 'store/projectSlice'
import { definitions } from 'generated/apiTypes'
import { setBlockingWindow } from 'store/commonSlice'

const useCreateTask = (settings?: definitions['OptimizationCreate']) => {
  const dispatch = useDispatch()
  const createTask = useSelector(selectCreateTask)
  const projectId = useSelector(selectProjectID)
  const trunkPipelineId = useSelector(selectTrunkPipelineID)

  const tryToCreateTask = async () => {
    if (trunkPipelineId && settings && projectId) {
      await createOptimizationTask(projectId, trunkPipelineId, settings)
        .then((response) => {
          dispatch(setTaskId(response.data.id))
          dispatch(setTaskStatus('Запущен'))
        })
        .catch(() => {
          dispatch(
            setBlockingWindow({
              objectName: 'Непредвиденная ошибка',
              message: 'Непредвиденная ошибка',
              type: 'ERROR',
            }),
          )
          dispatch(setCreateTask(false))
        })
    }
  }

  React.useEffect(() => {
    if (createTask) tryToCreateTask().then()
  }, [createTask])

  return
}

export default useCreateTask
