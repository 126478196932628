import React, { useEffect, useState } from 'react'
import { SearchInput } from 'components/SearchInput/searchInput'
import Button from 'components/Button'
import PlusIcon from 'images/PlusIcon'
import { definitions } from 'generated/apiTypes'
import { ProjectCard } from './components/ProjectCard/projectCard'
import { createProjectRequest, getProjectsRequest } from 'services/apiRequests'
import { selectGetProjects, selectProjectsList, setProjectsList, setGetProjects } from 'store/projectsListSlice'
import { setBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useCopyProject from 'components/hooks/useCopyProject'
import Dropdown from 'components/Dropdown/Dropdown'
import { handleStorage } from 'services/handleStorage'

const Projects: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState<string | undefined>()
  const [projectsArr, setProjectsArr] = useState<definitions['Project'][]>()
  const [projectsFilterValue, setProjectsFilterValue] = useState<{ name: 'Мои проекты' | 'Все проекты' }>(
    handleStorage.getShowAllProjects()
      ? { name: 'Все проекты' }
      : {
          name: 'Мои проекты',
        },
  )
  const projectsList = useSelector(selectProjectsList)
  const getProjects = useSelector(selectGetProjects)
  const [copyProjectID, setCopyProjectID] = useState<string | undefined>()
  const pattern = /(\d{2})-(\d{2})-(\d{4})/

  useCopyProject(copyProjectID)
  const fetchProjects = () => {
    getProjectsRequest().then((res: any) => {
      dispatch(
        setProjectsList(
          res.data.map((item: definitions['Project']) => {
            return {
              ...item,
              updated: item.updated.replace(pattern, '$3-$2-$1'),
            }
          }),
        ),
      )
      dispatch(setGetProjects(false))
    })
  }
  const createProject = () => {
    createProjectRequest({
      name: `Проект ${
        Math.max(
          ...projectsList.map((item) =>
            /Проект\s+\d+$/.test(item.name) ? Number(item.name.replace(/Проект\s+/, '')) : 0,
          ),
        ) + 1
      }`,
    }).then((res: any) => {
      fetchProjects()
      res.data && openProject(res.data.id)
    })
  }
  const openProject = (projectID: string) => {
    navigate(`/project/${projectID}`)
    // getProjectByIDRequest(projectID).then((res: any) => dispatch(setProject(res.data)))
  }
  const deleteProject = (projectID: string, projectName: string) => {
    dispatch(
      setBlockingWindow({
        objectID: projectID,
        objectType: 'PROJECT',
        objectName: projectName,
        type: 'DELETE_CONFIRM',
      }),
    )
    // deleteProjectByIDRequest(projectID).then(dispatch(setProjectsList(projectsList.filter((projectMeta: any)=> projectMeta.id!==projectID))))
  }

  useEffect(() => {
    let arr = [...projectsList]
    if (searchValue) {
      arr = projectsList.filter(
        (projectMeta: any) =>
          projectMeta.id.toLowerCase().includes(searchValue) ||
          projectMeta.name.toLowerCase().includes(searchValue.toLowerCase()),
      )
    }
    if (projectsFilterValue.name === 'Мои проекты') {
      arr = arr.filter((projectMeta: any) => projectMeta.author?.id.toString() === handleStorage.getUserId())
    }
    setProjectsArr(arr)
  }, [searchValue, projectsFilterValue.name, projectsList])

  useEffect(() => {
    if (getProjects || !projectsList.length) fetchProjects()
  }, [])

  const onProjectsFilterChanged = (value: { name: 'Мои проекты' | 'Все проекты' }) => {
    setProjectsFilterValue(value)
    if (value.name === 'Все проекты') {
      handleStorage.setShowAllProjects()
    } else {
      handleStorage.removeShowAllProjects()
    }
  }

  return (
    <div className={'main-page__container'}>
      <div className={'main-page-content__container'}>
        <div className={'project-list__projects-header'}>
          <div className={'project-list__my-projects-wrapper'}>
            <h1 className={'project-list__projects-title'}>Проекты</h1>
            <Dropdown
              options={[{ name: 'Мои проекты' }, { name: 'Все проекты' }]}
              displayedField={'name'}
              selectedValue={projectsFilterValue}
              setSelectedValue={onProjectsFilterChanged}
            />
          </div>
          <div className={'project-list__projects-btn'}>
            <SearchInput setValue={setSearchValue} placeholder={'Название проекта или id...'} />
            <Button className={'with-icon'} onClick={createProject}>
              <PlusIcon />
              Создать проект
            </Button>
          </div>
        </div>
        <div className={'project-list__projects-body'}>
          {projectsList.length > 0 ? (
            projectsArr && projectsArr?.length > 0 ? (
              projectsArr.map((projectMeta: definitions['Project']) => (
                <ProjectCard
                  key={projectMeta.id}
                  id={projectMeta.id}
                  title={projectMeta.name}
                  owner={`${projectMeta.author?.first_name || ''} ${projectMeta.author?.last_name || ''}`}
                  date={projectMeta.updated}
                  deleteProject={deleteProject}
                  copyProject={setCopyProjectID}
                  onClick={() => openProject(projectMeta.id)}
                />
              ))
            ) : (
              <span className={'project-list__loading-message normal-text'}>
                По заданным условиям поиска проекты не найдены
              </span>
            )
          ) : (
            <span className={'project-list__loading-message normal-text'}>Подождите...</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default Projects
