import { EditElementPanel } from '../editElementPanel'
import NumberInput from 'components/Input/numberInput'
import React from 'react'
import TextInput from 'components/Input/textInput'
import { definitions } from 'generated/apiTypes'
import { getCoords } from '@turf/turf'
import {
  selectLoadingHeightNodeID,
  selectProjectID,
  setLoadingHeightNodeID,
  setUpdatedElement,
  updateReferenceNode,
} from 'store/projectSlice'
import { useDispatch, useSelector } from 'react-redux'
import { setBlockingWindow } from 'store/commonSlice'
import CoordinateInput from 'components/Input/coordinateInput'
import Checkbox from 'components/Checkbox'
import Button from 'components/Button'
import RedoIcon from 'images/RedoIcon'
import {
  getNodesByProjectIDRequest,
  getReferenceNodeByIDRequest,
  updateGISHeight,
  updateReferenceNodeByIDRequest,
} from 'services/apiRequests'
import { setMapNodes } from 'store/mapSlice'

interface IReferenceNodeEditPanel {
  object?: definitions['ReferenceNode']
}

export const ReferenceNodeEditPanel: React.FC<IReferenceNodeEditPanel> = ({ object }) => {
  const dispatch = useDispatch()
  const projectId = useSelector(selectProjectID)
  const loadingHeightNodeID = useSelector(selectLoadingHeightNodeID)
  const updateStore = (updatedField: { [key: string]: any }) => {
    dispatch(
      updateReferenceNode({
        ...object,
        ...updatedField,
      } as definitions['ReferenceNode']),
    )
  }
  const setValue = (value: any, label: string) => {
    let updatedValue
    if (label === 'central_point') {
      updatedValue = {
        ...object?.central_point,
        coordinates: value,
      }
    } else updatedValue = value
    updateStore({ [label]: updatedValue })
    dispatch(
      setUpdatedElement({
        elementID: object?.id as string,
        type: 'REFERENCE_NODES',
        params: { [label]: updatedValue },
      }),
    )
  }
  const deleteObj = () => {
    dispatch(
      setBlockingWindow({
        objectID: object?.id,
        objectType: 'REFERENCE_NODES',
        type: 'DELETE_CONFIRM',
        objectName: object?.name,
      }),
    )
  }
  const setHeight = (value: number) => {
    updateStore({ height__m: value })
    if (projectId && object?.id) {
      updateReferenceNodeByIDRequest(projectId, object.id, {
        height__m: value,
      }).then(() => {
        getReferenceNodeByIDRequest(projectId, object.id).then((res) => {
          dispatch(updateReferenceNode(res.data))
        })
        getNodesByProjectIDRequest(projectId).then((res: any) => {
          dispatch(setMapNodes(res.data))
        })
      })
    }
  }
  const setAllowToRequestHeight = async (value: boolean) => {
    updateStore({ allow_to_request_gis_height: value })
    if (projectId && object?.id) {
      if (value) dispatch(setLoadingHeightNodeID(object.id))
      updateReferenceNodeByIDRequest(projectId, object.id, {
        allow_to_request_gis_height: value,
      })
        .then(() => {
          getReferenceNodeByIDRequest(projectId, object.id)
            .then((res) => {
              dispatch(updateReferenceNode(res.data))
              if (value) dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectId).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  const updateHeight = async () => {
    if (projectId && object?.id) {
      dispatch(setLoadingHeightNodeID(object.id))
      updateGISHeight(projectId, object.id)
        .then(() => {
          getReferenceNodeByIDRequest(projectId, object.id)
            .then((res) => {
              dispatch(updateReferenceNode(res.data))
              dispatch(setLoadingHeightNodeID(null))
            })
            .catch(() => dispatch(setLoadingHeightNodeID(null)))
          getNodesByProjectIDRequest(projectId).then((res: any) => {
            dispatch(setMapNodes(res.data))
          })
        })
        .catch(() => dispatch(setLoadingHeightNodeID(null)))
    }
  }
  return (
    <EditElementPanel title={'Опорный узел'} deleteAllow deleteObj={deleteObj}>
      <div className={'edit-element-panel__table'}>
        <div className={'common-row'}>
          <TextInput value={object?.name || ''} fieldName={'name'} setValue={setValue} label={'Название'} />
        </div>
        <CoordinateInput
          id={object?.id}
          key={`${object?.id}_latitude`}
          field={'latitude'}
          label={'lat'}
          value={object?.central_point ? getCoords(object?.central_point as any)[1] : ''}
          setValue={(value) => setValue([getCoords(object?.central_point as any)[0], value], 'central_point')}
        />
        <CoordinateInput
          id={object?.id}
          key={`${object?.id}_longitude`}
          field={'longitude'}
          label={'lon'}
          value={object?.central_point ? getCoords(object?.central_point as any)[0] : ''}
          setValue={(value, label: string) =>
            setValue([value, getCoords(object?.central_point as any)[1]], 'central_point')
          }
        />
        <NumberInput
          unit={'м'}
          value={object?.height__m || 0}
          label={'Высотная отметка'}
          fieldName={'height__m'}
          setValue={setHeight}
          loading={loadingHeightNodeID === object?.id}
          signed
          infoText={'Поле с выбранной опцией "Загружать из ГИС" обновляется автоматически при перемещении объекта'}
          warningText={object?.gis_height_error}
          disabled={object?.allow_to_request_gis_height}
        />
        <div className={'edit-element-panel__height-row'}>
          <Checkbox
            setCheckedValue={setAllowToRequestHeight}
            checkedValue={object?.allow_to_request_gis_height || false}
          >
            Загружать из ГИС
          </Checkbox>
          <div className={'edit-element-panel__redo-btn-container'}>
            {object?.allow_to_request_gis_height && (
              <Button className={'edit-element-panel__redo-btn'} mode={'secondary'} onClick={updateHeight}>
                <RedoIcon />
              </Button>
            )}
          </div>
        </div>
      </div>
    </EditElementPanel>
  )
}
