import React, { useEffect } from 'react'
import { ITableHeaderCell } from './types'
import classNames from 'classnames'
export const TableHeaderCell: React.FC<ITableHeaderCell> = ({ title, scrollTop, className }) => {
  const [offsetTop, setOffsetTop] = React.useState<number>()
  const cellRef = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollTop === 0) setOffsetTop(cellRef?.current?.offsetTop)
  }, [cellRef?.current?.offsetTop])

  useEffect(() => {
    if (scrollTop === 0) {
      setOffsetTop(0)
    }
  }, [scrollTop])

  useEffect(() => {
    const setTop = () => {
      if (scrollTop === 0) setOffsetTop(0)
    }
    window.addEventListener('resize', setTop)
    return () => {
      window.removeEventListener('resize', setTop)
    }
  }, [scrollTop])

  React.useEffect(() => {
    if (offsetTop === 0) {
      setOffsetTop(cellRef?.current?.offsetTop)
    }
  }, [offsetTop])

  return (
    <div ref={cellRef} style={{ top: offsetTop }} className={classNames('grid-table__header', className)}>
      {title}
    </div>
  )
}
