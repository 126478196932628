import { definitions } from 'generated/apiTypes'
import { SourceSpecification } from 'maplibre-gl'

export const Sources = (layers: definitions['GISConfiguration']): { [p: string]: SourceSpecification } => {
  return {
    'base-map-tiles': {
      type: 'raster',
      tiles: [layers.map_layer_osm_url],
      tileSize: 256,
    },
    'stripped-down-tiles': {
      type: 'raster',
      tiles: [layers.map_layer_stripped_down_url],
      tileSize: 256,
    },
    'full-tiles': {
      type: 'raster',
      tiles: [layers.map_layer_full_url],
      tileSize: 256,
    },
  }
}
