import { definitions } from 'generated/apiTypes'
import React, { useEffect } from 'react'
import { EditElementPanel } from '../editElementPanel'
import NumberInput from 'components/Input/numberInput'
import PlusIcon from 'images/PlusIcon'
import Button from 'components/Button'
import './pipelineEditPanel.scss'
import { DeleteButton } from 'components/DeleteButton/DeleteButton'
import { FilterButton } from '../../filterButton'
import { resetBlockingWindow, setBlockingWindow } from 'store/commonSlice'
import { connect, useDispatch } from 'react-redux'
import { setUpdatedElement, updatePipeline } from 'store/projectSlice'
import { createPipeRequest, deletePipeByIDRequest } from 'services/apiRequests'
import { IBlockingWindow, IUpdatedElement } from 'store/types'
import update from 'immutability-helper'
import TextInput from 'components/Input/textInput'
import IntegerInput from 'components/Input/integerInput'
interface IPipelineEditPanel {
  pipeline?: definitions['DetailedPipeline']
  projectID: string
  pipeTypes: definitions['PipeType'][]
  updatedElement: IUpdatedElement
  blockingWindow: IBlockingWindow
}
const PipelineEditPanel: React.FC<IPipelineEditPanel> = ({
  pipeline,
  projectID,
  pipeTypes,
  updatedElement,
  blockingWindow,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (updatedElement.type === 'PIPE' && Object.keys(updatedElement.params!).includes('pipe_type_id')) {
      const pipeIndex = pipeline?.pipes.findIndex((pipe) => pipe.id === updatedElement.elementID) as number
      const pipes = structuredClone(pipeline?.pipes) as definitions['Pipe'][]
      pipes[pipeIndex] = {
        ...pipeline?.pipes[pipeIndex],
        pipe_type: pipeTypes.find(
          (pipe) => pipe.id === (updatedElement.params! as definitions['PipeUpdate'])['pipe_type_id'],
        ) as definitions['PipeType'],
      } as definitions['Pipe']
      dispatch(
        updatePipeline({
          ...(pipeline as definitions['DetailedPipeline']),
          pipes,
        }),
      )
    }
  }, [updatedElement])
  useEffect(() => {
    if (blockingWindow.objectType === 'PIPE' && blockingWindow.type === 'DELETE_CONFIRM' && blockingWindow.isConfirm) {
      deletePipeByIDRequest(projectID, blockingWindow.objectID as string).then(() => {
        dispatch(
          updatePipeline({
            ...(pipeline as definitions['DetailedPipeline']),
            pipes: pipeline?.pipes.filter((pipe) => pipe.id !== blockingWindow.objectID) as definitions['Pipe'][],
          }),
        )
        dispatch(resetBlockingWindow())
      })
    }
  }, [blockingWindow])
  const createPipe = () => {
    createPipeRequest(projectID, pipeline?.id as string, {
      number_of_pipes: 1,
      pipe_type_id: pipeTypes[0].id,
    }).then((res) => {
      dispatch(
        updatePipeline({
          ...pipeline,
          pipes: [res.data, ...(pipeline?.pipes || [])],
        } as definitions['DetailedPipeline']),
      )
    })
  }
  const setPipeValue = (pipeID: string, value: number, fieldName: string) => {
    const pipeIndex = pipeline?.pipes.findIndex((pipe) => pipe.id === pipeID) as number
    dispatch(
      updatePipeline({
        ...pipeline,
        pipes: update(pipeline?.pipes, {
          [pipeIndex]: {
            $set: {
              ...pipeline?.pipes[pipeIndex],
              [fieldName]: value,
            } as definitions['Pipe'],
          },
        }) as definitions['Pipe'][],
      } as definitions['DetailedPipeline']),
    )
    dispatch(
      setUpdatedElement({
        elementID: pipeID,
        type: 'PIPE',
        params: { [fieldName]: value },
      }),
    )
  }
  const setPipelineValue = (value: any, fieldName: string) => {
    dispatch(
      updatePipeline({
        ...pipeline,
        [fieldName]: value,
      } as definitions['DetailedPipeline']),
    )
    dispatch(
      setUpdatedElement({
        elementID: pipeline?.id as string,
        type: 'PIPELINE',
        params: { [fieldName]: value },
      }),
    )
  }
  const renderPipeFrame = (index: number, pipe: definitions['Pipe']) => {
    const pipesNum = pipeline?.pipes ? pipeline?.pipes.length : 0
    return [
      <div key={pipe.id} className={'pipe-frame'}>
        <div className={'marker-index header4'}>{index + 1}</div>
        <div key={`${pipe.id}-num-pipes`} className={'common-row'}>
          <IntegerInput
            id={pipe.id}
            key={`${pipe.id}-number_of_pipes`}
            label={'Кол-во нитей'}
            value={pipe.number_of_pipes}
            fieldName={'number_of_pipes'}
            setValue={(value, fieldName) => {
              setPipeValue(pipe.id, value, fieldName as string)
            }}
            noEmpty
          />
        </div>
        {pipesNum > 1 ? (
          <DeleteButton
            onClick={() => {
              dispatch(
                setBlockingWindow({
                  objectID: pipe.id,
                  objectType: 'PIPE',
                  type: 'DELETE_CONFIRM',
                }),
              )
            }}
          />
        ) : (
          <div></div>
        )}
        <div className={'common-row'}>
          <hr />
        </div>
        <h4>Параметры трубы</h4>
        <div className={'pipe-type-filter'}>
          {' '}
          <FilterButton
            onClick={() => {
              dispatch(
                setBlockingWindow({
                  objectID: pipe.id,
                  objectType: 'PIPE',
                  objectName: pipe.pipe_type.id,
                  type: 'EDIT_PIPE_TYPE',
                  subID: pipe.pipe_type.id,
                }),
              )
            }}
          />
        </div>
        <div className={'left-column'}>
          <NumberInput label={'Внешний диаметр'} unit={'мм'} value={pipe.pipe_type.diameter__mm} disabled />
        </div>
        <div className={'right-column'}>
          <NumberInput label={'Толщина стенки'} unit={'мм'} value={pipe.pipe_type.thickness__mm} disabled />
        </div>
        <div className={'left-column'}>
          <NumberInput label={'Шероховатость'} unit={'мм'} value={pipe.pipe_type.roughness__mm} disabled />
        </div>
        <div className={'right-column'}>
          <TextInput label={'Марка стали'} value={pipe.pipe_type.steel_name} disabled />
        </div>
      </div>,
      <hr key={`${pipe.id}-hr`} />,
    ]
  }
  return (
    <EditElementPanel title={`${pipeline?.start_node.name || ''} - ${pipeline?.end_node.name || ''}`}>
      <div className={'edit-element-panel__table'}>
        <NumberInput label={'Длина'} unit={'км'} value={(pipeline?.length__m! * 0.001).toFixed(2)} disabled />
        <NumberInput
          id={pipeline?.id}
          key={`${pipeline?.id}-gas_inflow__mn_m3_per_day`}
          label={'Приток/отбор газа в начале участка'}
          unit={'млн.куб.м/сутки'}
          value={pipeline?.gas_inflow__mn_m3_per_day}
          fieldName={'gas_inflow__mn_m3_per_day'}
          infoText={'Приток/отбор газа в начале участка (отбор со знаком минус)'}
          signed
          setValue={setPipelineValue}
          noEmpty
        />
        <NumberInput
          id={pipeline?.id}
          key={`${pipeline?.id}-hydraulic_efficiency`}
          label={'К. гидравл. эффективности'}
          value={pipeline?.hydraulic_efficiency}
          fieldName={'hydraulic_efficiency'}
          setValue={setPipelineValue}
          signed
        />
        <NumberInput
          id={pipeline?.id}
          key={`${pipeline?.id}-heat_transfer__w_per_m2_per_k`}
          label={'К. теплопередачи от газа в грунт'}
          unit={
            <>
              Вт/м<sup>2</sup>К
            </>
          }
          infoText={'Коэффициент теплопередачи от газа в грунт'}
          value={pipeline?.heat_transfer__w_per_m2_per_k}
          fieldName={'heat_transfer__w_per_m2_per_k'}
          setValue={setPipelineValue}
        />
        <NumberInput
          id={pipeline?.id}
          key={`${pipeline?.id}-temperature__C`}
          label={'Темп. грунта на оси залож.'}
          unit={'°C'}
          value={pipeline?.temperature__C}
          fieldName={'temperature__C'}
          setValue={setPipelineValue}
          signed
        />
        <NumberInput label={'Изменение высоты'} unit={'м'} value={pipeline?.height__m} disabled />
      </div>
      <div className={'edit-element-panel__subtitle'}>
        Нити
        <Button className={'square-btn-28'} mode={'secondary'} onClick={createPipe}>
          <PlusIcon />
        </Button>
      </div>
      <div className={'pipe-frame__list'}>{pipeline?.pipes.map((pipe, index) => renderPipeFrame(index, pipe))}</div>
    </EditElementPanel>
  )
}

const mapStateToProps = (state: any) => ({
  projectID: state.project.detail.id,
  pipeTypes: state.project.pipeTypes,
  updatedElement: state.project.updatedElement,
  blockingWindow: state.common.blockingWindow,
})
export default connect(mapStateToProps)(PipelineEditPanel)
