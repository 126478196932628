import { useEffect } from 'react'
import { elementType } from 'store/types'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'store/configureStore'
import { selectUpdatedElement, setUpdatedElement, updatePipeline } from 'store/projectSlice'
import { setMapPipelines } from 'store/mapSlice'
import { length } from '@turf/turf'
export interface IEditLineProps {
  objectType?: elementType | null
  objectId?: string | null
  objectName?: string | null
  params?: any
  index?: number
  lineID?: number
}
const useEditLine = () => {
  const mapLines = useSelector((store: RootState) => store.map.pipelines)
  const pipelines = useSelector((store: RootState) => store.project.pipelines)
  const updatedElement = useSelector(selectUpdatedElement)
  const dispatch = useDispatch()
  const updatePolyline = () => {
    const { central_point } = updatedElement?.params as { central_point: any }
    central_point &&
      dispatch(
        setUpdatedElement({
          elementID: updatedElement.elementID,
          type: 'PIPELINE',
          params: { line: central_point } as { [key: string]: unknown },
        }),
      )
    const pipelineIndex = mapLines.findIndex((pipeline) => pipeline.id === updatedElement.elementID)
    if (pipelineIndex > -1) {
      dispatch(
        setMapPipelines([
          ...mapLines.slice(0, pipelineIndex),
          { ...mapLines[pipelineIndex], line: central_point },
          ...mapLines.slice(pipelineIndex + 1),
        ]),
      )
    }
    const pipelineInStore = pipelines.find((item) => item.id === updatedElement.elementID)
    if (pipelineInStore) {
      dispatch(
        updatePipeline({
          ...pipelineInStore,
          length__m: length(central_point) * 1000,
        }),
      )
    }
  }
  useEffect(() => {
    updatedElement?.type === 'POINTS' && updatePolyline()
  }, [updatedElement])
  return
}

export default useEditLine
