import AlertIcon from 'images/AlertIcon'
import React, { useEffect, useRef, useState } from 'react'
import './messageBox.scss'
import classnames from 'classnames'
interface IMessageBox {
  message: string
}
export const MessageBox: React.FC<IMessageBox> = ({ message }) => {
  const boxRef = useRef<any>(null)
  const messageRef = useRef<any>(null)
  const [showAll, setShowAll] = useState(false)
  const [overflow, setOverflow] = useState(false)
  useEffect(() => {
    boxRef.current &&
      messageRef.current &&
      messageRef.current.scrollWidth > boxRef.current.clientWidth &&
      setOverflow(true)
  }, [])
  return (
    <div className={'message-box normal-text'} ref={boxRef}>
      <AlertIcon />
      <div className={classnames('error-message', { showAll })} ref={messageRef}>
        {message}
        {overflow && (
          <div className={'show-all__btn'} onClick={() => setShowAll(!showAll)}>
            {!showAll ? 'Показать полностью' : 'Скрыть'}
          </div>
        )}
      </div>
    </div>
  )
}
