import { LineLayer } from './LineLayer'
import { bbox, booleanEqual, feature, getCoords, multiLineString, bboxPolygon, featureCollection } from '@turf/turf'
import { Layer } from './Layer'
import { AddNode } from './AddNode'
import { defaultZoomDelta, MapContext } from './map'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetMapZoom,
  selectMap,
  setMapZoom,
  setToMapCenter,
  selectNodeCreation,
  resetMapMode,
  resetMapSubmode,
  resetNodeCreation,
  setNodeCreation,
  setSelectedGroup,
} from 'store/mapSlice'
import { Feature } from 'geojson'
import {
  addPointToLineString,
  circleHoverLayerProps,
  circleWarnLayerProps,
  getIconName,
  getIconSize,
  getRotation,
  getSourceData,
  getSourceType,
  layerProps,
  lineHoverLayerProps,
  lineLayerProps,
  lineLayoutProps,
  lineSelectedLayerProps,
  lineTransparentLayerProps,
  selectAreaLayerProps,
  lineBaseLayerProps,
  PointsMinZoom,
  circleTransparentLayerProps,
  generateUID,
} from './utils'
import { definitions } from 'generated/apiTypes'
import { selectShowElementInfo, setShowElementInfo, setUpdatedElement, resetShowElementInfo } from 'store/projectSlice'
import { point, lineString, segmentReduce, getGeom } from '@turf/turf'
import { AnyLayer, MapEvent } from './types'
import { elementType, MapElementType } from 'store/types'
import { GeoJSONSource, PointLike, LngLat, MapGeoJSONFeature, FilterSpecification } from 'maplibre-gl'
import useUpdateCoords, { IUpdateCoordsProps } from './hooks/useUpdateCoords'
import { ControlButtons } from './ControlButtons'
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson'
import { CoordsView } from './CoordsView/coordsView'
import useDeleteObject from './hooks/useDeleteObject'
import { LayerControl } from './LayerControl/layerControl'
import { ModeControl } from './ModeControl/modeControl'
import useSwitchMode from './hooks/useSwitchMode'
import useEditLine from './hooks/useEditLine'
import useGetCurrentRef from './hooks/useGetCurrentRef'

interface DragFeature extends MapGeoJSONFeature {
  warn?: boolean
  drag?: boolean
}
export const ProjectMap = () => {
  const currentMap = useSelector(selectMap)
  const mapContext = useContext(MapContext)
  const dispatch = useDispatch()
  const elementInfo = useSelector(selectShowElementInfo)
  const [updatedCoords, setUpdatedCoords] = useState<IUpdateCoordsProps>()
  const [cursorCoords, setCursorCoords] = useState<LngLat>()
  const [pointIndex, setPointIndex] = useState<number>()
  const [zoom, setZoom] = useState<number>(0)
  const [lineID, setLineID] = useState<string>()
  const nodeCreation = useSelector(selectNodeCreation)
  const [selectArea, setSelectArea] = useState<number[]>([])
  const rules = useRef({
    select: false,
    selectLine: false,
    selectGroup: false,
    editLine: false,
    points: false,
    segment: false,
  })
  useEditLine()
  useUpdateCoords(updatedCoords)
  useSwitchMode({
    mode: currentMap.mode,
    submode: currentMap.submode,
    rules: rules,
  })
  const deleteObject = useDeleteObject({ ...elementInfo, index: pointIndex, lineID })
  useEffect(() => {
    dispatch(
      setNodeCreation({
        coordinates: undefined,
      }),
    )
    resetFilters(['points-hover__layer'])
  }, [deleteObject])
  const referenceNodes = useMemo(
    () =>
      currentMap.nodes?.reference_nodes.map((node) =>
        feature(node.central_point, { id: node.node_id, type: 'LOGIC', zIndex: 2 }),
      ) || [],
    [currentMap.nodes?.reference_nodes],
  )
  const [pipelines, segments] = useMemo(() => {
    const [_pipelines, _segments]: [Feature[], Feature[]] = [[], []]
    currentMap.pipelines.forEach((pipeline: any) => {
      _pipelines.push(
        feature(pipeline.line, {
          id: pipeline.id,
          start_node: pipeline.start_node_id,
          end_node: pipeline.end_node_id,
          zIndex: 0,
        }),
      )
      _segments.push(
        ...segmentReduce(
          pipeline.line,
          (prev: any[] | undefined, curr) => {
            prev &&
              curr &&
              prev.push(
                feature(getGeom(curr), {
                  id: `${pipeline.id}s${prev.length}`,
                  lineId: pipeline.id,
                  index: prev.length,
                  zIndex: 1,
                }),
              )
            return prev || []
          },
          [],
        ),
      )
    })
    return [_pipelines, _segments]
  }, [currentMap.pipelines])
  const points = useMemo(() => {
    const items: any[] = []
    currentMap.pipelines.forEach((pipeline: any) =>
      items.push(
        ...getCoords(pipeline.line)
          .slice(1, -1)
          .map((item, index) =>
            point(item, {
              id: `${pipeline.id}${index + 1}`,
              lineId: pipeline.id,
              type: 'POINT',
              index: index + 1,
              zIndex: 2,
            }),
          ),
      ),
    )
    return items
  }, [currentMap.pipelines])
  const source = useMemo(
    () =>
      currentMap.nodes?.source
        ? [
            feature(currentMap.nodes?.source.central_point, {
              id: currentMap.nodes?.source.node_id,
              type: 'LOGIC',
              rotation: getRotation(currentMap.nodes?.source as definitions['Node'], currentMap.pipelines),
              zIndex: 2,
            }),
          ]
        : [],
    [currentMap.nodes?.source, currentMap?.pipelines],
  )
  const sink = useMemo(
    () =>
      currentMap.nodes?.sink
        ? [
            feature(currentMap.nodes?.sink.central_point, {
              id: currentMap.nodes?.sink.node_id,
              type: 'LOGIC',
              rotation: getRotation(currentMap.nodes?.sink as definitions['Node'], currentMap.pipelines),
              zIndex: 2,
            }),
          ]
        : [],
    [currentMap.nodes?.sink, currentMap?.pipelines],
  )
  const compressorStations = useMemo(
    () =>
      currentMap.nodes?.compressor_stations.map((node) =>
        feature(node.central_point, { id: node.node_id, type: 'LOGIC', zIndex: 2 }),
      ) || [],
    [currentMap.nodes?.compressor_stations],
  )

  const reductionNodes = useMemo(
    () =>
      currentMap.nodes?.nodes_reduction.map((node) =>
        feature(node.central_point, { id: node.node_id, type: 'LOGIC' }),
      ) || [],
    [currentMap.nodes?.nodes_reduction],
  )
  const ref = useGetCurrentRef(nodeCreation, pipelines, currentMap.mode, currentMap.selectedGroup, selectArea)

  useEffect(() => {
    if (mapContext?.getLayer(`${ref.current.selectedType}-selected__layer`)) {
      mapContext?.setFilter(`${ref.current.selectedType}-selected__layer`, ['==', 'id', ''])
    }
    ref.current.selectedType = elementInfo.objectType
      ? (elementInfo.objectType.toLowerCase() as MapElementType)
      : undefined
    if (elementInfo.objectType && mapContext?.getLayer(`${elementInfo.objectType.toLowerCase()}-selected__layer`)) {
      mapContext?.setFilter(`${elementInfo.objectType.toLowerCase()}-selected__layer`, [
        '==',
        'id',
        elementInfo.objectId || '',
      ])
    }
  }, [elementInfo])

  useEffect(() => {
    for (const type of [
      'source',
      'sink',
      'reference_nodes',
      'compressor_stations',
      'nodes_reductions',
      'points',
      'segment',
    ]) {
      if (mapContext?.getLayer(`${type}-selected__layer`)) {
        mapContext?.setFilter(`${type}-selected__layer`, [
          'in',
          'id',
          ...currentMap.selectedGroup.filter((i) => i.type.toLowerCase() === type).map((i) => i.id),
        ])
      }
    }
  }, [currentMap.selectedGroup])

  useEffect(() => {
    document.onkeydown = (event) => {
      if (event.code === 'Escape') {
        if (elementInfo.objectType) {
          dispatch(resetShowElementInfo())
        } else {
          ref.current.selectedGroup.length
            ? dispatch(setSelectedGroup([]))
            : currentMap.submode !== 'base'
            ? dispatch(resetMapSubmode())
            : dispatch(resetMapMode())
          dispatch(resetNodeCreation())
        }
      }
      if (event.key === 'Shift') {
        ref.current.onShift = true
      }
    }
    document.onkeyup = (event) => {
      if (event.key === 'Shift') {
        ref.current.onShift = false
        ref.current.selectArea && drawSelectAreaFinish()
      }
    }
  }, [elementInfo, currentMap.mode, currentMap.submode])

  const resetFilters = (layers: string[]) => {
    setFilters(layers, ['==', 'id', ''])
  }
  const setFilters = (layers: string[], filter: FilterSpecification) => {
    layers.forEach((layerId: string) => {
      mapContext?.setFilter(layerId, filter)
    })
  }
  const project = (lnglat: LngLat) => {
    return mapContext?.project(lnglat)
  }
  const handleMouseMove = (event: MapEvent) => {
    setCursorCoords(event.lngLat)
    if (ref.current.dragFeatures.length > 0) return
    const feature = mapContext
      ?.queryRenderedFeatures(event.point)
      .sort((a, b) => b.properties.zIndex - a.properties.zIndex)
      .find((feat) => !['addNode', 'select-area__source'].includes(feat.layer.source))
    ref.current.hoverType && mapContext?.setFilter(`${ref.current.hoverType}-hover__layer`, ['==', 'id', ''])
    mapContext.getCanvas().style.cursor = ''
    if (feature) {
      ref.current.hoverType = getSourceType(feature.layer as AnyLayer) as MapElementType
      mapContext?.setFilter(`${ref.current.hoverType}-hover__layer`, ['==', 'id', feature.properties.id])
    }
  }
  const updateNodeCoords = (id: string, lngLat: LngLat, sourceId: string) => {
    const source = mapContext?.getSource(sourceId) as GeoJSONSource
    const pipelineSource = mapContext?.getSource('pipeline__source') as GeoJSONSource
    const segmentSource = mapContext?.getSource('segment__source') as GeoJSONSource
    const _segments: Feature[] = []
    const node = getSourceData(source).features.find((feat) => feat.properties!.id === id)
    if (node) {
      getSourceData(pipelineSource).features.forEach((feat) => {
        if (feat.properties!.start_node === id) {
          feat.geometry = lineString([lngLat.toArray(), ...getCoords(feat.geometry as any).slice(1)]).geometry
        } else if (feat.properties!.end_node === id) {
          feat.geometry = lineString([...getCoords(feat.geometry as any).slice(0, -1), lngLat.toArray()]).geometry
        } else if (feat.properties!.id === node.properties!.lineId) {
          feat.geometry = lineString([
            ...getCoords(feat.geometry as any).slice(0, node.properties!.index),
            lngLat.toArray(),
            ...getCoords(feat.geometry as any).slice(node.properties!.index + 1),
          ]).geometry
        }
        _segments.push(
          ...segmentReduce(
            feat.geometry as GeoJSON.LineString,
            (prev: any[] | undefined, curr) => {
              prev &&
                curr &&
                prev.push(
                  feature(getGeom(curr), {
                    id: `${feat.properties?.id}s${prev.length}`,
                    lineId: feat.properties?.id,
                    index: prev.length,
                    zIndex: 1,
                  }),
                )
              return prev || []
            },
            [],
          ),
        )
      })
      node.geometry = point(lngLat.toArray()).geometry
      source?.setData(getSourceData(source))
      segmentSource?.setData(featureCollection(_segments))
      pipelineSource?.setData(getSourceData(pipelineSource))
    }
  }
  const dragNode = (e: MapEvent) => {
    const { properties, layer, drag } = ref.current.dragFeatures[0] as DragFeature
    if (!drag && ref.current.dragFeatures[0]) ref.current.dragFeatures[0].drag = true
    updateNodeCoords(properties.id, e.lngLat, layer.source)
    const padding = getIconSize(getSourceType(layer as AnyLayer)) / 2
    const bbox = [
      [e.point.x - padding, e.point.y - padding],
      [e.point.x + padding, e.point.y + padding],
    ] as [PointLike, PointLike]
    resetFilters([
      'source-warn__layer',
      'sink-warn__layer',
      'reference_nodes-warn__layer',
      'compressor_stations-warn__layer',
      'points-warn__layer',
      'nodes_reductions-warn__layer',
    ])
    const crossFeatures = mapContext
      ?.queryRenderedFeatures(bbox)
      .filter(
        (feat) =>
          ['symbol', 'circle'].includes(feat.layer.type) &&
          feat.properties.id &&
          feat.properties.id !== properties.id &&
          e.point.dist(project(new LngLat(...(getCoords(feat.geometry as any) as [number, number])))) <=
            padding + getIconSize(getSourceType(feat.layer as AnyLayer)) / 2,
      )
      .map((feat) => feat.properties.id)
    if (crossFeatures.length && ref.current.dragFeatures.length) {
      ref.current.dragFeatures[0].warn = true
      setFilters(
        [
          'source-warn__layer',
          'sink-warn__layer',
          'reference_nodes-warn__layer',
          'compressor_stations-warn__layer',
          'points-warn__layer',
          'nodes_reductions-warn__layer',
        ],
        ['in', 'id', ...crossFeatures],
      )
    } else if (ref.current.dragFeatures.length) ref.current.dragFeatures[0].warn = false
  }
  const handleOnMouseDown = (e: MapEvent) => {
    e.preventDefault()
    dispatch(resetShowElementInfo())
    if (e.features[0].properties.type === 'POINT' && !rules.current.editLine) return
    mapContext.getCanvas().style.cursor = 'move'
    ref.current.dragFeatures.push({
      ...e.features[0],
      geometry: e.features[0].geometry,
      warn: false,
      drag: false,
    } as DragFeature)
    mapContext?.on('mousemove', dragNode)
    mapContext?.once('mouseup', handleOnMouseUp)
  }
  const handleCreatePoint = (e: MapEvent) => {
    // dispatch(resetShowElementInfo())
    e.preventDefault()
    const pipelineIndex = ref.current.pipelines.findIndex(
      (pipe: Feature) => pipe.properties && pipe.properties.id === ref.current.nodeCreation.pipelineID,
    )
    if (pipelineIndex > -1) {
      const pipeline = ref.current.pipelines[pipelineIndex]
      const line = lineString(
        addPointToLineString(getCoords(pipeline.geometry), ref.current.nodeCreation.coordinates as number[]),
      ).geometry
      const index = line.coordinates.findIndex((coord) =>
        booleanEqual(point(coord), point(ref.current.nodeCreation.coordinates as number[])),
      )
      dispatch(
        setUpdatedElement({
          elementID: ref.current.nodeCreation.pipelineID as string,
          type: 'POINTS',
          params: { central_point: line } as { [key: string]: unknown },
        }),
      )
      ref.current.dragFeatures.push({
        properties: {
          id: `${ref.current.nodeCreation.pipelineID}${index}`,
          index,
          lineId: ref.current.nodeCreation.pipelineID,
          type: 'POINT',
        },
        layer: mapContext?.getLayer('points__layer'),
        geometry: point(ref.current.nodeCreation.coordinates as number[]).geometry,
        warn: false,
        drag: false,
      } as unknown as DragFeature)
      dispatch(
        setShowElementInfo({
          objectType: 'POINTS',
          objectId: `${ref.current.nodeCreation.pipelineID}${index}`,
          isVisible: rules.current.select,
        }),
      )
      mapContext?.on('mousemove', dragNode)
      mapContext?.once('mouseup', handleOnMouseUp)
    }
  }
  const selectGroup = (id: string, type: MapElementType) => {
    if (ref.current.onShift) {
      ref.current.selectedGroup.find((i) => i.id === id)
        ? dispatch(setSelectedGroup(ref.current.selectedGroup.filter((i) => i.id !== id)))
        : dispatch(setSelectedGroup([...ref.current.selectedGroup, { id, type }]))
    } else {
      dispatch(setSelectedGroup([{ id, type }]))
    }
  }
  const handleOnMouseUp = (e: MapEvent) => {
    mapContext.getCanvas().style.cursor = ''
    if (!ref.current.dragFeatures.length) {
      console.warn('Drag feature is undefined')
      mapContext?.off('mousemove', dragNode)
      return
    }
    const { properties, layer, geometry, warn, drag } = ref.current.dragFeatures[0] as DragFeature
    const type = getSourceType(layer as AnyLayer).toUpperCase()
    if (!drag) {
      if (ref.current.mapMode === 'edit') {
        selectGroup(properties.id, type.toLowerCase() as MapElementType)
      } else
        dispatch(
          setShowElementInfo({
            objectType: type as elementType,
            objectId: properties.id,
            isVisible: rules.current.select,
          }),
        )
      properties.index && setPointIndex(properties.index as number)
      properties.lineId && setLineID(properties.lineId)
    } else if (warn) {
      dispatch(resetShowElementInfo())
      updateNodeCoords(properties.id, new LngLat(...(getCoords(geometry as any) as [number, number])), layer.source)
      resetFilters([
        'source-warn__layer',
        'sink-warn__layer',
        'reference_nodes-warn__layer',
        'compressor_stations-warn__layer',
        'points-warn__layer',
        'nodes_reductions-warn__layer',
      ])
    } else if (drag) {
      if (type === 'POINTS') {
        const pipeline = getSourceData(mapContext?.getSource('pipeline__source') as GeoJSONSource).features.find(
          (feat: Feature) => feat.properties && feat.properties.id === properties.lineId,
        )
        pipeline &&
          dispatch(
            setUpdatedElement({
              elementID: properties.lineId,
              type,
              params: { central_point: pipeline.geometry as any },
            }),
          )
      } else
        setUpdatedCoords({
          id: properties.id as string,
          type: getSourceType(layer as AnyLayer).toUpperCase() as elementType,
          central_point: point(e.lngLat.toArray()).geometry as any,
        })
      dispatch(resetShowElementInfo())
    }
    mapContext?.off('mousemove', dragNode)
    ref.current.dragFeatures = []
  }
  const handleMouseOut = (e: MapEvent) => {
    ref.current.dragFeatures.length && mapContext?.fire('mouseup', { lngLat: e.lngLat, point: e.point })
  }
  const handleZoom = () => {
    setZoom(mapContext?.getZoom())
  }
  const drawSelectArea = (e: MapEvent) => {
    ref.current.selectArea?.splice(2, 2, ...e.lngLat.toArray())
    mapContext.getCanvas().style.cursor = 'pointer'
    setSelectArea(ref.current.selectArea)
    const features = mapContext?.queryRenderedFeatures(
      [
        project(ref.current.selectArea.slice(0, 2) as unknown as LngLat),
        project(ref.current.selectArea.slice(2, 4) as unknown as LngLat),
      ],
      {
        layers: [
          'source__layer',
          'sink__layer',
          'reference_nodes__layer',
          'nodes_reductions__layer',
          'compressor_stations__layer',
          'points__layer',
          'segment__layer',
        ],
      },
    )
    dispatch(
      setSelectedGroup(
        features?.map((f) => {
          return {
            id: f.properties.id,
            type: getSourceType(f.layer as AnyLayer),
          }
        }),
      ),
    )
  }
  const drawSelectAreaFinish = () => {
    mapContext.getCanvas().style.cursor = ''
    setSelectArea([])
    mapContext?.off('mousemove', drawSelectArea)
  }
  const handleMouseDownOnMap = (e: MapEvent) => {
    if ((ref.current.onShift || e.originalEvent.buttons === 2) && rules.current.selectGroup) {
      setSelectArea([...e.lngLat.toArray(), ...e.lngLat.toArray()])
      e.preventDefault()
      mapContext.getCanvas().style.cursor = 'pointer'
      mapContext?.on('mousemove', drawSelectArea)
    }
    mapContext?.once('mouseup', drawSelectAreaFinish)
  }
  const onClick = (e: MapEvent) => {
    const feature = mapContext
      ?.queryRenderedFeatures(e.point)
      .sort((a, b) => b.properties.zIndex - a.properties.zIndex)
      .find((feat) => !['addNode', 'select-area__source'].includes(feat.layer.source))
    if (!feature) {
      dispatch(setSelectedGroup([]))
    }
  }
  useEffect(() => {
    mapContext?.on('mousemove', handleMouseMove)
    mapContext?.on('mouseout', handleMouseOut)
    mapContext?.on('zoom', handleZoom)
    mapContext?.on('click', onClick)
    mapContext?.on('mousedown', handleMouseDownOnMap)
    return () => {
      mapContext?.off('mousemove', handleMouseMove)
      mapContext?.off('zoom', handleZoom)
    }
  }, [])

  useEffect(() => {
    if (currentMap.zoom.in) {
      mapContext?.setZoom(mapContext?.getZoom() + defaultZoomDelta)
    }
    if (currentMap.zoom.out) {
      mapContext?.setZoom(mapContext?.getZoom() - defaultZoomDelta)
    }
    dispatch(resetMapZoom())
  }, [currentMap.zoom])

  useEffect(() => {
    if (currentMap.toCenter) {
      const collection = multiLineString(pipelines.map((pipeline) => getCoords(pipeline as any)))
      const box = bbox(collection) as BBox2d
      mapContext?.fitBounds(box, { padding: { top: 80, right: 40, bottom: 40, left: 40 }, animate: false })
      dispatch(setToMapCenter(false))
    }
  }, [currentMap.toCenter])
  const handleSegmentOnMouseDown = (e: MapEvent) => {
    //.
  }
  return (
    <>
      <ModeControl />
      <LayerControl />
      <ControlButtons
        className={currentMap.mode}
        onZoomIn={() => dispatch(setMapZoom({ in: true }))}
        onZoomOut={() => dispatch(setMapZoom({ out: true }))}
        onNavigate={() => dispatch(setToMapCenter(true))}
      />
      <CoordsView lngLat={cursorCoords || mapContext?.getCenter()} />
      <Layer
        sourceID={'segment__source'}
        baseLayer={'segment-hover__layer'}
        features={[]}
        layerType={'line'}
        paint={lineHoverLayerProps}
        layout={lineLayoutProps}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'segment__source'}
        baseLayer={'segment-selected__layer'}
        features={[]}
        layerType={'line'}
        paint={lineSelectedLayerProps}
        layout={lineLayoutProps}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'segment__source'}
        baseLayer={'segment__layer'}
        features={rules.current.segment ? segments : []}
        layout={lineLayoutProps}
        layerType={'line'}
        source
        paint={lineTransparentLayerProps}
      />
      <Layer
        sourceID={'pipeline__source'}
        baseLayer={'pipeline-hover__layer'}
        features={[]}
        layerType={'line'}
        paint={rules.current.selectLine ? lineHoverLayerProps : lineTransparentLayerProps}
        layout={lineLayoutProps}
        // visible={currentMap.mode==='edit'?'none':'visible'}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'pipeline__source'}
        baseLayer={'pipeline-selected__layer'}
        features={[]}
        layerType={'line'}
        paint={lineSelectedLayerProps}
        layout={lineLayoutProps}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'pipeline__source'}
        baseLayer={'pipeline-init__layer'}
        features={pipelines}
        layerType={'line'}
        paint={lineLayerProps}
        layout={lineLayoutProps}
        source
      />
      <LineLayer
        sourceID={'pipeline__source'}
        baseLayer={'pipeline__layer'}
        actionLayer={'pipeline-hover__layer'}
        features={pipelines}
        layout={lineLayoutProps}
        paint={lineBaseLayerProps}
        currentRef={ref}
      />
      <Layer
        sourceID={'points__source'}
        baseLayer={'points__layer'}
        layerType={'symbol'}
        layout={{
          'icon-image': `point-icn`,
        }}
        handleMouseDown={handleOnMouseDown}
        features={rules.current.points ? points : []}
        source
        // paint={zoom < PointsMinZoom ? circleTransparentLayerProps : circleLayerProps}
      />
      {!ref.current.dragFeatures[0]?.drag && <AddNode handleCreatePoint={handleCreatePoint} />}
      <Layer
        sourceID={'reference_nodes__source'}
        baseLayer={'reference_nodes__layer'}
        layerType={'symbol'}
        handleMouseDown={handleOnMouseDown}
        features={referenceNodes}
        source
        layout={{
          'icon-image': 'junction-icn',
          ...layerProps,
        }}
      />
      <Layer
        sourceID={'nodes_reductions__source'}
        baseLayer={'nodes_reductions__layer'}
        layerType={'symbol'}
        handleMouseDown={handleOnMouseDown}
        features={reductionNodes}
        source
        layout={{
          'icon-image': 'reduction-icn',
          ...layerProps,
        }}
      />
      <Layer
        sourceID={'compressor_stations__source'}
        baseLayer={'compressor_stations__layer'}
        features={compressorStations}
        layerType={'symbol'}
        source
        handleMouseDown={handleOnMouseDown}
        layout={{
          'icon-image': 'cs-icn',
          ...layerProps,
        }}
      />
      <Layer
        sourceID={`source__source`}
        baseLayer={'source__layer'}
        features={source}
        source
        layerType={'symbol'}
        handleMouseDown={handleOnMouseDown}
        layout={{
          'icon-image': 'source-icn',
          ...layerProps,
        }}
      />
      <Layer
        source
        sourceID={`sink__source`}
        baseLayer={'sink__layer'}
        features={sink}
        layerType={'symbol'}
        handleMouseDown={handleOnMouseDown}
        layout={{
          'icon-image': 'sink-icn',
          ...layerProps,
        }}
      />
      {
        ['source', 'sink', 'reference_nodes', 'compressor_stations', 'nodes_reductions'].map((type, index) => {
          return (
            <React.Fragment key={index}>
              <Layer
                sourceID={`${type}__source`}
                baseLayer={`${type}-hover__layer`}
                features={[]}
                layerType={'symbol'}
                layout={{
                  'icon-image': `${getIconName(type)}-hover-icn`,
                  ...layerProps,
                }}
                filter={['==', 'id', '']}
              />
              <Layer
                sourceID={`${type}__source`}
                baseLayer={`${type}-selected__layer`}
                features={[]}
                layerType={'symbol'}
                layout={{
                  'icon-image': `${getIconName(type)}-selected-icn`,
                  ...layerProps,
                }}
                filter={['==', 'id', '']}
              />
              <Layer
                sourceID={`${type}__source`}
                baseLayer={`${type}-warn__layer`}
                features={[]}
                layerType={'symbol'}
                layout={{
                  'icon-image': `${getIconName(type)}-warn-icn`,
                  ...layerProps,
                }}
                filter={['==', 'id', '']}
              />
            </React.Fragment>
          ) as any
        }) as any
      }
      <Layer
        sourceID={'points__source'}
        baseLayer={'points-hover__layer'}
        features={[]}
        layerType={'circle'}
        paint={circleHoverLayerProps}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'points__source'}
        baseLayer={'points-selected__layer'}
        features={[]}
        layerType={'circle'}
        paint={circleHoverLayerProps}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'points__source'}
        baseLayer={'points-warn__layer'}
        features={[]}
        layerType={'circle'}
        paint={circleWarnLayerProps}
        filter={['==', 'id', '']}
      />
      <Layer
        sourceID={'select-area__source'}
        baseLayer={'select-area__layer'}
        features={selectArea.length > 0 ? [bboxPolygon(selectArea as BBox2d)] : selectArea}
        layerType={'fill'}
        paint={selectAreaLayerProps}
        source
      />
    </>
  )
}
