import React from 'react'

import {
  createBlackPipePricesHandbookVersion,
  createIndicesPipesCalculationHandbookVersion,
} from 'services/apiRequests'

import { useDispatch, useSelector } from 'react-redux'
import { selectDirectoryItem, setDirectoryItem } from 'store/directorySlice'
import { definitions } from 'generated/apiTypes'
import { setBlockingWindow } from 'store/commonSlice'
import {
  DiameterToBlackPipePrice,
  DiameterToPipesCalculationIndices,
  PipesCalculationIndices,
  PressureToBlackPipePrice,
  PressureToPipesCalculationIndices,
  SteelGradeToBlackPipePrice,
} from 'store/types'

const useCreateBlackPipePricesVersion = (saveData: boolean | undefined): boolean => {
  const dispatch = useDispatch()
  const directoryItem = useSelector(selectDirectoryItem)
  const [result, setResult] = React.useState(false)

  const tryToCreateBlackPipePricesVersion = async () => {
    if (directoryItem) {
      const items: definitions['DiameterToBlackPipePriceCreate'] = {}
      Object.entries(directoryItem).forEach((value) => {
        const [diameter, item] = value as [keyof DiameterToBlackPipePrice, PressureToBlackPipePrice]
        Object.entries(item).forEach((value2) => {
          const [pressure, attrs] = value2 as [keyof PressureToBlackPipePrice, SteelGradeToBlackPipePrice]
          Object.entries(attrs).forEach((value3) => {
            const [steel, price] = value3
            const { modified, ...newItem } = price
            if (modified) {
              const diameterItem = items[diameter]
              if (diameterItem)
                if (Object.keys(diameterItem).includes(pressure)) {
                  //@ts-ignore
                  diameterItem[pressure][steel] = newItem
                } else
                  diameterItem[pressure] = {
                    [steel]: newItem,
                  }
              else
                items[diameter] = {
                  [pressure]: {
                    [steel]: newItem,
                  },
                }
            }
          })
        })
      })
      await createBlackPipePricesHandbookVersion(items)
        .then((res) => {
          dispatch(setDirectoryItem(res.data))
          setResult(true)
        })
        .catch((err) => {
          if (err.response.status === 400 && err.response.data.some((item: object) => Object.keys(item).length > 0)) {
            dispatch(
              setBlockingWindow({
                objectName: 'Ошибка сохранения',
                message: (
                  <div className='error-window__errors-list-container'>
                    {err.response.data.map((item: any, index: number) => {
                      if (Object.keys(item).length > 0) {
                        return (
                          <div key={index}>
                            {'При сохранении возникли следующие ошибки:'}
                            <ul className='error-window__errors-list' key={index}>
                              {Object.entries(item).map(([key, value], ix) => {
                                return <li key={ix}>{`${key}: ${value}`}</li>
                              })}
                            </ul>
                          </div>
                        )
                      }
                    })}
                  </div>
                ),
                type: 'ERROR',
              }),
            )
          } else
            dispatch(
              setBlockingWindow({
                objectName: 'Непредвиденная ошибка',
                message: 'Непредвиденная ошибка',
                type: 'ERROR',
              }),
            )
        })
    }
  }
  React.useEffect(() => {
    if (saveData !== undefined) {
      setResult(false)
      tryToCreateBlackPipePricesVersion().then()
    }
  }, [saveData])

  return result
}

export default useCreateBlackPipePricesVersion
