import * as React from 'react'

function RubIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g
        clipPath='url(#prefix__clip0_743_5729)'
        stroke='#0074BC'
        strokeWidth={2.083}
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <path d='M6.667 15.833V4.167h5a2.5 2.5 0 010 5H5M11.667 12.5H5' />
      </g>
      <defs>
        <clipPath id='prefix__clip0_743_5729'>
          <path fill='#fff' d='M0 0h20v20H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default RubIcon
