import { arrow, ComputedStyle } from '../utils'
import { Text } from './text'

export const Arrow = (ctx: CanvasRenderingContext2D, x: number, y: number, Q?: string, P?: string, t?: string) => {
  const color = ComputedStyle.getPropertyValue('--primary-text-color')
  const lineHeight = 15
  const gap = 4
  Text(ctx, `Q = ${Q}`, x, y - lineHeight - gap, {
    fontWeight: 600,
    fontSize: 12,
    color,
    textAlign: 'left',
    textBaseLine: 'middle',
  })
  Text(ctx, `P = ${P}`, x, y, {
    fontWeight: 600,
    fontSize: 12,
    color,
    textAlign: 'left',
    textBaseLine: 'middle',
  })
  Text(ctx, `Tвх = ${t}`, x, y + lineHeight + gap, {
    fontWeight: 600,
    fontSize: 12,
    color,
    textAlign: 'left',
    textBaseLine: 'middle',
  })
  arrow(ctx, x + 70, y - 8)
}
