import React, { useContext, useEffect, useRef } from 'react'
import { MapContext } from './map'
import { useDispatch, useSelector } from 'react-redux'
import { selectMapMode, selectMapSubmode, setNodeCreation, setToMapCenter } from 'store/mapSlice'
import { getCoords } from '@turf/turf'
import { getIconSize, pointOnLine } from './utils'
import { Layer } from './Layer'
import { PointLike } from 'maplibre-gl'
import { MapEvent } from './types'
import { setShowElementInfo } from 'store/projectSlice'

interface ILayerProps {
  features: any[]
  sourceID: string
  baseLayer: string
  actionLayer?: string
  layout?: any
  paint?: any
  handleMouseMove?: (e: any) => void
  handleMouseClick?: (e: any) => void
  currentRef: any
}
export const LineLayer: React.FC<ILayerProps> = (props) => {
  const mapContext = useContext(MapContext)
  const mapMode = useSelector(selectMapMode)
  const mapSubmode = useSelector(selectMapSubmode)
  const mapModeRef = useRef(mapMode)
  const mapSubmodeRef = useRef(mapSubmode)
  const dispatch = useDispatch()
  const init = useRef(true)
  const reset = () => {
    dispatch(
      setNodeCreation({
        pipelineID: undefined,
        coordinates: undefined,
      }),
    )
  }
  const onMouseMoveHandler = (e: any) => {
    const padding = getIconSize(props.currentRef.current.nodeCreation.editObject) / 2 - 6
    const bbox = [
      [e.point.x - padding, e.point.y - padding],
      [e.point.x + padding, e.point.y + padding],
    ] as [PointLike, PointLike]
    if (
      mapContext
        .queryRenderedFeatures(bbox)
        .filter(
          (feature) =>
            feature.source !== 'pipeline__source' &&
            feature.source !== 'segment__source' &&
            feature.source !== 'addNode',
        ).length > 0
    ) {
      reset()
      return
    }
    const feature = props.features.find((f) => f.properties.id === e.features[0].properties.id)
    const coordinates = mapContext
      .unproject(
        pointOnLine(
          getCoords(feature.geometry).map((coords) => Object.values(mapContext?.project(coords))),
          Object.values(e.point),
        ) as PointLike,
      )
      .toArray() as [number, number]
    dispatch(
      setNodeCreation({
        pipelineID: feature.properties.id,
        coordinates,
      }),
    )
  }
  const onMouseLeaveHandler = () => {
    reset()
  }
  const onMouseClickHandler = (event: MapEvent) => {
    mapModeRef.current === 'view' &&
      mapSubmodeRef.current === 'base' &&
      dispatch(
        setShowElementInfo({
          objectType: 'PIPELINE',
          objectId: event.features[0].properties.id,
          isVisible: true,
        }),
      )
  }
  useEffect(() => {
    mapModeRef.current = mapMode
    mapSubmodeRef.current = mapSubmode
    if (mapSubmode !== 'base') {
      mapContext?.on('mousemove', 'pipeline__layer', onMouseMoveHandler)
      mapContext?.on('mouseleave', 'pipeline__layer', onMouseLeaveHandler)
    }
    return () => {
      mapContext?.off('mousemove', 'pipeline__layer', onMouseMoveHandler)
      mapContext?.off('mouseleave', 'pipeline__layer', onMouseLeaveHandler)
    }
  }, [mapMode, mapSubmode, props.features])
  useEffect(() => {
    if (props.features.length && init.current) {
      dispatch(setToMapCenter(true))
      init.current = false
    }
  }, [props.features.length])
  return (
    <Layer
      sourceID={props.sourceID}
      baseLayer={props.baseLayer}
      actionLayer={props.actionLayer}
      features={props.features}
      layout={props.layout}
      layerType={'line'}
      source
      handleMouseClick={onMouseClickHandler}
      paint={props.paint}
    />
  )
}
