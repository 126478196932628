import Checkbox from 'components/Checkbox'
import './layerControl.scss'
import React, { useContext, useState } from 'react'
import { MapContext } from '../map'
import Button from 'components/Button'
import IconArrayDown from 'images/IconArrayDown'
import { Layers } from '../layers'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { selectMapMode } from '../../../store/mapSlice'
interface ILayerProps {
  id: string
  name: string
  visible: boolean
}
const LayerButton: React.FC<ILayerProps> = (props) => {
  const [isVisible, setIsVisible] = useState(props.visible)
  const mapContext = useContext(MapContext)

  const setVisibility = (visible: boolean) => {
    mapContext?.setLayoutProperty(props.id, 'visibility', visible ? 'visible' : 'none')
    setIsVisible(visible)
  }
  return (
    <div className={'layer-control__dropdown-btn'}>
      <Checkbox checkedValue={isVisible} setCheckedValue={(value) => setVisibility(value)} />
      {props.name}
    </div>
  )
}
export const LayerControl: React.FC = () => {
  const mapContext = useContext(MapContext)
  const mode = useSelector(selectMapMode)
  const [open, setOpen] = useState(false)
  return (
    <div className={classnames('layer-control', mode)}>
      <Button className={classnames('layer-control__btn', { open })} onClick={() => setOpen(!open)}>
        Слои <IconArrayDown />
      </Button>
      <div className={classnames('layer-control__dropdown-container', { open })}>
        {Layers.map((layer) => (
          <LayerButton
            id={layer.id}
            key={layer.id}
            name={(layer.metadata as any).name}
            visible={mapContext?.getLayoutProperty(layer.id, 'visibility')}
          />
        ))}
      </div>
    </div>
  )
}
