import React from 'react'
import BackIcon from 'images/BackIcon'
import Button from 'components/Button'
import './projectHeader.scss'
import { useNavigate } from 'react-router-dom'
import './directoryHeader.scss'
import { useSelector } from 'react-redux'
import { selectUser } from '../../store/commonSlice'

interface IDirectoryHeaderProps {
  title: string
  editMode: boolean
  setEditMode(editMode: boolean): void
  discardChanges(): void
  pageLeave(): boolean
  saveChanges(): void
}

export const DirectoryHeader: React.FC<IDirectoryHeaderProps> = ({
  title,
  editMode,
  setEditMode,
  discardChanges,
  pageLeave,
  saveChanges,
}) => {
  const navigate = useNavigate()
  const user = useSelector(selectUser)

  const onSave = () => {
    saveChanges()
  }

  const onDiscard = () => {
    discardChanges()
  }

  const onPageLeave = () => {
    const leave = pageLeave()
    if (leave) {
      navigate('/', { state: { tab: 'directories' } })
    }
  }

  return (
    <div className={'project__header base-header'}>
      <div className={'project__header-start'}>
        <BackIcon className={'back-icon'} onClick={onPageLeave} />
        <h2 className={'project__header-title'}>{title}</h2>
      </div>
      <div className={'project__header-end'}>
        {user?.groups.some((group) => group.name === 'librarian') &&
          (!editMode ? (
            <Button mode={'secondary'} onClick={() => setEditMode(true)}>
              Редактировать
            </Button>
          ) : (
            <div className={'directory__header-buttons'}>
              <Button mode={'secondary'} onClick={onSave}>
                Сохранить
              </Button>
              <Button mode={'primary-light'} onClick={onDiscard}>
                Выйти без сохранения
              </Button>
            </div>
          ))}
      </div>
    </div>
  )
}
