import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Button from 'components/Button'
import React from 'react'
import './style.scss'
import { IBlockingWindow } from 'store/types'
interface IStopTaskWindow {
  blockingWindow: IBlockingWindow
  stopTask(): void
  closeWindow(): void
}
export const StopTaskWindow: React.FC<IStopTaskWindow> = ({ blockingWindow, stopTask, closeWindow }) => {
  const [singleClick, setSingleClick] = React.useState<boolean>(false)

  const stopTaskHandler = () => {
    setSingleClick(true)
    stopTask()
  }

  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container stop-task-confirm'>
        <div className={'blocking-window__header'}>
          {`Отмена расчета`}
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'blocking-window__body'}>
          {`Вы действительно хотите отменить расчет`}
          {blockingWindow.objectName !== undefined && ` “${blockingWindow.objectName}“`}?
          <br />
          <br />
          {`Весь достигнутый прогресс будет потерян.`}
        </div>
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Не отменять расчет
          </Button>
          <Button mode={'warn'} onClick={stopTaskHandler} disabled={singleClick}>
            Отменить расчет
          </Button>
        </div>
      </div>
    </div>
  )
}
