import { CloseBtn } from 'components/CloseBtn/closeBtn'
import Button from 'components/Button'
import React from 'react'
import './style.scss'
import { IBlockingWindow } from 'store/types'
import { setBlockingWindow } from '../../store/commonSlice'
import { useDispatch } from 'react-redux'
interface IDiscardChangesWindow {
  closeWindow(): void
}
export const DiscardChangesWindow: React.FC<IDiscardChangesWindow> = ({ closeWindow }) => {
  const dispatch = useDispatch()

  const discardChanges = () => {
    dispatch(
      setBlockingWindow({
        isConfirm: true,
      }),
    )
  }

  return (
    <div className='blocking-window-background'>
      <div className='blocking-window-container delete-confirm'>
        <div className={'blocking-window__header'}>
          {'Выход без сохранения'}
          <CloseBtn close={closeWindow} />
        </div>
        <div className={'blocking-window__body'}>{'Вы действительно хотите выйти без сохранения?'}</div>
        <div className={'blocking-window__footer'}>
          <Button mode={'secondary'} onClick={closeWindow}>
            Отменить
          </Button>
          <Button mode={'warn'} onClick={discardChanges}>
            Выйти без сохранения
          </Button>
        </div>
      </div>
    </div>
  )
}
