import CloseIcon from 'images/CloseIcon'
import React from 'react'
import './closeBtn.scss'
interface ICloseBtn {
  close(): void
}
export const CloseBtn: React.FC<ICloseBtn> = ({ close }) => {
  return (
    <button className={'close-btn'} onClick={close}>
      <CloseIcon />
    </button>
  )
}
