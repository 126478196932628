import * as React from 'react'

function PathAutoIcon(props) {
  return (
    <svg width={20} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#prefix__clip0_1901_20641)' fill='#0074BC' strokeOpacity={0}>
        <path d='M7 5a2 2 0 11-4 0 2 2 0 014 0zM17 15a2 2 0 11-4 0 2 2 0 014 0z' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.415 7.976L4.01 5.14l1.98-.282.405 2.834.59.148A2 2 0 018.5 9.78v1.352l2.388.34a2 2 0 01.917.38l1.754 1.316 2.456 1.474-1.03 1.715-2.544-1.527-1.836-1.377-2.388-.341a2 2 0 01-1.717-1.98V9.78l-.59-.148a2 2 0 01-1.495-1.657z'
        />
        <path d='M-.02 20l3.3-7.05h1.5L8.1 20H6.25l-.85-1.94.71.51H1.97l.7-.51L1.83 20H-.02zm4.04-5.15l-1.17 2.79-.28-.47H5.5l-.25.47-1.18-2.79h-.05z' />
      </g>
      <defs>
        <clipPath id='prefix__clip0_1901_20641'>
          <path fill='#fff' d='M0 0h20v20H0z' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PathAutoIcon
