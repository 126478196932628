import { FC, useEffect } from 'react'
import { resetBlockingWindow, selectBlockingWindow } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { clearSelectedItems } from 'store/directorySlice'
import { useNavigate } from 'react-router-dom'

interface IDiscardChangesProps {
  onDiscardChanges(): void
}

const useDiscardChanges = ({ onDiscardChanges }: IDiscardChangesProps) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const blockingWindow = useSelector(selectBlockingWindow)

  useEffect(() => {
    if (
      blockingWindow.type === 'DISCARD_CHANGES' &&
      blockingWindow.objectName === 'EDIT_EXIT' &&
      blockingWindow.isConfirm
    ) {
      onDiscardChanges()
      dispatch(clearSelectedItems())
      dispatch(resetBlockingWindow())
    } else if (
      blockingWindow.type === 'DISCARD_CHANGES' &&
      blockingWindow.objectName === 'PAGE_LEAVE' &&
      blockingWindow.isConfirm
    ) {
      navigate('/', { state: { tab: 'directories' } })
      dispatch(resetBlockingWindow())
    }
  }, [blockingWindow.isConfirm])

  return
}

export default useDiscardChanges
