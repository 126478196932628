import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { selectValidation } from 'store/validationSlice'

const useGetValidationMessage = (id?: string, field?: string) => {
  const validation = useSelector(selectValidation)
  const [message, setMessage] = useState<string[] | undefined>()
  React.useEffect(() => {
    if (id && field) {
      const index = validation.findIndex((item) => item.id === id && item.field === field)
      if (index > -1) {
        setMessage(validation[index].errorMessage)
      } else {
        setMessage(undefined)
      }
    }
  }, [validation])

  return message
}

export default useGetValidationMessage
