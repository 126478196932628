import React, { useEffect } from 'react'
import classnames from 'classnames'
import { TableHeader } from '../TableHeader'
import '../Table.scss'
import { IMultiSelectTableProps } from '../types'
import { TableCell } from '../TableCell'
import { MultiSelectTableRow } from './MultiSelectTableRow'
import { sortByOrder } from '../../../pages/utils'
import classNames from 'classnames'

export const MultiSelectTable: React.FC<IMultiSelectTableProps> = ({
  columns,
  dataSource,
  className,
  setValue,
  editMode,
  selectedItems,
  onItemSelect,
  onItemDeselect,
}) => {
  const tableRef = React.useRef<HTMLDivElement>(null)
  const [scrollTop, setScrollTop] = React.useState<number>(0)
  const contentColumns = columns.filter((column) => column.type).sort((a, b) => sortByOrder(a.order, b.order))

  const usePrevious = <T,>(value: T): T | undefined => {
    const ref = React.useRef<T>()
    React.useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const previous = usePrevious({ dataLength: dataSource.length })
  React.useEffect(() => {
    if (
      editMode &&
      tableRef?.current &&
      previous &&
      previous?.dataLength > 0 &&
      previous?.dataLength + 1 === dataSource?.length
    ) {
      tableRef.current.scroll({ top: tableRef.current.scrollHeight, behavior: 'smooth' })
    }
  }, [dataSource.length])

  useEffect(() => {
    const scrollToTop = () => {
      tableRef.current?.scroll({ top: 0, behavior: 'auto' })
      setScrollTop(0)
    }
    window.addEventListener('resize', scrollToTop)
    return () => {
      window.removeEventListener('resize', scrollToTop)
    }
  }, [tableRef.current])

  useEffect(() => {
    const setTop = () => {
      if (tableRef.current?.scrollTop) setScrollTop(tableRef.current.scrollTop)
    }
    tableRef.current?.addEventListener('scroll', setTop)
    return () => {
      tableRef.current?.removeEventListener('scroll', setTop)
    }
  }, [tableRef.current])

  return (
    <div className={classnames('table-container', className, editMode && 'edit')} ref={tableRef}>
      <div
        className={'grid-table'}
        style={{
          gridTemplateColumns: `${editMode ? '48px' : ''} ${contentColumns
            ?.map((column) => {
              return column.type === 'Boolean'
                ? '172px'
                : ['title', 'name'].includes(column.key)
                ? 'minmax(max-content, max-content)'
                : 'minmax(186px, auto)'
            })
            .join(' ')}`,
          gridTemplateRows: dataSource?.length > 0 ? `auto repeat(${dataSource.length}, minmax(36px, auto))` : 'unset',
        }}
      >
        <TableHeader columns={columns} expandColumn={editMode} scrollTop={scrollTop} />
        {dataSource.length > 0 ? (
          dataSource.map((obj) => (
            <MultiSelectTableRow
              selected={selectedItems.some((item) => item.id === obj.id)}
              key={`${obj.id}`}
              id={obj.id}
              selectionMode={editMode}
              onItemSelect={onItemSelect}
              onItemDeselect={onItemDeselect}
            >
              {contentColumns.map((field) => (
                <TableCell
                  key={`${obj.id}-${field.key}`}
                  id={obj.id}
                  type={field.type}
                  value={Object.prototype.hasOwnProperty.call(obj, field.key) ? obj[field.key] : ''}
                  fieldName={field.key}
                  editable={field.editable && editMode}
                  signed={field.signed}
                  noEmpty={field.noEmpty}
                  minValue={field.minValue}
                  setValue={setValue}
                  options={field.options}
                  displayedField={field.displayedField}
                  tableRef={tableRef}
                  cellClassNames={classNames(
                    obj['combined'] && field.allowSpan && 'combined',
                    field.sticky && 'sticky',
                  )}
                />
              ))}
            </MultiSelectTableRow>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}
