import * as React from 'react'

function AddPointIcon(props) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.5301 3.36643C8.68968 2.9276 9.31032 2.9276 9.4699 3.36643L13.7004 15.0004H17.5C17.7761 15.0004 18 15.2243 18 15.5004V16.5004C18 16.7765 17.7761 17.0004 17.5 17.0004H12.2996L9 7.92657L5.70043 17.0004H2.5C2.22386 17.0004 2 16.7765 2 16.5004V15.5004C2 15.2243 2.22386 15.0004 2.5 15.0004H4.29957L8.5301 3.36643Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 4V2.5C14 2.22386 14.2239 2 14.5 2H15.5C15.7761 2 16 2.22386 16 2.5V4H17.5C17.7761 4 18 4.22386 18 4.5V5.5C18 5.77614 17.7761 6 17.5 6H16V7.5C16 7.77614 15.7761 8 15.5 8H14.5C14.2239 8 14 7.77614 14 7.5V6H12.5C12.2239 6 12 5.77614 12 5.5V4.5C12 4.22386 12.2239 4 12.5 4H14Z'
        fill='white'
      />
    </svg>
  )
}

export default AddPointIcon
