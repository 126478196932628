import { definitions } from 'generated/apiTypes'

interface ILayersColumn {
  title: string | JSX.Element
  type: 'Text' | 'Boolean'
  key: keyof definitions['HandbookAreaType']
  editable: boolean
}

export const columns: ILayersColumn[] = [
  {
    title: 'Название',
    type: 'Text',
    key: 'name',
    editable: true,
  },
  {
    title: 'Запретный слой',
    type: 'Boolean',
    key: 'is_restricted',
    editable: true,
  },
]
