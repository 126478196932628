import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './configureStore'
import { ITasksSlice, IValidation, IValidationItem } from './types'

export const initialState: IValidation = {
  items: [],
}

export const validationSlice = createSlice({
  name: 'validation',
  initialState,
  reducers: {
    addToValidation: (state, action: PayloadAction<IValidationItem>) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id && item.field === action.payload.field,
      )
      if (index > -1) {
        state.items = [...state.items.slice(0, index), action.payload, ...state.items.slice(index + 1)]
      } else state.items = [...state.items, action.payload]
    },
    resetValidation: (state: IValidation) => {
      state.items = initialState.items
    },
    removeFromValidation: (state, action: PayloadAction<IValidationItem>) => {
      const index = state.items.findIndex(
        (item) => item.id === action.payload.id && item.field === action.payload.field,
      )
      if (index > -1) {
        state.items = [...state.items.slice(0, index), ...state.items.slice(index + 1)]
      }
    },
    updateValidationByID: (state, action: PayloadAction<IValidationItem>) => {
      const index = state.items.findIndex((item) => item.id === action.payload.id)
      if (index > -1) {
        state.items = [...state.items.slice(0, index), action.payload, ...state.items.slice(index + 1)]
      }
    },
  },
})

export const { addToValidation, resetValidation, removeFromValidation, updateValidationByID } = validationSlice.actions

export const selectValidation = (state: RootState) => state.validation.items

export default validationSlice.reducer
