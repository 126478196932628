import { useEffect } from 'react'
import { resetBlockingWindow, selectBlockingWindow, setTaskIsCanceling } from 'store/commonSlice'
import { useDispatch, useSelector } from 'react-redux'
import { cancelTask } from 'services/apiRequests'

const useStopTask = () => {
  const dispatch = useDispatch()
  const blockingWindow = useSelector(selectBlockingWindow)

  const tryToStopTask = async (taskId: string | undefined | null) => {
    if (taskId) {
      await cancelTask(taskId).then(() => dispatch(setTaskIsCanceling(true)))
    }
  }

  useEffect(() => {
    if (blockingWindow.type === 'STOP_TASK_CONFIRM' && blockingWindow.isConfirm) {
      tryToStopTask(blockingWindow.objectID).then(() => dispatch(resetBlockingWindow()))
    }
  }, [blockingWindow.isConfirm])

  return
}

export default useStopTask
