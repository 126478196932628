import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react'

import './style.scss'
import IconArrayDown from './IconArrayDown'
import classNames from 'classnames'
import PopupMenuLayout from './PopupMenuLayout'
import Option from './Option'
import { InfoPopup } from 'components/InfoPopup/InfoPopup'
import InfoIcon from 'images/InfoIcon'
import Button from 'components/Button'

interface IDropdownProps<OptionType> {
  options: OptionType[]
  selectedValue?: OptionType
  setSelectedValue?(value: OptionType): void
  displayedField: string
  valuePlaceholder?: string
  panelRef?: React.RefObject<HTMLDivElement>
  disabled?: boolean
  label?: string
  infoText?: string
}

const Dropdown = <OptionType,>({
  options,
  selectedValue,
  displayedField,
  valuePlaceholder,
  setSelectedValue,
  panelRef,
  disabled,
  label,
  infoText,
}: PropsWithChildren<IDropdownProps<OptionType>>): ReactElement => {
  const [open, setOpen] = React.useState<boolean>(false)
  const controlRef = React.useRef<HTMLDivElement>(null)
  const [menuBottom, setMenuBottom] = React.useState<number>()
  const [panelBottom, setPanelBottom] = React.useState<number>()
  const [menuRef, setMenuRef] = React.useState<React.RefObject<HTMLDivElement>>()
  const [focus, setFocus] = useState(false)
  const [visible, setVisibility] = useState(false)
  React.useEffect(() => {
    setMenuBottom(menuRef?.current?.getBoundingClientRect().bottom)
  }, [menuRef?.current, open])

  React.useEffect(() => {
    if (panelRef?.current)
      setPanelBottom(
        panelRef.current?.getBoundingClientRect().bottom -
          // scrollbar size
          (panelRef?.current?.offsetHeight - panelRef?.current?.clientHeight),
      )
    else setPanelBottom(window.innerHeight)
  }, [panelRef?.current])
  useEffect(() => {
    setVisibility(focus)
  }, [focus])
  return (
    <>
      <div className='dropdown__container' ref={controlRef}>
        {label && (
          <div className={'dropdown__container__label field-label-text'}>
            {label}
            {infoText && (
              <Button mode={'info'} onClick={() => setFocus(true)} onBlur={() => setFocus(false)}>
                <InfoIcon />
              </Button>
            )}
          </div>
        )}
        <button className='dropdown__value-container' onClick={() => setOpen(!open)} disabled={disabled}>
          <div className='dropdown__value-wrapper'>
            <span className={classNames('dropdown__value')}>
              {selectedValue
                ? (selectedValue as any)[displayedField] ||
                  (displayedField === '' && selectedValue) ||
                  'Отсутствует поле'
                : valuePlaceholder || 'Не задано'}{' '}
            </span>
          </div>
          <div className={classNames('dropdown__indicator', { open })}>
            <IconArrayDown />
          </div>
        </button>
        {open && (
          <PopupMenuLayout
            controlRef={controlRef}
            closeMenu={() => setOpen(false)}
            className={classNames(
              menuBottom !== undefined ? 'dropdown__options-list' : 'dropdown__options-list no-display',
              menuBottom! > panelBottom! && 'open-up',
            )}
            setMenuRef={setMenuRef}
          >
            {options.map((item, index) => (
              <Option
                key={index}
                item={item}
                displayedField={displayedField}
                setSelectedValue={setSelectedValue}
                closeOptionsList={() => setOpen(false)}
              />
            ))}
            {options.length === 0 && <div className='dropdown__empty-options'>Список опций пуст</div>}
          </PopupMenuLayout>
        )}
      </div>
      {/*infoText && <InfoPopup focused={focus} visible={visible} infoText={infoText} inputRef={controlRef} />*/}
    </>
  )
}

export default Dropdown
