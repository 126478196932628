import * as React from 'react'

function WarningIcon(props) {
  return (
    <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M5.97 1.5C6.21058 1.50017 6.44756 1.55839 6.66082 1.66972C6.87409 1.78105 7.05733 1.94221 7.195 2.1395L7.249 2.2215L11.4645 9.2585C11.5908 9.4775 11.6595 9.72497 11.6641 9.97775C11.6688 10.2305 11.6092 10.4803 11.491 10.7038C11.3728 10.9273 11.1999 11.1172 10.9884 11.2556C10.7768 11.3941 10.5336 11.4766 10.2815 11.4955L10.1815 11.5H1.7535C1.5011 11.497 1.25356 11.4301 1.03398 11.3056C0.814398 11.1811 0.629916 11.003 0.497732 10.788C0.365549 10.5729 0.289969 10.3279 0.278038 10.0758C0.266107 9.82363 0.318215 9.57257 0.429502 9.346L0.480002 9.2515L4.6925 2.219C4.82574 1.99951 5.01328 1.81804 5.23704 1.69211C5.4608 1.56617 5.71323 1.50001 5.97 1.5ZM6.005 8.5L5.9415 8.5035C5.81998 8.51795 5.70797 8.57648 5.62671 8.66798C5.54544 8.75949 5.50056 8.87762 5.50056 9C5.50056 9.12238 5.54544 9.24051 5.62671 9.33202C5.70797 9.42352 5.81998 9.48205 5.9415 9.4965L6 9.5L6.0635 9.4965C6.18503 9.48205 6.29703 9.42352 6.3783 9.33202C6.45956 9.24051 6.50444 9.12238 6.50444 9C6.50444 8.87762 6.45956 8.75949 6.3783 8.66798C6.29703 8.57648 6.18503 8.51795 6.0635 8.5035L6.005 8.5ZM6 4.5C5.87754 4.50002 5.75933 4.54498 5.66782 4.62636C5.5763 4.70774 5.51783 4.81987 5.5035 4.9415L5.5 5V7L5.5035 7.0585C5.51796 7.18002 5.57648 7.29203 5.66798 7.37329C5.75949 7.45456 5.87762 7.49944 6 7.49944C6.12238 7.49944 6.24051 7.45456 6.33202 7.37329C6.42352 7.29203 6.48205 7.18002 6.4965 7.0585L6.5 7V5L6.4965 4.9415C6.48217 4.81987 6.42371 4.70774 6.33219 4.62636C6.24067 4.54498 6.12247 4.50002 6 4.5Z'
        fill='#FC7818'
      />
    </svg>
  )
}

export default WarningIcon
