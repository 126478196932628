import { useEffect, useRef } from 'react'
import { IMap, MapElementType } from 'store/types'
import { MapGeoJSONFeature } from 'maplibre-gl'

interface DragFeature extends MapGeoJSONFeature {
  warn?: boolean
  drag?: boolean
}

interface ICurrentRefProps {
  nodeCreation: IMap['nodeCreation']
  mapMode: IMap['mode']
  pipelines: any[]
  selectedType?: MapElementType
  hoverType?: MapElementType
  dragFeatures: DragFeature[]
  selectedGroup: IMap['selectedGroup']
  onShift: boolean
  selectArea: number[]
}
const useGetCurrentRef = (
  nodeCreation: IMap['nodeCreation'],
  pipelines: any[],
  mapMode: IMap['mode'],
  selectedGroup: IMap['selectedGroup'],
  selectArea: number[],
) => {
  const currentRef = useRef<ICurrentRefProps>({
    nodeCreation,
    mapMode,
    pipelines,
    selectedType: undefined,
    hoverType: undefined,
    dragFeatures: [],
    selectedGroup,
    selectArea,
    onShift: false,
  })
  useEffect(() => {
    currentRef.current.nodeCreation = nodeCreation
  }, [nodeCreation])

  useEffect(() => {
    currentRef.current.pipelines = pipelines
  }, [pipelines])

  useEffect(() => {
    currentRef.current.mapMode = mapMode
  }, [mapMode])

  useEffect(() => {
    currentRef.current.selectedGroup = selectedGroup
  }, [selectedGroup])
  useEffect(() => {
    currentRef.current.selectArea = selectArea
  }, [selectArea])

  return currentRef
}

export default useGetCurrentRef
