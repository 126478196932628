import React, { useRef, useState } from 'react'
import classnames from 'classnames'
import './KebabButton.scss'
import DotsIcon from '../../images/DotsIcon'

interface IKebabButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  mode?: 'light' | 'dark'
  text?: string
  children?: any
}
export const KebabButton: React.FC<IKebabButton> = ({ mode = 'light', text, children }) => {
  const [menuVisibility, setMenuVisibility] = useState('hidden')
  const menuRef = useRef<HTMLDivElement | null>(null)
  // const dropBoxWidth = 167;
  const [position, setPosition] = useState<any>({ top: 0, left: 0 })
  const onClickDotsButton = (e: any) => {
    e.stopPropagation()
    const kebabPosition = e.currentTarget.getBoundingClientRect()
    setPosition({
      top: kebabPosition.bottom + window.pageYOffset + 2,
      right: document.body.clientWidth - kebabPosition.right,
    })
    setMenuVisibility('visible')
  }
  React.useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef?.current?.contains(event.target)) {
        event.target.click()
      }
      setMenuVisibility('hidden')
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <>
      <button className={classnames('kebab-button', menuVisibility, mode)} onClick={onClickDotsButton}>
        {text}
        <DotsIcon className={classnames('kebab-img', mode)} />
      </button>
      <div
        className={classnames('popup-menu-container', menuVisibility)}
        style={{
          position: 'absolute',
          top: `${position.top}px`,
          right: `${position.right}px`,
        }}
        ref={menuRef}
      >
        {children}
      </div>
    </>
  )
}
